import { FC, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { FlexCol, Label } from 'components/basic';
import {
	TAction,
	TApi,
	TAtomicCondition,
	TCrudl,
	TMail,
	TMath,
	TNestedConditions,
	TOperand,
} from 'types/automation';
import { TSchema, TStructure } from 'types/table';
import {
	InputSelectionAutomation,
	SectionConnector,
	SectionWrapper,
} from './automation-edit/automation-edit.style';
import {
	confirmEmailTemplate,
	createFilterOptions,
	language,
	isNotEmpty,
	replaceElementInArray,
	resetPasswordTemplate,
	translateName,
	auroraMeetTemplate,
	auroraApplianceTemplate,
	sarimTemplate,
} from 'utils';
import {
	TAtomicFilter,
	TGroupedOptions,
	TNestedFilter,
	TOption,
} from 'types/app';

import InputFilter from './filter-input/nested-filter-input.component';
import { useSelector } from 'react-redux';
import { selectorAuth } from 'store/auth/auth.slice';
import ActionSelector from './action-selector';
import { selectorApp } from 'store/app/app.slice';
import Table from 'services/table.service';

interface TActionProps {
	currentAction: TAction;
	open: boolean;
	actionIndex: number;
	getActionType: (action: TAction) => TActionType;
	setOpen: Dispatch<SetStateAction<'event' | 'condition' | number | undefined>>;
	actionOptions: TGroupedOptions;
	translateConditions: (
		conditions: TAtomicCondition | TNestedConditions,
		tableSchema: TSchema
	) => TAtomicFilter | TNestedFilter;
	translateFilter: (
		filters: TAtomicFilter | TNestedFilter
	) => TAtomicCondition | TNestedConditions;
	getActionOptions: (index: number) => TOption[];
	onDelete?: () => void;
	editAction: (action: TAction) => void;
}

type TActionType = keyof Omit<TAction, 'actionRef'>;

const ActionEdit: FC<TActionProps> = ({
	currentAction,
	actionIndex,
	editAction,
	getActionType,
	getActionOptions,
	open,
	setOpen,
	onDelete,
	actionOptions,
	translateConditions,
	translateFilter,
}) => {
	const { actionRef, math, crudl, mail } = currentAction;

	const [actionType, setActionType] = useState<TActionType>(
		getActionType(currentAction)
	);

	const [table, setTable] = useState<TStructure>();

	const { tables } = useSelector(selectorAuth);
	const { brand, visibleDefaultFields, t } = useSelector(selectorApp);

	useEffect(() => {
		setActionType(getActionType(currentAction));
	}, [currentAction]);

	useEffect(() => setTable(Table.getTable(tables, actionRef)), [actionRef]);

	return (
		<FlexCol>
			<SectionConnector />
			<SectionWrapper
				open={open}
				setOpen={setOpen}
				section={actionIndex}
				onDelete={onDelete}
			>
				<Label color={open ? 'black' : 'white'}>{t('automations.type')}</Label>
				<InputSelectionAutomation
					isFirst
					defaultSelected={currentAction[actionType]?.operation}
					id={`actionselection${actionIndex}`}
					onSelection={(op, group) => {
						let type: keyof Omit<TAction, 'actionRef'> = 'crudl';
						switch (group) {
							case `${t('automations.action.crudl')}`:
								type = 'crudl';
								break;
							case `${t('automations.action.math')}`:
								type = 'math';
								break;
							case `${t('automations.action.api')}`:
								type = 'api';
								break;
							case `${t('automations.action.mail')}`:
								type = 'mail';
								break;
							case `${t('automations.action.error')}`:
								type = 'error';
								break;
						}

						let newAction: Partial<TAction> = {};

						let newOperation: Partial<TCrudl | TMath | TApi | TMail> = {
							operation: op,
						};

						if (type === 'crudl') {
							newAction.actionRef = actionRef;
							if (op === 'create') (newOperation as TCrudl).elements = [{}];
							else {
								if (['updateMany', 'updateOne'].includes(op))
									(newOperation as TCrudl).options = { update: {} };
								(newOperation as TCrudl).options = {
									...((newOperation as TCrudl).options ?? {}),
									filter: {},
								};
							}
						}
						if (type === 'mail') {
							(newOperation as TMail).variables = [];
						}
						newAction[type] = newOperation;
						editAction(newAction);
					}}
					options={actionOptions}
				/>
				{crudl && (
					<>
						<Label className='mt-3' color={open ? 'black' : 'white'}>
							{t('table.name')}
						</Label>
						<InputSelectionAutomation
							defaultSelected={actionRef}
							id={`actiontableselection${actionIndex}`}
							onSelection={(actionRef) =>
								editAction({
									...currentAction,
									actionRef,
								})
							}
							options={{
								_nogroup: tables.map(({ tableName, alias }) => ({
									label: translateName(tableName, alias, t, language),
									value: tableName,
								})),
							}}
						/>
						{table && (
							<>
								{crudl.operation === 'create' ? (
									<>
										<Label className='mt-2' color={open ? 'black' : 'white'}>
											{t('table.elements')}
										</Label>
										{crudl.elements?.map((element, ind) => (
											<ActionSelector
												onPlusElementClick={() => {
													editAction({
														...currentAction,
														crudl: {
															...crudl,
															elements: [...(crudl.elements ?? []), {}],
														},
													});
												}}
												getActionOptions={getActionOptions}
												actionIndex={actionIndex}
												key={`actionElement${actionIndex}${ind}`}
												editElement={(element) => {
													const newElements = replaceElementInArray(
														crudl.elements ?? [],
														element,
														ind
													);
													editAction({
														actionRef,
														crudl: {
															...crudl,
															elements: newElements,
														},
													});
												}}
												currentElement={element}
												table={table}
												refId={`actionSelector${actionIndex}${ind}`}
											/>
										))}
									</>
								) : (
									<>
										<Label className='mt-3' color={open ? 'black' : 'white'}>
											{`${t('automations.elements_have')}...`}
										</Label>
										<InputFilter
											automations={{ actionIndex, getActionOptions }}
											id={`action${actionIndex}filter`}
											options={createFilterOptions(
												table.tableSchema,
												t,
												visibleDefaultFields
											)}
											defaultFilter={translateConditions(
												crudl.options?.filter ?? {},
												table.tableSchema
											)}
											setValue={(value) =>
												editAction({
													actionRef,
													crudl: {
														...currentAction.crudl,
														options: {
															filter: translateFilter(value),
														},
													} as TCrudl,
												})
											}
										/>
									</>
								)}
								{crudl.operation.includes('update') && (
									<>
										<Label className='mt-2' color={open ? 'black' : 'white'}>
											{t('automations.elements_to_update')}
										</Label>
										<ActionSelector
											getActionOptions={getActionOptions}
											actionIndex={actionIndex}
											key={`actionUpdate${actionIndex}`}
											editElement={(update) => {
												editAction({
													actionRef,
													crudl: {
														...crudl,
														options: { ...crudl.options, update },
													},
												});
											}}
											currentElement={crudl.options?.update}
											table={table}
											refId={`actionUpdateSelector${actionIndex}`}
										/>
									</>
								)}
							</>
						)}
					</>
				)}
				{math && (
					<>
						{math.operation === 'random' && (
							<FlexCol className='mt-3'>
								<Label color={open ? 'black' : 'white'}>
									{t('automations.random_digits')}
								</Label>
								<ActionSelector
									editElement={(options) => {
										editAction({
											math: {
												...math,
												options,
												elements: [],
											},
										});
									}}
									currentElement={math.options}
									actionIndex={actionIndex}
									refId={`actionMath${actionIndex}`}
									table={{
										tableName: '',
										tableSchema: {
											value: { type: 'Number', min: 0, max: 10 },
										},
									}}
									getActionOptions={getActionOptions}
								/>
							</FlexCol>
						)}
					</>
				)}
				{mail && (
					<FlexCol>
						<Label color={open ? 'black' : 'white'} className='mt-3'>
							{t('automations.receiver')}
						</Label>
						<ActionSelector
							editElement={({ to }) => {
								editAction({
									mail: {
										...mail,
										to: to as TOperand,
									},
								});
							}}
							currentElement={mail.to ? { to: mail.to } : undefined}
							actionIndex={actionIndex}
							refId={`actionMailReceiver${actionIndex}`}
							table={{
								tableName: '',
								tableSchema: { to: { type: 'String', alias: 'Destinatario' } },
							}}
							getActionOptions={getActionOptions}
						/>
						{mail.to && (
							<>
								<Label color={open ? 'black' : 'white'}>
									{t('automations.mail_template')}
								</Label>
								<InputSelectionAutomation
									defaultSelected={
										mail.html && mail.subject
											? { html: mail.html, subject: mail.subject }
											: undefined
									}
									id={`actionMailTemplate${actionIndex}`}
									onSelection={(template) =>
										editAction({ mail: { ...mail, ...template } })
									}
									options={{
										_nogroup: [
											{
												label: `${t('automations.password_reset')}`,
												value: resetPasswordTemplate(brand.name, t),
											},
											{
												label: `${t('automations.mail_confirm')}`,
												value: confirmEmailTemplate(brand.name, t),
											},
											{
												label: 'Aurora Candidatura Template',
												value: auroraApplianceTemplate(brand.name),
											},
											{
												label: 'Aurora Coach Template',
												value: auroraMeetTemplate(brand.name),
											},
											{
												label: 'Sarim Template',
												value: sarimTemplate(brand.name),
											},
										],
									}}
								/>
								{/* TODO: Temporary e-mail templates */}
								{mail.html &&
								!['Sarim', 'AuroraFellows'].includes(brand.name) ? (
									<>
										<Label className='mt-3' color={open ? 'black' : 'white'}>
											{t('automations.field_as_code')}
										</Label>
										<ActionSelector
											editElement={({ code }) => {
												editAction({
													mail: {
														...mail,
														variables: [code] as TOperand[],
													},
												});
											}}
											currentElement={
												isNotEmpty(mail.variables)
													? { code: mail.variables[0] }
													: undefined
											}
											actionIndex={actionIndex}
											refId={`actionMailCode${actionIndex}`}
											table={{
												tableName: '',
												tableSchema: { code: { type: 'String' } },
											}}
											getActionOptions={getActionOptions}
										/>
									</>
								) : (
									<>
										<Label className='mt-3'> {t('table.name')}</Label>
										<InputSelectionAutomation
											defaultSelected={actionRef}
											id={`actiontableselection${actionIndex}`}
											onSelection={(actionRef) =>
												editAction({
													...currentAction,
													actionRef,
												})
											}
											options={{
												_nogroup: tables.map(({ tableName, alias }) => ({
													label: translateName(tableName, alias, t, language),
													value: tableName,
												})),
											}}
										/>
									</>
								)}
							</>
						)}
					</FlexCol>
				)}
			</SectionWrapper>
		</FlexCol>
	);
};

export default ActionEdit;
