import { Plus } from 'components/basic/assets';
import { ActionButton } from 'components/basic/buttons';
import { InputSelection } from 'components/basic/inputs';
import { FC, HTMLAttributes } from 'react';
import { TSelectionProps } from 'types/app';

export const InputSelectionElement: FC<
	HTMLAttributes<HTMLDivElement> & TSelectionProps
> = (props) => (
	<InputSelection
		{...props}
		hasBorder
		buttonClass='bg-white px-2 ml-2 min-h-6 text-xs'
		optionClass='px-2 min-h-6 text-xs'
		optionsListClass='bg-white'
		lastRounded
	/>
);

export const InputSelectionFilter: FC<
	HTMLAttributes<HTMLDivElement> & TSelectionProps
> = (props) => (
	<InputSelection
		{...props}
		labelClass='px-2 mt-3'
		buttonClass='py-2 px-3 text-xs mr-2 bg-white flex-1'
		optionClass='py-2 px-3 text-xs'
		optionsListClass='bg-white rounded-b'
	/>
);

export const AddFilter: FC<HTMLAttributes<HTMLDivElement>> = ({
	children,
	className = '',
	...props
}) => (
	<ActionButton
		Icon={<Plus className='opacity-50 w-2 h-2 min-w-2 mr-0.5' />}
		className={`w-content my-1 ${className}`}
		{...props}
	>
		{children}
	</ActionButton>
);
