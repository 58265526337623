import { FC, useState } from 'react';
import { ClickableScreen, TableModal } from 'components/basic/others';
import { TTableModalProps } from 'types/app';
import CronInput from 'components/hoc/cron-input';

interface CronModalProps extends TTableModalProps {
	onClose: (value?: any) => void;
	defaultCron?: string;
}

const CronModal: FC<CronModalProps> = ({ onClose, defaultCron, refId }) => {
	const [value, setValue] = useState<string>();
	return (
		<>
			<ClickableScreen cb={() => onClose(value)} />
			<TableModal zIndex={31} refId={refId} className='py-4 px-3'>
				<CronInput
					onChange={(cron) => setValue(cron)}
					defaultCron={defaultCron}
				/>
			</TableModal>
		</>
	);
};

export default CronModal;
