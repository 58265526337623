import { FlexAlign, FlexCol, Label, Text } from 'components/basic';
import { Polygon } from 'components/basic/assets';
import { FilledButton } from 'components/basic/buttons';
import { InputCheckbox, InputSelection } from 'components/basic/inputs';
import { CommonModal } from 'components/basic/others';
import { FC, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectorApp } from 'store/app/app.slice';
import { TGeoCoord, TGeoFeatures, TModalOpt, TOption } from 'types/app';
import { TStructure } from 'types/table';
import { replaceElementInArray } from 'utils';
const GeojsonModal: FC<
	TModalOpt & {
		table?: {
			geo: TStructure;
			current: TStructure;
		};
		features: TGeoFeatures;
		options?: TOption[];
		uploadGeojson: (
			table: {
				geo: TStructure;
				current: TStructure;
			},
			polygons: { coordinates: TGeoCoord; reference: string }[],
			deleteOld: boolean
		) => Promise<void>;
	}
> = ({ onHide, features, table, options = [], uploadGeojson }) => {
	const { t } = useSelector(selectorApp);
	const [references, setReferences] = useState<string[]>([]);
	const [deleteOld, setDeleteOld] = useState<boolean>(true);

	const onClose = () => {
		setDeleteOld(true);
		setReferences([]);
		onHide();
	};

	useEffect(() => {
		setReferences(Array(features.length).fill(undefined));
	}, [features]);

	const onSubmit = async () => {
		if (!table) return;
		for (const ref of references) {
			if (!ref) return toast.warning('Missing reference');
		}
		uploadGeojson(
			table,
			features.map(({ geometry: { coordinates } }, index) => ({
				coordinates,
				reference: references[index],
			})),
			deleteOld
		);
		onClose();
	};

	return (
		<CommonModal
			className='overflow-auto'
			onHide={onClose}
			header={t('modal.geojson.import')}
		>
			<Label>{t('modal.geojson.polygons')}</Label>
			{features.map(({ geometry: { coordinates }, properties }, index) => (
				<FlexCol key={`feature${index}`}>
					<FlexAlign className='mt-2 mb-3 justify-between'>
						<FlexAlign>
							<Text fontWeight='semibold' color='primary' className='mr-4'>
								{index + 1}.
							</Text>
							<Polygon className='mr-1' />
							<Text>{coordinates.flat().length}</Text>
						</FlexAlign>
						<Text color='black-light'>
							({Object.values(properties).toString()})
						</Text>
					</FlexAlign>
					<InputSelection
						id={`feature:${index}`}
						lastRounded
						hasBorder
						buttonClass='bg-transparent min-h-8 px-4 text-black'
						optionsListClass='bg-white'
						optionClass='min-h-8 px-4 text-black'
						onSelection={(value) =>
							setReferences(replaceElementInArray(references, value, index))
						}
						options={{ _nogroup: options }}
						defaultSelected={references[index]}
					/>
				</FlexCol>
			))}
			<InputCheckbox
				checked={deleteOld}
				setValue={(value) => setDeleteOld(value)}
				text='Sostituisci righe tabella'
				className='w-full justify-center mt-6'
			/>
			<FilledButton type='submit' className='mt-6' onClick={onSubmit}>
				{`${t('placeholder.import')} Geojson`}
			</FilledButton>
		</CommonModal>
	);
};

export default GeojsonModal;
