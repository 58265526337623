import { FC, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import PublicRoute from 'routes/public.route';
import { useSelector } from 'react-redux';
import { logout, selectorAuth } from 'store/auth/auth.slice';
import Api from 'services/api.service';
import MainRoute from 'routes/main.route';
import { useAppDispatch } from 'store';
import { ScreenLoader, Toast } from 'components/basic/others';
import { subDirectory } from 'config';
import { FlexRow } from 'components/basic';
import {
	clearApp,
	selectorApp,
	setBrand,
	setPreviewSession,
	setTranslateFunction,
} from 'store/app/app.slice';
import { applyBrand } from 'themes/brand';
import { translate } from 'utils';
import { useFormatMessage } from 'react-intl-hooks';

const App: FC = () => {
	const [firstRender, setFirstRender] = useState(true);
	const [appLoading, setAppLoading] = useState(true);
	const { access_token, user, tables } = useSelector(selectorAuth);
	const { brand, visibleDefaultFields } = useSelector(selectorApp);
	const dispatch = useAppDispatch();

	const getBrand = async () => {
		try {
			const { data } = await Api.getBrand();
			dispatch(setBrand(data));
		} catch (err) {
			applyBrand(brand);
		}
		setAppLoading(false);
	};

	const t = useFormatMessage();

	if (firstRender) {
		if (!tables || !visibleDefaultFields) dispatch(clearApp());
		getBrand();
		Api.on401(() => {
			dispatch(logout());
		});
		Api.setTemporaryToken(access_token);
		dispatch(setTranslateFunction(translate(t)));
		dispatch(setPreviewSession([]));
		setFirstRender(false);
	}

	useEffect(() => {
		Api.setTemporaryToken(access_token);
	}, [access_token]);

	if (appLoading) return <ScreenLoader />;

	return (
		<FlexRow className='content-center justify-center min-h-screen w-screen overflow-hidden bg-gray'>
			<Toast />
			<Router basename={subDirectory}>
				{user ? <MainRoute /> : <PublicRoute />}
			</Router>
		</FlexRow>
	);
};

export default App;
