import { FC } from 'react';
import Login from 'container/login.screen';
import { Route, Redirect, Switch } from 'react-router-dom';
import { FlexRow } from 'components/basic';

const PublicRoute: FC = () => {
	return (
		<FlexRow className='content-center justify-center h-full w-full'>
			<Switch>
				<Route path='/login' component={Login} exact />
				<Redirect from='/' to='/login' />
			</Switch>
		</FlexRow>
	);
};

export default PublicRoute;
