import { FlexAlign, FlexCol, Label, Text } from 'components/basic';
import { FC, useState, useEffect } from 'react';
import { TOption } from 'types/app';
import { TCron } from 'types/table';
import { isEmpty, replaceElementInArray } from 'utils';
import cronParser from 'cron-parser';
import moment from 'moment';
import lodash from 'lodash';
import { Loader } from 'components/basic/others';

import { InputSelection } from 'components/basic/inputs';
import { selectorApp } from 'store/app/app.slice';
import { useSelector } from 'react-redux';
interface TCronInputProps {
	defaultCron?: string;
	onChange: (value: string) => void;
}

const CronInput: FC<TCronInputProps> = ({ defaultCron, onChange }) => {
	const baseCron = {
		minute: '*',
		hour: '*',
		monthDay: '*',
		month: '*',
		weekDay: '*',
	};
	const baseCustom = Array(5).fill([]);
	const [cron, setCron] = useState<TCron>();
	const [custom, setCustom] = useState<number[][]>();
	const [advice, setAdvice] = useState<string>('');
	const { t } = useSelector(selectorApp);

	const getValuesBetween = (value: string) => {
		const [first, last] = value.split('-').map((v) => parseInt(v));
		if (isNaN(first) || isNaN(last) || first > last) return [];
		const values: number[] = [];
		for (let i = first; i < last; i++) {
			values.push(i);
		}
		return values;
	};

	const translateCron = (
		text: string | undefined
	): { cron: TCron; custom: number[][] } => {
		if (isEmpty(text)) return { cron: baseCron, custom: baseCustom };
		let cronValues = text?.split(' ') ?? [];
		const customValues: number[][] = baseCustom;

		cronValues.forEach((value, index) => {
			// if value is not composed of only numbers commas and dashes
			if (
				value
					.split('')
					.some((char) => isNaN(parseInt(char)) && ![',', '-'].includes(char))
			)
				return;

			cronValues[index] = 'custom';
			customValues[index] = value
				.split(',')
				.map((value) => (value.includes('-') ? getValuesBetween(value) : value))
				.flat()
				.map((value) =>
					isNaN(parseInt(value as string)) ? null : parseInt(value as string)
				)
				.filter((value) => value !== null) as number[];
		});

		const [minute, hour, monthDay, month, weekDay] = cronValues;
		return {
			cron: { minute, hour, monthDay, month, weekDay },
			custom: customValues,
		};
	};

	const getOptions = (type: keyof TCron): TOption[] => {
		switch (type) {
			case 'minute':
				return [
					{ value: 'custom', label: `${t('input.cron.custom')}` },
					{
						value: '*',
						label: `${t('input.cron.every')} ${t('input.cron.minute')}`,
					},
					{
						value: '*/2',
						label: `${t('input.cron.every')} ${t('input.cron.minute')} ${t(
							'input.cron.even'
						)}`,
					},
					{
						value: '1-59/2',
						label: `${t('input.cron.every')} ${t('input.cron.minute')} ${t(
							'input.cron.odd'
						)}`,
					},
					{
						value: '*/5',
						label: `${t('input.cron.every')} 5 ${t('input.cron.minutes')}`,
					},
					{
						value: '*/10',
						label: `${t('input.cron.every')} 10 ${t('input.cron.minutes')}`,
					},
					{
						value: '*/15',
						label: `${t('input.cron.every')} 15 ${t('input.cron.minutes')}`,
					},
					{
						value: '*/20',
						label: `${t('input.cron.every')} 20 ${t('input.cron.minutes')}`,
					},
					{
						value: '*/25',
						label: `${t('input.cron.every')} 25 ${t('input.cron.minutes')}`,
					},
					{
						value: '*/30',
						label: `${t('input.cron.every')} 30 ${t('input.cron.minutes')}`,
					},
				];
			case 'hour':
				return [
					{ value: 'custom', label: `${t('input.cron.custom')}` },
					{
						value: '*',
						label: `${t('input.cron.every')} ${t('input.cron.hour')}`,
					},
					{
						value: '*/2',
						label: `${t('input.cron.every')} ${t('input.cron.hour')} ${t(
							'input.cron.even'
						)}`,
					},
					{
						value: '1-23/2',
						label: `${t('input.cron.every')} ${t('input.cron.hour')} ${t(
							'input.cron.odd'
						)}`,
					},
					{
						value: '*/3',
						label: `${t('input.cron.every')} 3 ${t('input.cron.hours')}`,
					},
					{
						value: '*/4',
						label: `${t('input.cron.every')} 4 ${t('input.cron.hours')}`,
					},
					{
						value: '*/6',
						label: `${t('input.cron.every')} 6 ${t('input.cron.hours')}`,
					},
					{
						value: '*/8',
						label: `${t('input.cron.every')} 8 ${t('input.cron.hours')}`,
					},
					{
						value: '*/12',
						label: `${t('input.cron.every')} 12 ${t('input.cron.hours')}`,
					},
				];
			case 'monthDay':
				return [
					{ value: 'custom', label: `${t('input.cron.custom')}` },
					{
						value: '*',
						label: `${t('input.cron.every')} ${t('input.cron.day')}`,
					},
					{
						value: '*/2',
						label: `${t('input.cron.every')} ${t('input.cron.day')} ${t(
							'input.cron.even'
						)}`,
					},
					{
						value: '1-31/2',
						label: `${t('input.cron.every')} ${t('input.cron.day')} ${t(
							'input.cron.odd'
						)}`,
					},

					{
						value: '*/3',
						label: `${t('input.cron.every')} 3 ${t('input.cron.days')}`,
					},
					{
						value: '*/5',
						label: `${t('input.cron.every')} 5 ${t('input.cron.days')}`,
					},
					{
						value: '*/10',
						label: `${t('input.cron.every')} 10 ${t('input.cron.days')}`,
					},
					{
						value: '*/15',
						label: `${t('input.cron.every')} 15 ${t('input.cron.days')}`,
					},
				];
			case 'month':
				return [
					{ value: 'custom', label: `${t('input.cron.custom')}` },
					{
						value: '*',
						label: `${t('input.cron.every')} ${t('input.cron.month')}`,
					},
					{
						value: '1-11/2',
						label: `${t('input.cron.every')} ${t('input.cron.month')} ${t(
							'input.cron.even'
						)}`,
					},
					{
						value: '*/2',
						label: `${t('input.cron.every')} ${t('input.cron.month')} ${t(
							'input.cron.odd'
						)}`,
					},
					{
						value: '*/3',
						label: `${t('input.cron.every')} 3 ${t('input.cron.months')}`,
					},

					{
						value: '*/4',
						label: `${t('input.cron.every')} 4 ${t('input.cron.months')}`,
					},
					{
						value: '*/6',
						label: `${t('input.cron.every')} 6 ${t('input.cron.months')}`,
					},
				];
			case 'weekDay':
				return [
					{ value: 'custom', label: `${t('input.cron.custom')}` },
					{
						value: '*',
						label: `${t('input.cron.every')} ${t('input.cron.weekDay')}`,
					},
					{
						value: '1-5',
						label: `${moment().weekday(1).format('dddd')} ${moment()
							.weekday(5)
							.format('dddd')}`,
					},
					{ value: '0,6', label: `${t('input.cron.weekend')}` },
				];
		}
	};

	const getCustomOptions = (type: keyof TCron): TOption[] => {
		switch (type) {
			case 'minute':
				return Array(60)
					.fill(1)
					.map((_, ind) => ({ label: ind, value: ind }));
			case 'hour':
				return Array(24)
					.fill(1)
					.map((_, ind) => ({ label: ind, value: ind }));
			case 'monthDay':
				return Array(31)
					.fill(1)
					.map((_, ind) => ({ label: ind + 1, value: ind + 1 }));
			case 'month':
				return Array(12)
					.fill(1)
					.map((_, ind) => ({
						label: moment(ind + 1, 'M').format('MMMM'),
						value: ind + 1,
					}));
			case 'weekDay':
				return Array(7)
					.fill(1)
					.map((_, ind) => ({
						label: moment.weekdays()[ind],
						value: ind,
					}));
		}
	};

	useEffect(() => {
		const { cron, custom } = translateCron(defaultCron);
		setCustom(custom);
		setCron(cron);
	}, []);

	useEffect(() => {
		cron && submitCron(cron);
	}, [cron, custom]);

	if (!custom || !cron) return <Loader />;

	const submitCron = (newCron: TCron) => {
		if (
			Object.values(newCron).some(
				(value, index) => value === 'custom' && isEmpty(custom[index])
			)
		) {
			return setAdvice(`${t('input.cron.custom_values')}`);
		}

		let calculatedCron = lodash.cloneDeep(newCron);

		Object.entries(calculatedCron).forEach(([calc, value], ind) => {
			if (value === 'custom')
				calculatedCron[calc as keyof TCron] = lodash
					.sortBy(custom[ind])
					.join(',');
		});

		const cronTab = Object.values(calculatedCron).join(' ');

		const parser = cronParser.parseExpression(cronTab);
		setAdvice(
			`${t('input.cron.next_outcome')} ${moment(parser.next().toDate()).format(
				'YYYY-MM-DD HH:mm'
			)}`
		);

		onChange(cronTab);
	};

	return (
		<FlexCol>
			<Label>{advice}</Label>
			{Object.entries(cron).map(
				([type, value], index) =>
					!['monthDay', 'month'].includes(type) && (
						<FlexAlign
							key={`cron${type},${index}`}
							className={`${index < 4 ? 'mb-2' : ''}`}
						>
							<Text
								color='primary'
								fontSize='xs'
								fontWeight='semibold'
								className='w-20'
							>
								{t(`input.cron.${type}`)}
							</Text>
							<InputSelection
								options={{ _nogroup: getOptions(type as keyof TCron) }}
								defaultSelected={value}
								id={`cronInput:${type}`}
								onSelection={(sel) => setCron({ ...cron, [type]: sel })}
								buttonClass='py-2 px-3 text-xs mr-2 bg-white w-40'
								optionClass='py-2 px-3 text-xs'
								hasBorder
								optionsListClass='bg-white rounded-b'
							/>
							{value === 'custom' && (
								<InputSelection
									multiple
									buttonClass='py-2 px-3 text-xs mr-2 bg-white w-32'
									optionClass='py-2 px-3 text-xs'
									hasBorder
									optionsListClass='bg-white rounded-b'
									options={{ _nogroup: getCustomOptions(type as keyof TCron) }}
									defaultSelected={custom[index]}
									id={`cronInputCustom:${type}`}
									onSelection={(sel) => {
										setCustom(replaceElementInArray(custom, sel, index));
									}}
								/>
							)}
						</FlexAlign>
					)
			)}
		</FlexCol>
	);
};

export default CronInput;
