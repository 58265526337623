import { InputArray, InputSelection } from 'components/basic/inputs';
import { FlexCol, FlexRow } from 'components/basic';
import { FC, HTMLAttributes } from 'react';
import { TInputArrayProps, TSelectionProps } from 'types/app';

export const InputRow: FC<HTMLAttributes<HTMLDivElement>> = ({
	children,
	...props
}) => (
	<FlexCol className='w-full min-h-50px mb-3' {...props}>
		{children}
	</FlexCol>
);

export const CheckboxWrapper: FC = ({ children, ...props }) => (
	<FlexRow className='mr-2 mt-2' {...props}>
		{children}
	</FlexRow>
);

export const InputSelectionModal: FC<
	HTMLAttributes<HTMLDivElement> & TSelectionProps
> = (props) => (
	<InputSelection
		{...props}
		hasBorder
		buttonClass='min-h-8 px-4 text-black bg-white'
		optionsListClass=''
		optionClass='min-h-8 px-4 text-black bg-white'
		lastRounded
	/>
);

export const InputArrayModal: FC<
	HTMLAttributes<HTMLDivElement> & TInputArrayProps
> = ({ inputProps, className = '', ...props }) => (
	<InputArray
		{...props}
		className={`-mr-2 min-h-8 flex-wrap ${className}`}
		inputProps={{
			...inputProps,
			className: `h-7 focus:ring-2 focus:ring-primary rounded ${
				inputProps?.className ?? ''
			}`,
		}}
	/>
);
