import { FC, useState /*  useEffect  */ } from 'react';
import { FlexAlign, FlexCol, Text } from 'components/basic';
/* import RoleList from 'components/hoc/role-list';
 */ import SystemList from 'components/hoc/system-list';
import TablesList from 'components/hoc/tables-list';
import { TStructure, TStructureProperties } from 'types/table';
import {
	handleError,
	language,
	replaceElementInArray,
	translateName,
} from 'utils'; /* 
import { TRole, TUser } from 'types/auth'; */
import Api from 'services/api.service';
import {
	TAlias,
	TModalOperationOpt,
	TSchemaOpt,
	TTableOperation,
} from 'types/app';
import UpsertTableModal from 'components/modal/upsert-table.modal';

import ConfirmModal from 'components/modal/confirm.modal';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { selectorAuth, setTables } from 'store/auth/auth.slice';
import { useHistory, useLocation } from 'react-router-dom';
import { selectorApp } from 'store/app/app.slice';
import { HorizontalArrow } from 'components/basic/assets';

const Sider: FC = () => {
	const { t } = useSelector(selectorApp);
	const history = useHistory();
	const { pathname } = useLocation();
	const dispatch = useAppDispatch();
	const { tables } = useSelector(selectorAuth); /* 
	const [users, setUsers] = useState<TUser[]>([]);
	const [roles, setRoles] = useState<TRole[]>([]); */
	const [tableModal, setTableModal] = useState<Partial<TModalOperationOpt>>();
	const [confirmDelete, setConfirmDelete] =
		useState<{ name: string; alias: string }>();
	const [visible, setVisible] = useState<boolean>(true);

	// LOAD DATAS
	/* // TODO: Users are not in User table
	const loadRoles = async () => {
		try {
			const {
				data: { roles },
			} = await Api.listRoles();
			setRoles(roles);
		} catch (error) {
			handleError(error);
		}
	};

	const loadUsers = async () => {
		try {
			const {
				data: { users },
			} = await Api.listUsers();
			setUsers(users);
		} catch (error) {
			handleError(error);
		}
	};

	useEffect(() => {
		loadUsers();
		loadRoles();
	}, []); */

	const reloadTables = (tableName: string, table: TStructure) => {
		const tableIndex = tables.findIndex(
			({ tableName: name }) => tableName === name
		);
		if (tableIndex > 0)
			dispatch(setTables(replaceElementInArray(tables, table, tableIndex)));
	};

	// EDIT TABLES

	const addTable = async (
		tableName: string,
		properties: TStructureProperties
	) => {
		try {
			const { data: newTable } = await Api.createTable({
				tableName,
				tableSchema: {},
				...properties,
			});
			dispatch(setTables([...tables, newTable]));
			history.push(`/dashboard/${tableName}`);
		} catch (error) {
			handleError(error);
		}
	};

	const deleteTable = async (tableName: string) => {
		try {
			await Api.deleteTable(tableName);
			const newTables = tables.filter((tab) => tab.tableName !== tableName);
			if (pathname.split('/').pop() === tableName)
				history.push(`/dashboard/${newTables[0]?.tableName ?? ''}`);
			dispatch(setTables(newTables));
		} catch (error) {
			handleError(error);
		}
	};

	const renameTable = async (tableName: string, newTableName: string) => {
		try {
			if (tableName === newTableName) return;
			const { data: table } = await Api.renameTable(tableName, newTableName);
			if (pathname.split('/').pop() === tableName)
				history.push(`/dashboard/${newTableName}`);
			reloadTables(tableName, table);
		} catch (error) {
			handleError(error);
		}
	};

	const updateProperties = async (
		tableName: string,
		properties: TStructureProperties
	) => {
		try {
			const { data: table } = await Api.editTableProperties(
				tableName,
				properties
			);
			reloadTables(tableName, table);
		} catch (error) {
			handleError(error);
		}
	};

	const handleOperation = async (tableOperation: TTableOperation) => {
		if (!tableOperation) return;
		const { operation, table } = tableOperation;
		switch (operation as TSchemaOpt) {
			case 'addTable':
				setTableModal({ table, operation });
				break;
			case 'editTable':
				setTableModal({
					table: tables.filter((tab) => tab.tableName === table.tableName)?.[0],
					operation,
				});
				break;
			case 'deleteTable':
				setConfirmDelete({
					alias: translateName(table.tableName, table.alias, t, language),
					name: table.tableName,
				});
				break;
		}
	};

	return (
		<>
			<FlexCol
				onMouseEnter={() => setVisible(true)}
				className={`max-h-full-with-header overflow-x-hidden flex-1 ${
					visible ? 'min-w-content max-w-content' : 'min-w-3 max-w-3'
				}`}
			>
				<TablesList startOperation={handleOperation} />
				<SystemList />
				{/* 	<RoleList users={users} roles={roles} /> */}
				{!!tableModal && (
					<UpsertTableModal
						addTable={addTable}
						renameTable={renameTable}
						updateProperties={updateProperties}
						onHide={() => setTableModal(undefined)}
						operation={tableModal?.operation}
						table={tableModal?.table}
					/>
				)}
				{!!confirmDelete && (
					<ConfirmModal
						header={`${t('placeholder.remove')} ${t('table.name')}`}
						onHide={() => setConfirmDelete(undefined)}
						confirmText={t('placeholder.delete')}
						explanation={
							<FlexCol>
								<Text>{`${t('modal.remove_one')} ${
									confirmDelete?.alias
								}?`}</Text>
								<Text>{t('modal.remove_two')}</Text>
							</FlexCol>
						}
						onConfirm={() => confirmDelete && deleteTable(confirmDelete.name)}
					/>
				)}
			</FlexCol>
			<FlexAlign
				onClick={() => setVisible(!visible)}
				className='cursor-pointer min-h-full-with-header w-3 mx-1'
			>
				<HorizontalArrow
					className={`transition-all ease-in duration-smooth transform ${
						visible ? 'rotate-180' : 'rotate-0'
					}`}
				/>
			</FlexAlign>
		</>
	);
};

export default Sider;
