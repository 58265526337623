import {
	forwardRef,
	InputHTMLAttributes,
	FC,
	HTMLAttributes,
	useState,
	useEffect,
	useRef,
	TextareaHTMLAttributes,
} from 'react';
import Dropzone from 'react-dropzone';
import Api from 'services/api.service';
import {
	TAlias,
	TCheckboxProps,
	TColor,
	TGroupedOptions,
	TInputArrayProps,
	TLanguage,
	TOption,
	TSelectionListProps,
	TSelectionProps,
} from 'types/app';
import {
	isEmpty,
	handleError,
	locales,
	isNotEmpty,
	replaceObjectKey,
	singleToDoubleDigit,
	toggleElementInArray,
	translateHex,
	trimText,
} from 'utils';
import { ArrowSmall, Clock, Cross, Flag, TextText } from './assets';
import { AddButton, Checkbox, OptionButton } from './buttons';
import { Chip, ClickableScreen } from './others';
import { FlexAlign, FlexCol, Label, Text } from '.';
import { TFile, TLocation } from 'types/table';
import lodash from 'lodash';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectorApp } from 'store/app/app.slice';

export const InputText = forwardRef<
	HTMLInputElement,
	InputHTMLAttributes<HTMLInputElement>
>(({ className = '', ...props }, ref) => (
	<input
		ref={ref}
		className={`font-normal w-full text-sm rounded text-black bg-gray h-8 px-4 ring-primary focus:ring-2 focus:outline-none ${className}`}
		{...props}
	/>
));

export const InputArea = forwardRef<
	HTMLTextAreaElement,
	TextareaHTMLAttributes<HTMLTextAreaElement>
>(({ className = '', ...props }, ref) => (
	<textarea
		ref={ref}
		className={`font-normal w-full text-sm rounded text-black bg-gray px-4 py-1 focus:outline-none focus:ring-primary focus:ring-2 ${className}`}
		{...props}
	/>
));

export const InputCheckbox: FC<
	HTMLAttributes<HTMLDivElement> &
		TCheckboxProps & {
			setValue: (value: boolean) => void;
			text: string | any;
			circle?: boolean;
		}
> = ({
	className = '',
	checked = false,
	text,
	circle,
	setValue,
	color = 'gray',
	...props
}) => (
	<FlexAlign
		onClick={() => setValue(!checked)}
		className={`cursor-pointer ${className}`}
		{...props}
	>
		{circle ? (
			<OptionButton color={color} checked={checked} className='mr-2' />
		) : (
			<Checkbox color={color} checked={checked} className='mr-2' />
		)}
		{text}
	</FlexAlign>
);

export const InputToggle: FC<
	HTMLAttributes<HTMLDivElement> & {
		checked?: boolean;
		setValue: (value: boolean) => void;
		background?: string;
	}
> = ({
	className = '',
	checked = false,
	background = 'primary',
	setValue,
	...props
}) => (
	<FlexAlign
		onClick={(e) => {
			e.stopPropagation();
			setValue(!checked);
		}}
		className={`cursor-pointer h-4 w-7 p-1 rounded-circle bg-${background} ${className}`}
		{...props}
	>
		<div
			className={`bg-white rounded-circle w-2 h-2 transition-all duration-quick ease-linear ${
				checked ? 'ml-3' : 'ml-0'
			}`}
		/>
	</FlexAlign>
);

export const InputArray: FC<HTMLAttributes<HTMLDivElement> & TInputArrayProps> =
	({ className = '', setValue, defaultValue, inputProps, ...props }) => {
		const [words, setWords] = useState<string[] | undefined>(defaultValue);
		const inputRef = useRef<HTMLInputElement>(null);

		useEffect(() => {
			if (words && words.length > 0) {
				setValue(words);
				inputRef.current?.focus();
			} else setValue(undefined);
		}, [words]);

		return (
			<FlexAlign
				className={`w-full rounded bg-gray pl-1 pr-3 ${className}`}
				{...props}
			>
				{words?.map((word, index) => (
					<Chip className='m-1' key={`input${word}${index}`}>
						{trimText(word, 20)}
						<Cross
							onClick={() =>
								setWords(words?.filter((_w, ind) => ind !== index))
							}
							className='w-2 h-2 ml-1 cursor-pointer'
						/>
					</Chip>
				))}
				<input
					{...inputProps}
					ref={inputRef}
					className={`pl-1 bg-transparent focus:outline-none min-w-100px ${
						!words || words.length === 0 ? 'w-full' : ''
					} ${inputProps?.className ?? ''}`}
					onKeyDown={(e) => {
						const emptyInput =
							!e.currentTarget.value ||
							e.currentTarget.value.replace(' ', '') === '';
						if (e.key === 'Backspace') {
							if (emptyInput) {
								e.currentTarget.value = words?.[words.length - 1] ?? '';
								setWords(words?.filter((_w, ind) => ind !== words?.length - 1));
							}
						}
						if (e.key === 'Enter' || e.key === 'Tab') {
							e.preventDefault();
							if (!emptyInput) {
								const currentWords = words ?? [];
								setWords([...currentWords, e.currentTarget.value.trim()]);
							}
							e.currentTarget.value = '';
						}
					}}
				/>
			</FlexAlign>
		);
	};

export const InputFile: FC<{
	multiple?: boolean;
	onDrop: (files: TFile[]) => void;
	className?: string;
	uploadToBackend?: boolean;
	mimetypes?: string[];
	fieldName?: string;
	tableName?: string;
}> = ({
	multiple = false,
	uploadToBackend = true,
	onDrop,
	children,
	className,
	mimetypes,
	fieldName,
	tableName = 'uploadPlaceholder',
}) => (
	<Dropzone
		accept={mimetypes}
		multiple={multiple}
		onDrop={async (files) => {
			const upload: any = {};
			files.forEach((file: File, index) => {
				upload[index] = file;
			});
			if (uploadToBackend) {
				try {
					const { data } = await Api.uploadFiles(tableName, upload, fieldName);
					const newFiles = Object.keys(data).map((index) => data[index]);
					onDrop(newFiles);
				} catch (error) {
					handleError(error);
				}
			} else onDrop(files as any);
		}}
	>
		{({ getInputProps, getRootProps }) => (
			<div
				className={`cursor-pointer hover:bg-primary-15 ${className}`}
				{...getRootProps()}
			>
				<input {...getInputProps()} />
				{children}
			</div>
		)}
	</Dropzone>
);

export const InputTimeRange: FC<InputHTMLAttributes<HTMLInputElement>> = (
	props
) => {
	const [type, setType] = useState<'text' | 'time'>();
	const { t } = useSelector(selectorApp);

	useEffect(() => {
		setType(
			props.defaultValue && !/\d{2}:\d{2}/.test(props.defaultValue as string)
				? 'text'
				: 'time'
		);
	}, [props.defaultValue]);

	const buttonCSS = 'border-primary ring-1 ring-primary h-4 px-0.5';

	return (
		<FlexAlign>
			<input
				{...props}
				className='rounded-small h-4 w-18 text-black px-2 py-1 focus:outline-none focus:ring-primary focus:ring-2 text-xs bg-gray'
				type={type}
				placeholder={`${t('placeholder.insert')}`}
			/>
			<FlexAlign className='ml-2 cursor-pointer'>
				<FlexAlign
					className={`rounded-l-small ${buttonCSS} ${
						type === 'time' ? 'bg-primary' : 'bg-white'
					}`}
					onClick={() => setType('time')}
				>
					<Clock color={type === 'time' ? 'white' : 'primary'} />
				</FlexAlign>
				<FlexAlign
					className={`rounded-r-small ${buttonCSS} ${
						type === 'text' ? 'bg-primary' : 'bg-white'
					}`}
					onClick={() => setType('text')}
				>
					<TextText color={type === 'text' ? 'white' : 'primary'} />
				</FlexAlign>
			</FlexAlign>
		</FlexAlign>
	);
};

export const InputPeriod: FC<{
	defaultPeriod?: string;
	onChange: (value: string) => void;
	inputProps?: InputHTMLAttributes<HTMLInputElement>;
}> = ({ defaultPeriod, onChange, inputProps }) => {
	const [value, setValue] = useState<{ day?: string; month?: string }>({});

	useEffect(() => {
		if (defaultPeriod) {
			const [day, month] = (defaultPeriod as string).split('/');
			setValue({ day, month });
		}
	}, []);

	useEffect(() => {
		if (value.day && value.month) {
			onChange(
				`${singleToDoubleDigit(
					Math.min(31, parseInt(value.day))
				)}/${singleToDoubleDigit(Math.min(12, parseInt(value.month)))}`
			);
		}
	}, [value]);

	const inputCSS = 'h-4 w-9 text-black py-1 focus:outline-none text-xs bg-gray';

	return (
		<FlexAlign className='bg-gray px-2 rounded-small hover:border-primary hover:border-2'>
			<input
				defaultValue={value?.day}
				onBlur={({ currentTarget: { value: day } }) =>
					setValue({ ...value, day })
				}
				{...inputProps}
				className={`${inputCSS} ${inputProps?.className ?? ''}`}
				type='number'
				min={1}
				max={31}
				placeholder='DD'
			/>
			<Text fontSize='xs' color='black-light'>
				/
			</Text>
			<input
				defaultValue={value.month}
				onBlur={({ currentTarget: { value: month } }) =>
					setValue({ ...value, month })
				}
				{...inputProps}
				className={`${inputCSS} ml-1 ${inputProps?.className ?? ''}`}
				type='number'
				min={1}
				max={12}
				placeholder='MM'
			/>
		</FlexAlign>
	);
};

export const InputLocation: FC<{
	defaultValue?: TLocation;
	onChange: (value: Partial<TLocation> | undefined) => void;
}> = ({ defaultValue, onChange }) => {
	const [value, setValue] = useState<Partial<TLocation>>(defaultValue ?? {});

	useEffect(() => {
		onChange(value);
	}, [value]);

	const inputCSS =
		'h-4 w-9 text-black py-2 ml-1 focus:outline-none text-xs bg-gray z-20 h-11';

	return (
		<FlexAlign className='bg-gray px-2 rounded-small hover:ring-primary hover:ring-2 z-20'>
			<FlexAlign className='flex-1'>
				<Text fontSize='xs' color='black-light'>
					lat:
				</Text>
				<input
					defaultValue={value.lat}
					onChange={({ currentTarget: { value: lat } }) =>
						setValue({ ...value, lat: parseFloat(lat) })
					}
					className={inputCSS}
					type='number'
					min={-90}
					max={90}
				/>
			</FlexAlign>
			<FlexAlign className='flex-1'>
				<Text fontSize='xs' color='black-light'>
					lng:
				</Text>
				<input
					defaultValue={value.lng}
					onChange={({ currentTarget: { value: lng } }) =>
						setValue({ ...value, lng: parseFloat(lng) })
					}
					className={inputCSS}
					type='number'
					min={-180}
					max={180}
				/>
			</FlexAlign>
		</FlexAlign>
	);
};

export const SelectionOptions: FC<
	HTMLAttributes<HTMLDivElement> & TSelectionListProps
> = ({
	options,
	onSelection,
	optionClass = '',
	labelClass = '',
	className = '',
	lastRounded,
	multiple,
	zIndex = 20,
	hasBorder = false,
	id,
	visible,
	hovered,
	setHovered,
	setVisible,
	defaultSelected,
	...props
}) => {
	const [selected, setSelected] = useState<TOption | TOption[] | undefined>(
		defaultSelected
	);

	const [{ top, left, minWidth }, setPosition] = useState<{
		top: number;
		left: number;
		minWidth: number;
	}>({
		top: 0,
		left: 0,
		minWidth: 0,
	});

	useEffect(() => {
		setSelected(defaultSelected);
	}, [defaultSelected]);

	useEffect(() => {
		// Change Position when visible is toggled on
		if (!id || !visible) return;
		const element = document.getElementById(id);
		if (!element) return;
		const { top, left, height, width } = element.getBoundingClientRect() ?? {
			top: 0,
			left: 0,
			height: 0,
			width: 0,
		};
		setPosition({ top: top + height - 2, left, minWidth: width + 10 });
	}, [visible, id]);

	if ((top <= 0 && left <= 0) || isEmpty(options)) return <></>;

	const toggleVisible = () => {
		if (visible) setHovered(false);
		setVisible(!visible);
	};

	return (
		<>
			{visible && <ClickableScreen cb={toggleVisible} />}
			<FlexCol
				className={`fixed overflow-y-auto transition-maxh duration-quick bg-white shadow-md ${
					visible
						? `max-h-200px ease-in ${
								hasBorder
									? `rounded-b rounded-tr border-2 ${
											hovered ? 'border-primary' : 'border-gray-dark'
									  }`
									: ''
						  }`
						: `max-h-0 ease-out`
				} ${className}`}
				style={{
					zIndex,
					top,
					left,
					minWidth,
				}}
				{...props}
			>
				{Object.entries(options).map(
					([groupLabel, opts], ind) =>
						opts.length > 0 && (
							<FlexCol key={`selectiongroup${ind}`}>
								{groupLabel !== '_nogroup' && (
									<Label className={`cursor-default ${labelClass}`}>
										{groupLabel}
									</Label>
								)}
								{opts.map(({ label, value }, index) => (
									<FlexAlign
										onMouseEnter={() => setHovered(true)}
										onMouseLeave={() => setHovered(false)}
										onClick={() => {
											if (multiple) {
												const newSelected = toggleElementInArray(
													{ label, value },
													(selected as TOption[]) ?? []
												);
												onSelection(
													newSelected.map(({ value }) => value),
													groupLabel
												);
												setSelected(newSelected);
											} else {
												onSelection(value, groupLabel);
												setSelected({ label, value });
												toggleVisible();
											}
										}}
										key={`opt:${index}`}
										className={`w-full cursor-pointer whitespace-nowrap hover:bg-primary hover:text-white ${optionClass}`}
									>
										{multiple && (
											<Checkbox
												className='mr-2'
												checked={(
													Object.values(selected ?? {}).flat() as TOption[]
												).some((opt) => lodash.isEqual(opt.value, value))}
											/>
										)}
										{label}
									</FlexAlign>
								))}
							</FlexCol>
						)
				)}
			</FlexCol>
		</>
	);
};

export const InputSelection: FC<
	HTMLAttributes<HTMLDivElement> & TSelectionProps
> = ({
	defaultSelected,
	options,
	onSelection,
	buttonClass = 'w-content',
	optionClass = '',
	labelClass = '',
	optionsListClass = '',
	arrowColor = 'primary',
	hasBorder = false,
	lastRounded,
	multiple,
	zIndex = 20,
	id,
	...props
}) => {
	const [visible, setVisible] = useState<boolean>(false);
	const [hovered, setHovered] = useState<boolean>(false);
	const [selected, setSelected] = useState<TOption | TOption[]>();
	const [deep, setDeep] = useState<number>(zIndex);

	const [actualOptions, setActualOptions] = useState<TGroupedOptions>(options);

	useEffect(() => {
		setActualOptions(
			multiple
				? options
				: Object.entries(options).reduce(
						(options, [group, opts]) => ({
							...options,
							[group]: opts.filter(
								(option) => !lodash.isEqual(option, selected as TOption)
							),
						}),
						{}
				  )
		);
	}, [options, selected]);

	useEffect(() => {
		if (visible) setDeep((z) => z + 100);
		else setTimeout(() => setDeep(zIndex), 200);
	}, [visible, zIndex]);

	useEffect(() => {
		const flattenOptions = Object.values(options).flat();

		if (multiple) {
			setSelected(
				flattenOptions.filter(({ value }) => defaultSelected?.includes(value))
			);
		} else
			setSelected(
				flattenOptions.find(({ value }) =>
					lodash.isEqual(value, defaultSelected)
				)
			);
	}, [defaultSelected, options]);

	const { t } = useSelector(selectorApp);

	const toggleVisible = () => isNotEmpty(actualOptions) && setVisible(!visible);

	return (
		<>
			<FlexAlign
				id={id}
				className={`cursor-pointer justify-between whitespace-nowrap ${
					hasBorder
						? `border-2 ${
								hovered
									? `border-${visible ? 'selection-primary' : 'primary'}`
									: `border-${visible ? 'selection-gray' : 'gray-dark'}`
						  } ${visible ? 'rounded-t' : 'rounded'}`
						: ''
				} ${buttonClass}`}
				onClick={toggleVisible}
				style={{ zIndex: deep + 1 }}
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				{...props}
			>
				{isNotEmpty(selected)
					? Array.isArray(selected)
						? `${selected[0].label} ${
								selected.length > 1 ? `+ ${selected.length - 1}` : ''
						  }`
						: (selected as TOption).label
					: t('placeholder.select')}
				{isNotEmpty(actualOptions) && (
					<ArrowSmall
						color={arrowColor}
						className={`transition-all ml-2 duration-quick transform ${
							visible ? 'rotate-0' : 'rotate-180'
						}`}
					/>
				)}
			</FlexAlign>
			<SelectionOptions
				hovered={hovered}
				setHovered={setHovered}
				visible={visible}
				setVisible={setVisible}
				options={actualOptions}
				id={id}
				onSelection={onSelection}
				className={optionsListClass}
				optionClass={optionClass}
				labelClass={labelClass}
				zIndex={deep}
				lastRounded={lastRounded}
				multiple={multiple}
				hasBorder={hasBorder}
				defaultSelected={selected}
			/>
		</>
	);
};

export const InputColor: FC<{
	id: string;
	onChange: (value: TColor) => void;
	defaultValue?: TColor;
}> = ({ onChange, defaultValue, id }) => {
	const [inputType, setInputType] = useState<'text' | 'rgb'>('text');
	const [color, setColor] = useState<TColor>(
		defaultValue ?? {
			r: 0,
			g: 0,
			b: 0,
		}
	);

	useEffect(() => {
		onChange(color);
	}, [color]);

	const inputRGB = (rgb: 'r' | 'g' | 'b') => (
		<FlexAlign>
			<Text fontSize='xs' className='mx-1'>
				{rgb.toUpperCase()}:
			</Text>
			<input
				type='number'
				min={0}
				max={255}
				onChange={(e) => {
					const value = parseInt(e.currentTarget.value);
					if (value > 255) e.currentTarget.value = '255';
					if (value < 0) e.currentTarget.value = '0';
					setColor({
						...color,
						[rgb]: parseInt(e.currentTarget.value),
					});
				}}
				defaultValue={color[rgb]}
				className='rounded px-2 py-1 text-xs focus:ring-2 focus:ring-primary focus:outline-none max-w-100px'
			/>
		</FlexAlign>
	);

	return (
		<FlexAlign className='rounded border border-gray-dark p-2'>
			<div
				className='rounded w-4 h-4 mr-2 min-w-4'
				style={{
					backgroundColor: `rgb(${color.r},${color.g},${color.b})`,
				}}
			/>
			{inputType === 'text' ? (
				<input
					defaultValue={`#${color.r.toString(16)}${color.g.toString(
						16
					)}${color.b.toString(16)}`.toUpperCase()}
					maxLength={7}
					onBlur={(e) => setColor(translateHex(e.currentTarget?.value))}
					className='rounded px-2 py-1 text-xs focus:ring-2 focus:ring-primary focus:outline-none max-w-100px'
				/>
			) : (
				<FlexAlign>
					{inputRGB('r')}
					{inputRGB('g')}
					{inputRGB('b')}
				</FlexAlign>
			)}
			<InputSelection
				id={id}
				defaultSelected={inputType}
				onSelection={(value) => setInputType(value)}
				buttonClass='ml-2 p-1 text-xs'
				optionsListClass='bg-white rounded-b'
				optionClass='p-1 text-xs'
				options={{
					_nogroup: [
						{ label: 'HEX', value: 'text' },
						{ label: 'RGB', value: 'rgb' },
					],
				}}
			/>
		</FlexAlign>
	);
};

export const InputAlias: FC<{
	id: string;
	onChange: (value: TAlias) => void;
	defaultValue?: TAlias;
}> = ({ onChange, defaultValue, id }) => {
	const { t } = useSelector(selectorApp);

	const [translations, setTranslations] = useState<TAlias>(defaultValue ?? {});
	const [hasMore, setHasMore] = useState<boolean>(true);
	const languages = Object.keys(locales);

	const addInput = () => {
		const newTranslation = languages.filter(
			(existing) => !Object.keys(translations).includes(existing)
		)[0];
		if (!newTranslation) {
			return toast.warning(`${t('toast.no_language_warning')}`);
		}
		for (const lang of Object.values(translations)) {
			if (!lang) return;
		}
		setTranslations({ ...translations, [newTranslation]: undefined });
	};

	useEffect(() => {
		onChange(translations);
		const newTranslation = languages.filter(
			(existing) => !Object.keys(translations).includes(existing)
		)[0];
		if (!newTranslation) setHasMore(false);
	}, [translations]);

	return (
		<FlexCol>
			{Object.entries(translations).map(([lang, value], index) => (
				<FlexAlign key={`${lang}:${value}`} className='mb-3'>
					<InputText
						defaultValue={(value as string) ?? ''}
						onBlur={({ currentTarget: { value } }) =>
							setTranslations({
								...translations,
								[lang]: value,
							})
						}
					/>
					<div className='-ml-14'>
						<InputSelection
							id={`${id}${index}`}
							buttonClass='p-1 w-50px bg-gray'
							optionsListClass='bg-gray'
							optionClass='p-1'
							lastRounded
							onSelection={(value) => {
								if (Object.keys(translations).includes(value))
									return toast.warning(`${t('toast.double_language')}`);
								setTranslations(
									replaceObjectKey(
										translations,
										lang,
										value,
										translations[lang as TLanguage]
									)
								);
							}}
							defaultSelected={lang}
							options={{
								_nogroup: languages.map((lan) => ({
									label: <Flag lang={lan as TLanguage} />,
									value: lan,
								})),
							}}
						/>
					</div>
				</FlexAlign>
			))}
			{hasMore && <AddButton onClick={addInput} />}
		</FlexCol>
	);
};
