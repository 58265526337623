import { AxiosError } from 'axios';
import { baseUrl } from 'config';
import { toast } from 'react-toastify';
import {
	TAlias,
	TAtomicFilter,
	TColor,
	TLanguage,
	TNestedFilter,
	TOption,
	TColumn,
	TTranslateMessage,
} from 'types/app';
import locale_it from 'translations/it.json';
import locale_en from 'translations/en.json';
import locale_es from 'translations/es.json';
import 'moment/locale/en-gb';
import 'moment/locale/it';
import moment from 'moment';
import lodash from 'lodash';
import {
	TElement,
	TGroupedElements,
	TObjectIdColumn,
	TSchema,
	TSelectionAlias,
} from 'types/table';
import { v4 as uuidv4 } from 'uuid';
import { TAtomicCondition, TMail, TNestedConditions } from 'types/automation';
import Table from 'services/table.service';
import { MessageDescriptor } from 'react-intl';
import Compressor from 'compressorjs';

export const armoniaColors: TColor[] = [
	{
		name: 'purple',
		r: 82,
		g: 83,
		b: 152,
	},
	{
		name: 'red',
		r: 255,
		g: 80,
		b: 79,
	},
	{
		name: 'yellow',
		r: 255,
		g: 178,
		b: 55,
	},
	{
		name: 'green',
		r: 29,
		g: 193,
		b: 66,
	},
	{
		name: 'blue',
		r: 0,
		g: 137,
		b: 223,
	},
];

export const translateHex = (hex: string = ''): TColor => {
	if (!hex) return { r: 0, g: 0, b: 0 };
	const [r, g, b] = hex.replace(/[ #]/g, '').match(/.{1,2}/g) as string[];
	return {
		r: isNaN(parseInt(r, 16)) ? 0 : parseInt(r, 16),
		g: isNaN(parseInt(g, 16)) ? 0 : parseInt(g, 16),
		b: isNaN(parseInt(b, 16)) ? 0 : parseInt(b, 16),
	};
};

export const translate =
	(t: (message: MessageDescriptor) => React.ReactNode): TTranslateMessage =>
	(id: string) =>
		t({ id });

export const trimText = (text: string, maxLength: number) => {
	if (!text || typeof text !== 'string') return '';
	if (text.length <= maxLength || !text) return text;
	return `${text.slice(0, maxLength)}...`;
};

export const makeUrl = (url?: string) => {
	if (!url) return '';
	if (url.startsWith('http') || url.startsWith('blob')) return url;
	if (url.startsWith('%PUBLIC_URL%/')) {
		return url.replace('%PUBLIC_URL%/', '');
	}
	return `${baseUrl}/${url}`;
};

export const randomAvatarColor = (text: string) =>
	text?.split('').reduce((acc, next) => acc + next.charCodeAt(0), 0) %
	armoniaColors.length;

export const handleError = (error: Error | AxiosError | any) => {
	const message = (error as AxiosError)?.response?.data?.message;
	if (message)
		toast.error(message, {
			toastId: message,
		});
	else toast.error(error.message ?? error);
};

export const replaceObjectKey = (
	obj: object,
	keyToRemove: string,
	keyToAdd: string,
	newValue: any = undefined
) => {
	// Replaces key in an object by keeping them ordered for some functions (ex. Object.keys)
	const entries = Object.entries(obj);
	if (!entries.some(([key]) => key === keyToRemove))
		return { ...obj, [keyToAdd]: newValue };
	const newObj: any = {};
	for (const [key, value] of entries) {
		if (key !== keyToRemove) newObj[key] = value;
		//if no newValue is given it just renames the key
		else newObj[keyToAdd] = newValue ?? value;
	}
	return newObj;
};

export const toggleElementInArray = <T>(
	elem: T,
	arr: T[] = [],
	customFilter?: (value: T, index: number, array: T[]) => void
) => {
	// IF ELEM IN ARRAY REMOVE IT
	// ELSE ADD IT TO ARRAY
	// RETURN THE RESULTING ARRAY
	if (arr.some((value) => lodash.isEqual(elem, value))) {
		return customFilter
			? arr.filter(customFilter)
			: arr.filter((value: T) => !lodash.isEqual(value, elem));
	} else return [...arr, elem];
};

export const replaceElementInArray = <T>(
	array: T[],
	element: T,
	index: number
) => [
	...array.slice(0, index),
	element,
	...array.slice(index + 1, array.length),
];

export const streamToFile = ({
	stream,
	mimetype,
	fileName,
}: {
	stream: string;
	mimetype: string;
	fileName: string;
}): { file: File; blob: Blob } => {
	// STREAM MUST BE BASE64
	console.log(stream);
	const byteCharacters = atob(stream);
	let byteNumbers = [];
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers.push(byteCharacters.charCodeAt(i));
	}
	const byteArray = new Uint8Array(byteNumbers);
	const blobFile = new Blob([byteArray], { type: mimetype });
	return { file: new File([blobFile], fileName), blob: blobFile };
};

export const downloadFile = (
	stream: string,
	fileName: string,
	mimetype: string
) => {
	const { file, blob } = streamToFile({ stream, fileName, mimetype });
	const fileURL = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = fileURL;
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
	return file;
};

export const FileCompressor = async (
	files: { [file: string]: File },
	maxHeight?: number,
	maxWidth?: number
) => {
	for (const [fileName, file] of Object.entries(files)) {
		if (file.type.startsWith('image') && file.type !== 'image/svg+xml') {
			files[fileName] = new File(
				[
					await new Promise((resolve, reject) => {
						new Compressor(file, {
							mimeType: file.type,
							maxHeight,
							maxWidth,
							success: (file) => resolve(file),
							error: (err) => reject(err),
						});
					}),
				],
				file.name ?? fileName,
				{ type: file.type }
			);
		}
	}
};

// TODO: Type fix, does not work if given type is an | of two types
export const elemToArray = <T>(elem: T | T[]): T[] =>
	Array.isArray(elem) ? elem : [elem];

export const locales = {
	it: locale_it,
	en: locale_en,
	es: locale_es,
};

export const language = (() => {
	const browserLang: keyof typeof locales = navigator.language.split(
		/[-_]/
	)[0] as keyof typeof locales;
	if (browserLang !== 'it') {
		moment.locale('en');
		return 'en';
	}

	moment.locale(browserLang);
	return browserLang;
})();

export const nFormatter = (num: number) => {
	if (isNaN(num)) return num;
	if (num >= 1000000000) {
		return `${(num / Math.pow(2, 30)).toFixed(1).replace(/\.0$/, '')} GB`;
	}
	if (num >= 1000000) {
		return `${(num / Math.pow(2, 20)).toFixed(1).replace(/\.0$/, '')} MB`;
	}
	if (num >= 1000) {
		return `${(num / Math.pow(2, 10)).toFixed(1).replace(/\.0$/, '')} KB`;
	}
	return `${num} B`;
};

const emptyArray = [null, undefined];

export const isNotEmpty = (value: any): boolean => {
	if (
		emptyArray.includes(value) ||
		(typeof value === 'string' && value.trim() === '')
	)
		return false;
	if (Array.isArray(value)) {
		if (value.length === 0) return false;
		// if at least one element is not empty return true
		return value.some((elem) => isNotEmpty(elem));
	}
	if (typeof value === 'object') {
		const values = Object.values(value);
		// stringify does not support circular JSON, such as DOM elements
		if (/* JSON.stringify(value) === '{}' || */ values.length === 0)
			return false;
		// if at least one element is not empty return true
		return values.some((elem) => isNotEmpty(elem));
	}
	return true;
};

export const isEmpty = (value: any): boolean => !isNotEmpty(value);

export const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'] as const;

export const createColumnPath = (column: TObjectIdColumn): string =>
	column
		? `${column.value}${
				Table.getType(column.schema.type) === 'ObjectId'
					? `.${createColumnPath(column.schema.column)}`
					: ''
		  }`
		: '';

export const createFilterOptions = (
	tableSchema: TSchema,
	t: TTranslateMessage,
	visibleDefaultFields: string[],
	isFilter: boolean = false
): TOption[] =>
	Object.entries(tableSchema)
		.filter(
			([fieldName, { type }]) =>
				(isFilter
					? ['String', 'Number', 'Boolean', 'ObjectId'].includes(
							Table.getType(type)
					  )
					: true) &&
				(!Table.defaultFields.includes(fieldName) ||
					(Table.defaultFields.includes(fieldName) &&
						visibleDefaultFields?.includes(fieldName)))
		)
		.map(([fieldName, fieldSchema]) => ({
			value: {
				fieldName: createColumnPath({ value: fieldName, schema: fieldSchema }),
				fieldSchema: {
					...fieldSchema,
					type: Table.getType(fieldSchema.type),
				},
			},
			label: translateName(fieldName, fieldSchema?.alias, t, language),
		}));

export const getFilterModes = (
	column: TColumn,
	t: TTranslateMessage
): TOption[] => {
	// takes possible modes depending on field type
	if (!column?.fieldSchema || !column?.fieldName) return [];
	const modes = [
		{ value: 'contains', label: t('filter.mode.contains') },
		{ value: 'notContains', label: t('filter.mode.not_contains') },
		{ value: 'isEqual', label: t('filter.mode.is') },
		{ value: 'isNotEqual', label: t('filter.mode.is_not') },
	];
	const fieldType = Table.getType(column.fieldSchema.type);
	switch (fieldType) {
		case 'String':
			if (column.fieldName === '_id' || column.fieldSchema.enum)
				return modes.filter(({ value }) =>
					['isEqual', 'isNotEqual'].includes(value)
				);
			return modes;
		case 'Number':
			return [
				...modes.filter(({ value }) =>
					['isEqual', 'isNotEqual'].includes(value)
				),
				{ value: 'isGreater', label: t('filter.mode.greater') },
				{ value: 'isLesser', label: t('filter.mode.lesser') },
			];
		case 'ObjectId':
			const { schema, value } = column.fieldSchema.column;
			return getFilterModes({ fieldSchema: schema, fieldName: value }, t);
		case 'Boolean':
			return modes.filter(({ value }) =>
				['isEqual', 'isNotEqual'].includes(value)
			);
		default:
			return modes.filter(({ value }) =>
				['isEqual', 'isNotEqual'].includes(value)
			);
	}
};

export const generateTranslatedEnum = (enums: TAlias[]): TSelectionAlias => {
	if (!Array.isArray(enums)) return enums;
	return enums.reduce((prev, next) => {
		const id = uuidv4();
		for (const lang of Object.keys(locales)) {
			prev[lang as TLanguage] = {
				...(prev[lang as TLanguage] ?? {}),
				[id]: next[lang as TLanguage],
			};
		}
		return prev;
	}, {} as TSelectionAlias);
};

export const translateName = (
	name: string,
	alias: string | TAlias,
	t: TTranslateMessage,
	language: TLanguage
): string => {
	if (Table.defaultFields.includes(name)) return `${t(`table.column.${name}`)}`;
	if (isEmpty(alias)) return name;
	return typeof alias === 'string'
		? alias
		: alias[language] ??
				Object.keys(locales)
					.filter((lang) => lang !== language)
					.find((lang) => !!alias[lang as TLanguage]) ??
				'';
};

export const mergeObjectOfArray = (
	objone: TGroupedElements,
	objtwo: TGroupedElements
): TGroupedElements => {
	const isArrayMerge =
		Object.values(objone).some((value) => Array.isArray(value)) ||
		Object.values(objtwo).some((value) => Array.isArray(value));
	const newObject: TGroupedElements = {};
	let newKeys = [...Object.keys(objone), ...Object.keys(objtwo)];
	newKeys = newKeys.filter((key, ind) => newKeys.indexOf(key) === ind);
	for (const key of newKeys) {
		if (isArrayMerge) {
			newObject[key] = [
				...((objone[key] as TElement[]) ?? []),
				...((objtwo[key] as TElement[]) ?? []),
			];
		} else {
			newObject[key] = mergeObjectOfArray(
				objone[key] as TGroupedElements,
				objtwo[key] as TGroupedElements
			);
		}
	}
	return newObject;
};

export const objectFilter = <T extends object>(
	obj: T,
	filter: ([key, value]: [string, unknown]) => boolean
) =>
	Object.entries(obj).reduce(
		(newObj, [key, value]) =>
			filter([key, value]) ? { ...newObj, [key]: value } : newObj,
		{} as T | Partial<T>
	);

export const isAtomicFilter = (
	options: TNestedFilter | TAtomicFilter | TAtomicCondition | TNestedConditions
) => !('_or' in options) && !('_and' in options);

export const singleToDoubleDigit = (number: string | number): string => {
	const newNumber = typeof number === 'number' ? number : parseInt(number);
	return newNumber < 10 && newNumber > -10
		? `${newNumber < 0 ? '-' : ''}0${newNumber}`
		: `${newNumber}`;
};

/* TODO: Temporary e-mail templates */

export const resetPasswordTemplate = (
	projectName: string,
	t: TTranslateMessage
): Pick<TMail, 'subject' | 'html'> => ({
	subject: `Reset password di ${projectName}`, // TODO: Translate`${t({id:`Email`})}`,
	html: `<body>
	<h2>Email automatica per il reset della password di ${projectName}.</h2>
	<p><h3>Codice di conferma:</h3></p>
	<p><h1>**code**</h1></p>
</body>`,
});

export const confirmEmailTemplate = (
	projectName: string,
	t: TTranslateMessage
): Pick<TMail, 'subject' | 'html'> => ({
	subject: `Conferma email di ${projectName}`, // TODO: Translate`${t({id:`Email`})}`,
	html: `<body>
	<h2>Email automatica per la conferma email di ${projectName}.</h2>
	<p><h3>Codice di conferma:</h3></p>
	<p><h1>**code**</h1></p>
</body>`,
});

export const sarimTemplate = (projectName: string) => ({
	subject: `Una prenotazione è stata creata per ${projectName}`,
	html: `<body>
	<h2>Email automatica per la creazione di una prenotazione.</h2>
		<p><h4>Utente:</h4> <h1>**firstName** **lastName**</h1></p>
		<p><h4>Categoria di rifiuto:</h4> <h1>**waste.name**</h1></p><h4>Immagine Rifiuto :</h4> <img src="https://db.sarimambiente.it/api/v1/**image.url**"/>
		<p><h4>Indirizzo:</h4> <h1>**address** </p>
		<p><h4>Data:</h4> <h1>**day**</h1></p>
		<p><h4>Citt&agrave;:</h4> <h1>**area.city.name**</h1></p>
		<p><h4>Zona:</h4> <h1>**area.name**</h1></p>
		<p><h4>Codice Fiscale/Partita IVA:</h4> <h1>**fiscalCode**</h1></p>
</body>`,
});

export const auroraApplianceTemplate = (projectName: string) => ({
	subject: `Una candidatura è stata creata per ${projectName}`,
	html: `<body>
		<h2>Email automatica per la creazione di una candidatura.</h2>
		<p><h4>Nome e Cognome: </h4> <h1>**fullName**</h1></p>
		<p><h4>E-mail: </h4> <h1>**email**</h1></p>
		<p><h4>Numero di telefono: </h4> <h1>**phoneNumber**</h1></p>
 		<p><h4>Per il ruolo di: </h4> <h1>**role**</h1></p>
		<p><h4>Motivazione: </h4> <h1>**stars** stelle, **reason**</h1></p>
		<p><h4>Disponibilità: </h4> <h1>**availability**</h1></p><br/><br/>
		<p><h5>Controlla ora la candidatura su </h5> db.aurorafellows.com/dashboard/Appliance</p>
	</body>`,
});

export const auroraMeetTemplate = (projectName: string) => ({
	subject: `Una richiesta per un coach di ${projectName} è stata creata`,
	html: `<body>
		<h2>Email automatica per la creazione di una richiesta di conoscere un caoch.</h2>
		<p><h4>Nome e Cognome richiedente: </h4> <h1>**fullName**</h1></p>
		<p><h4>E-mail: </h4> <h1>**email**</h1></p>
		<p><h4>Coach richiesto: </h4> <h1>**coach**</h1></p>
		<p><h4>Messaggio: </h4> <h1> **message**</h1></p>
		<p><h5>Controlla ora la richiesta su </h5> db.aurorafellows.com/dashboard/Appliance</p>
	</body>`,
});
// Useful function for migrations
export const sleep = async (milliseconds: number) => {
	return new Promise((resolve) => setTimeout(resolve, milliseconds));
};
