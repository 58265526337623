import { FC, useEffect, useState } from 'react';
import { FlexAlign, FlexCol, Text } from 'components/basic';
import { InputPeriod } from 'components/basic/inputs';
import { TRange } from 'types/table';
import { toggleElementInArray } from 'utils';
import { Cross, Plus } from 'components/basic/assets';

import lodash from 'lodash';
import { selectorApp } from 'store/app/app.slice';
import { useSelector } from 'react-redux';

interface PeriodProps {
	setValue: (value: any) => void;
	defaultPeriods?: string[];
	isArray: boolean;
}

const PeriodInput: FC<PeriodProps> = ({
	setValue,
	defaultPeriods,
	isArray,
}) => {
	const defaultPeriod: TRange = { start: undefined, end: '31/12' };
	const { t } = useSelector(selectorApp);
	const [periods, setPeriods] = useState<TRange[]>(
		defaultPeriods && defaultPeriods.length > 0
			? defaultPeriods.map((period) => {
					const [start, end] = period.split('-');
					return { start, end };
			  })
			: [defaultPeriod]
	);

	const onSubmit = () => {
		const newPeriods = periods
			.filter(({ start, end }) => start && end)
			.map(({ start, end }) => `${start}-${end}`);
		setValue(isArray ? newPeriods : newPeriods[0]);
	};

	const handleChange = (key: keyof TRange, value: string, index: number) => {
		setPeriods((p) => {
			const other = key === 'start' ? 'end' : 'start';
			const newRange = { [key]: value, [other]: p[index]?.[other] };
			const newPeriods = lodash.cloneDeep(p);
			newPeriods[index] = newRange;
			return newPeriods;
		});
	};

	useEffect(() => {
		onSubmit();
	}, [periods]);

	return (
		<FlexCol>
			{periods.map(({ start, end }, index) => (
				<FlexAlign key={`input${start}${end}:${index}`} className='pt-3'>
					<Text fontSize='xs' color='primary' className='mr-2'>
						{`${t('input.time.start')}`.toUpperCase()}
					</Text>
					<InputPeriod
						defaultPeriod={start}
						onChange={(value) => handleChange('start', value, index)}
					/>
					<Text fontSize='xs' color='primary' className='mr-2 ml-4'>
						{`${t('input.time.end')}`.toUpperCase()}
					</Text>
					<InputPeriod
						defaultPeriod={end}
						onChange={(value) => handleChange('end', value, index)}
					/>
					{isArray && (
						<>
							<Plus
								className={`ml-3 w-2 min-w-2 h-2 cursor-pointer ${
									index !== periods.length - 1 ? 'opacity-0' : ''
								}`}
								onClick={() => setPeriods((p) => [...p, defaultPeriod])}
							/>
							<Cross
								color='primary'
								className='ml-3 w-2 min-w-2 h-2 opacity-50 cursor-pointer'
								onClick={() =>
									setPeriods((p) => toggleElementInArray(p[index], p))
								}
							/>
						</>
					)}
				</FlexAlign>
			))}
		</FlexCol>
	);
};

export default PeriodInput;
