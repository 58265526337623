import { FC, useState, useEffect } from 'react';
import { FlexAlign, FlexCol, FlexRow, Label } from 'components/basic';
import { FilledButton } from 'components/basic/buttons';
import { InputAlias, InputCheckbox, InputText } from 'components/basic/inputs';
import { CommonModal } from 'components/basic/others';
import { toast } from 'react-toastify';
import { TModalOperationOpt, TAlias, TModalOpt } from 'types/app';
import { isEmpty, isNotEmpty } from 'utils';
import lodash from 'lodash';
import { selectorApp } from 'store/app/app.slice';
import { useSelector } from 'react-redux';
import { TStructureProperties } from 'types/table';

interface UpsertTableProps extends TModalOperationOpt, TModalOpt {
	addTable: (
		tableName: string,
		properties: TStructureProperties
	) => Promise<void>;
	renameTable: (tableName: string, newTableName: string) => Promise<void>;
	updateProperties: (
		tableName: string,
		properties: TStructureProperties
	) => Promise<void>;
}

const UpsertTableModal: FC<UpsertTableProps> = ({
	onHide,
	operation,
	table,
	addTable,
	renameTable,
	updateProperties,
}) => {
	const [alias, setAlias] = useState<TAlias>({});
	const [softDelete, setSoftDelete] = useState<boolean | undefined>(
		table?.softDelete
	);
	const [singleRow, setSingleRow] = useState<boolean | undefined>(
		table?.singleRow
	);
	const [newName, setNewName] = useState<string>();
	const { t } = useSelector(selectorApp);

	useEffect(() => {
		if (!table) return;
		setAlias(table.alias ?? {});
		setSoftDelete(table.softDelete ?? false);
	}, [table]);

	if (!table) return <></>;

	const close = () => {
		setAlias({});
		setNewName(undefined);
		setSoftDelete(false);
		onHide();
	};

	const submitEntity = async () => {
		if (operation === 'addTable') {
			if (isEmpty(newName)) return toast.warning(`${t('toast.no_table_name')}`);
			addTable(newName as string, { alias, softDelete, singleRow });
			return close();
		}
		if (
			!lodash.isEqual(table.alias, alias) ||
			softDelete !== table.softDelete ||
			singleRow !== table.singleRow
		) {
			await updateProperties(table.tableName, { alias, softDelete, singleRow });
		}

		if (isNotEmpty(newName) && newName !== table?.tableName)
			renameTable(table.tableName, newName as string);
		close();
	};

	return (
		<CommonModal
			className='overflow-auto min-w-500px'
			onHide={onHide}
			header={t(`table.operation.${operation}`)}
		>
			<FlexCol>
				<Label>{t('attributes.name')}</Label>
				<InputText
					defaultValue={table.tableName}
					onChange={({ currentTarget: { value } }) => setNewName(value)}
				/>
				<Label className='mt-3'>{t('upsert_table.translation')}</Label>
				<InputAlias
					id='tablealias'
					defaultValue={table.alias ?? alias}
					onChange={(translations) => setAlias(translations)}
				/>
				<FlexAlign className='w-full justify-center mt-3'>
					<InputCheckbox
						setValue={(value) => setSoftDelete(value)}
						text={t('upsert_table.softDelete')}
						checked={softDelete}
					/>
				</FlexAlign>
				<FlexAlign className='w-full justify-center mt-3'>
					<InputCheckbox
						setValue={(value) => setSingleRow(value)}
						text={t('upsert_table.singleRow')}
						checked={singleRow}
					/>
				</FlexAlign>
				<FlexRow className='w-full justify-center'>
					<FilledButton className='mt-8 max-w-300px' onClick={submitEntity}>
						{t(`table.operation.${operation}`)}
					</FilledButton>
				</FlexRow>
			</FlexCol>
		</CommonModal>
	);
};

export default UpsertTableModal;
