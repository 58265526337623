export const baseUrl: string | undefined = `${
	//@ts-ignore
	window.appConfig?.apiUrl ?? process.env.REACT_APP_API_URL
}`;

export const subDirectory: string | undefined =
	//@ts-ignore
	window.appConfig?.subDirectory ?? process.env.REACT_APP_SUB_DIRECTORY;

export const version: string | undefined =
	//@ts-ignore
	window.appConfig?.appVersion ?? process.env.REACT_APP_VERSION;
