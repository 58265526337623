import { FC, useState } from 'react';
import { ArrowSmall } from 'components/basic/assets';
import { useSelector } from 'react-redux';
import { selectorAuth, setUser } from 'store/auth/auth.slice';
import { HeaderUser } from 'components/hoc/avatar';
import { FlexAlign, Text } from 'components/basic';
import {
	ClickableScreen,
	Media,
	Menu,
	ScreenLoader,
} from 'components/basic/others';
import { downloadFile, handleError } from 'utils';
import Api from 'services/api.service';
import moment from 'moment';

import { useAppDispatch } from 'store';
import { InputFile } from 'components/basic/inputs';
import { toast } from 'react-toastify';
import { selectorApp } from 'store/app/app.slice';

const Header: FC = () => {
	const dispatch = useAppDispatch();
	const { user } = useSelector(selectorAuth);
	const { brand, schemaSettings, t } = useSelector(selectorApp);
	const [visible, setVisible] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const exportDB = async () => {
		setLoading(true);
		try {
			const { data } = await Api.exportDB();
			downloadFile(
				data,
				`${brand.name}-${moment().format('YYYY-MM-DD')}.adb.zip`,
				'application/octet-stream'
			);
		} catch (error) {
			handleError(error);
		}
		setLoading(false);
	};

	const importDB = async (database: { [tableName: string]: any }) => {
		setLoading(true);
		try {
			const { data } = await Api.importDB(database);
			toast.success(data?.message);
		} catch (error) {
			handleError(error);
		}
		setLoading(false);
	};

	return (
		<FlexAlign className='justify-between w-full min-h-50px h-14 px-4'>
			{loading && <ScreenLoader />}
			{visible && <ClickableScreen cb={() => setVisible(false)} />}
			<Media
				file={brand.headerLogo}
				className='h-8 min-w-content max-w-500px select-none'
			/>
			<FlexAlign
				className='justify-center cursor-pointer'
				onClick={() => setVisible(!visible)}
				onContextMenu={(e) => {
					e.preventDefault();
					setVisible(!visible);
				}}
			>
				<Text color='primary' fontWeight='bold' fontSize='base'>
					{brand.name}
				</Text>
				<ArrowSmall className='ml-2' id='exportmenu' />
				<Menu
					id='exportmenu'
					className='z-50'
					visible={visible}
					options={[
						...(schemaSettings
							? [
									{
										text: (
											<InputFile
												uploadToBackend={false}
												className='hover:text-white'
												onDrop={(files) => {
													const reader = new FileReader();
													reader.onload = async (e) => {
														const adb = JSON.parse(e.target?.result as string);
														importDB(adb);
													};
													reader.readAsText((files as any[])[0]);
												}}
											>
												{`${t('placeholder.import')} DB`}
											</InputFile>
										),
									},
									{
										onClick: exportDB,
										text: `${t('placeholder.export')} DB`,
									},
							  ]
							: []),
						{
							onClick: () => dispatch(setUser(undefined)),
							text: 'Logout',
						},
					]}
				/>
			</FlexAlign>
			<HeaderUser user={user} />
		</FlexAlign>
	);
};

export default Header;
