import { FC } from 'react';
import { FlexAlign, Text } from 'components/basic';
import { TSelectable } from 'types/app';

interface HeaderElementProps extends TSelectable {
	text: string | any;
}

const HeaderElement: FC<HeaderElementProps> = ({ selected, text }) => {
	return (
		<FlexAlign
			className={`h-8 px-3 cursor-default min-w-content whitespace-nowrap ${
				selected ? 'rounded-t bg-white' : ''
			}`}
		>
			<Text
				className='relative'
				fontWeight='semibold'
				color={!selected ? 'white' : undefined}
			>
				{text}
			</Text>
		</FlexAlign>
	);
};

export default HeaderElement;
