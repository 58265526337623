import { FlexAlign, FlexCol, FlexRow } from 'components/basic';
import { Plus, Save } from 'components/basic/assets';
import { ActionButton, DashedButton } from 'components/basic/buttons';
import AutomationEdit from 'components/hoc/automation-edit/automation-edit.component';
import AutomationElement from 'components/hoc/automation-element';
import { FC, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import Api from 'services/api.service';
import { selectorApp } from 'store/app/app.slice';
import { AnyObject } from 'types/app';
import { TAutomation, TAutomationProperties } from 'types/automation';
import {
	isEmpty,
	handleError,
	replaceElementInArray,
	toggleElementInArray,
} from 'utils';

const Automations: FC = () => {
	const [automations, setAutomations] = useState<TAutomation[]>([]);
	const [deletedAutomations, setDeletedAutomations] = useState<string[]>([]);
	const [filter, setFilter] = useState<AnyObject>();
	const [sort, setSort] = useState<AnyObject>();
	const [selected, setSelected] = useState<number>(0);
	const [properties, setProperties] = useState<TAutomationProperties>();

	const loadProperties = async () => {
		try {
			const {
				data: { events },
			} = await Api.listEvents();
			const {
				data: { conditions },
			} = await Api.listConditions();
			const { data: actions } = await Api.listActions();
			setProperties({
				events,
				conditions,
				actions,
			});
		} catch (error) {
			handleError(error);
		}
	};

	const loadAutomations = async () => {
		try {
			const {
				data: { automations },
			} = await Api.listAutomations(filter, sort);
			setAutomations(automations);
		} catch (error) {
			handleError(error);
		}
	};

	const editAutomations = async () => {
		try {
			automations.forEach(({ conditions }, ind) => {
				if (isEmpty(conditions)) delete automations[ind].conditions;
			});
			await Api.editAutomations(automations);
			if (deletedAutomations) {
				await Api.deleteAutomations(deletedAutomations);
			}
		} catch (error) {
			handleError(error);
		}
	};

	useEffect(() => {
		loadProperties();
	}, []);

	useEffect(() => {
		loadAutomations();
	}, [sort, filter]);

	const { t } = useSelector(selectorApp);

	return (
		<FlexRow className='w-full min-h-full-frame'>
			<FlexCol className='p-4 w-1/2 resize-x min-w-500px border-gray border-r-2'>
				<FlexAlign className='justify-between mb-4'>
					<FlexAlign>
						{/* TODO: Filters	<TableOption
							id='automationoptionfilter'
							type='filter'
							onModalSubmit={(filter) => setFilter(filter)}
							options={[]}
							selectedOption={filter}
							onCancel={() => setFilter({})}
							className='mr-2'
						/>
						<TableOption
							id='automationoptionsort'
							type='sort'
							onModalSubmit={(sort) => setSort(sort)}
							options={[]}
							selectedOption={sort}
							onCancel={() => setSort({})}
					/>*/}
					</FlexAlign>
					<ActionButton Icon={<Save />} onClick={() => editAutomations()}>
						{`${t('placeholder.save')} ${t('placeholder.edits')}`}
					</ActionButton>
				</FlexAlign>
				<DashedButton
					onClick={() =>
						setAutomations([
							...automations,
							{ active: false, name: '', actions: [], event: 'before_create' },
						])
					}
				>
					<FlexAlign className='text-primary'>
						<Plus className='mr-2 w-2 h-2' />
						{t('automations.new')}
					</FlexAlign>
				</DashedButton>
				<FlexCol className='mt-4 pr-1 overflow-y-auto max-h-full-grid'>
					{automations.map((automation, index) => (
						<AutomationElement
							onSubmit={(name, description) =>
								setAutomations(
									replaceElementInArray(
										automations,
										{ ...automation, name, description },
										index
									)
								)
							}
							automation={automation}
							key={`automation:${index}`}
							selected={index === selected}
							onDelete={() => {
								setAutomations(toggleElementInArray(automation, automations));
								if (index === selected) setSelected(0);
								if (automation._id)
									setDeletedAutomations((deleted) => [
										...deleted,
										automation._id as string,
									]);
							}}
							onSelect={() => setSelected(index)}
							onToggle={(active) =>
								setAutomations(
									replaceElementInArray(
										automations,
										{ ...automation, active },
										index
									)
								)
							}
							className='mb-3'
						/>
					))}
				</FlexCol>
			</FlexCol>
			{automations[selected] && properties && (
				<AutomationEdit
					setAutomation={(automation) =>
						setAutomations(
							replaceElementInArray(automations, automation, selected)
						)
					}
					properties={properties}
					automation={automations[selected]}
				/>
			)}
		</FlexRow>
	);
};

export default Automations;
