import { forwardRef, FC, HTMLAttributes } from 'react';

interface TextProps {
	fontWeight?: string;
	fontSize?: string;
	color?: string;
}

export const Text: FC<HTMLAttributes<HTMLDivElement> & TextProps> = ({
	fontWeight = 'normal',
	fontSize = 'sm',
	color = 'black',
	className = '',
	children,
	...props
}) => (
	<div
		{...props}
		className={`${className} text-${color} text-${fontSize} font-${fontWeight}`}
	>
		{children}
	</div>
);

export const Label: FC<HTMLAttributes<HTMLDivElement>> = ({
	className = '',
	children,
	...props
}) => (
	<Text
		fontWeight='semibold'
		fontSize='xs'
		className={`mb-2 cursor-default ${className}`}
		{...props}
	>
		{children}
	</Text>
);

export const FlexRow = forwardRef<
	HTMLDivElement,
	HTMLAttributes<HTMLDivElement>
>(({ className = '', children, ...props }, ref) => (
	<div className={`flex flex-row ${className}`} ref={ref} {...props}>
		{children}
	</div>
));

export const FlexCol = forwardRef<
	HTMLDivElement,
	HTMLAttributes<HTMLDivElement>
>(({ className = '', children, ...props }, ref) => (
	<div className={`flex flex-col ${className}`} ref={ref} {...props}>
		{children}
	</div>
));

export const FlexAlign = forwardRef<
	HTMLDivElement,
	HTMLAttributes<HTMLDivElement>
>(({ className = '', children, ...props }, ref) => (
	<FlexRow className={`items-center ${className}`} ref={ref} {...props}>
		{children}
	</FlexRow>
));

export const Grid = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
	({ className = '', children, ...props }, ref) => (
		<div className={`grid ${className}`} ref={ref} {...props}>
			{children}
		</div>
	)
);
