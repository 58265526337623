import { FC } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Dashboard from 'container/dashboard.screen';
import Header from 'components/layout/header';
import { FlexCol, FlexRow } from 'components/basic';
import Sider from 'components/layout/sider';
import PageHeader from 'components/layout/page-header';
import Settings from 'container/settings.screen';
import Automations from 'container/automations.screen';
import { AppVersion } from 'components/basic/others';

const MainRoute: FC = () => {
	return (
		<FlexCol className='h-full w-full'>
			<Header />
			<FlexRow className='h-full mx-4'>
				<Sider />
				{/* TODO: Columns cannot be expanded */}
				<FlexCol className='max-h-full-with-header flex-1 max-w-full overflow-x-hidden'>
					<PageHeader />
					<FlexCol className='relative w-full min-h-full-frame bg-white rounded-tr'>
						<Switch>
							<Route path='/dashboard' component={Dashboard} exact />
							<Route path='/dashboard/:tableName' component={Dashboard} exact />
							<Route path='/settings' component={Settings} exact />
							<Route path='/automations' component={Automations} exact />
							<Redirect from='/' to='/dashboard' />
						</Switch>
						<AppVersion />
					</FlexCol>
				</FlexCol>
			</FlexRow>
		</FlexCol>
	);
};

export default MainRoute;
