import { FC, useState, useEffect } from 'react';
import { FlexAlign, FlexCol, FlexRow } from 'components/basic';
import { TTableOperation, TTableOptions } from 'types/app';
import { TElement, TStructure, TSchema, TEditedCell } from 'types/table';
import { useSelector } from 'react-redux';
import { selectorApp } from 'store/app/app.slice';
import { createFilterOptions, isEmpty, isNotEmpty, objectFilter } from 'utils';
import TableOption from '../../hoc/table-option';
import Table from 'services/table.service';
import VerticalInput from 'components/hoc/vertical-input';
import { ActionButton } from 'components/basic/buttons';
import { Plus, Save } from 'components/basic/assets';
import { selectorAuth } from 'store/auth/auth.slice';

interface TableProps {
	table: TStructure;
	defaultElement: TElement | undefined;
	startOperation: (operationOpt: TTableOperation) => void;
	setTableOptions: (options: TTableOptions) => void;
	tableOptions: TTableOptions;
}

const VerticalView: FC<TableProps> = ({
	table,
	defaultElement,
	startOperation,
	setTableOptions,
	tableOptions,
}) => {
	const { tableName, tableSchema } = table;
	const { visibleDefaultFields, t, schemaSettings } = useSelector(selectorApp);
	const { user } = useSelector(selectorAuth);
	const [element, setElement] = useState<TElement>(
		defaultElement ?? Table.newElement(user ?? {})
	);

	useEffect(() => {
		setElement(defaultElement ?? Table.newElement(user ?? {}));
	}, [defaultElement]);

	const [fields, setFields] = useState<string[]>([]);
	useEffect(() => {
		if (!tableSchema) return setFields([]);
		const newFields = Object.keys(tableSchema).filter(
			(field) =>
				!Object.keys(tableOptions?.hide ?? {}).includes(field) &&
				(!Table.defaultFields.includes(field) ||
					visibleDefaultFields?.includes(field))
		);

		setFields(newFields);
	}, [table, tableOptions]);

	return (
		<>
			<FlexAlign
				className='p-4 rounded-b bg-white justify-between'
				style={{ zIndex: 32 }}
			>
				<FlexRow>
					<TableOption
						id='tableoptionhide'
						type='hide'
						onCancel={() => setTableOptions({ ...tableOptions, hide: {} })}
						selected={isNotEmpty(tableOptions.hide)}
						className='mr-2'
						onModalSubmit={(hide) =>
							setTableOptions({
								...tableOptions,
								hide: hide as TTableOptions['hide'],
							})
						}
						options={createFilterOptions(tableSchema, t, visibleDefaultFields)}
						defaultOptions={tableOptions.hide}
					/>
					<ActionButton
						Icon={<Plus className='w-13px h-13px' fill='primary' />}
						className='z-20 mr-4'
						onClick={() => startOperation({ operation: 'addColumn', table })}
					>
						{t('table.operation.addField')}
					</ActionButton>
				</FlexRow>
				<ActionButton
					Icon={<Save />}
					onClick={() => {
						if (isEmpty(defaultElement)) {
							startOperation({
								operation: 'insertRow',
								table,
								options: { element },
							});
						} else {
							const immutableFields = [
								...Table.defaultFields,
								...Table.getFilteredFields(tableSchema, 'immutable'),
							];
							// Current Fields are needed because if someone deletes a column the actual value is not deleted
							const currentFields = Object.keys(tableSchema);
							const update = objectFilter(
								element,
								([fieldName]) =>
									!immutableFields.includes(fieldName) &&
									currentFields.includes(fieldName)
							);
							startOperation({
								operation: 'editRow',
								table,
								options: {
									address: { row: element._id, tableName },
									update,
								} as TEditedCell,
							});
						}
					}}
				>
					{`${t('placeholder.save')} ${t('placeholder.edits')}`}
				</ActionButton>
			</FlexAlign>
			<FlexCol className='mx-3 min-h-full-grid'>
				<VerticalInput
					onEditColumn={(column) =>
						startOperation({
							operation: 'editColumn',
							table,
							options: { [column]: tableSchema[column] },
						})
					}
					editableColumn={(column) =>
						!!schemaSettings && !Table.defaultFields.includes(column)
					}
					maxHeight='full-grid'
					element={element}
					table={{
						...table,
						tableSchema: objectFilter(tableSchema, ([key]) =>
							fields.includes(key)
						) as TSchema,
					}}
					isCreate={isEmpty(element)}
					setElement={setElement}
				/>
			</FlexCol>
		</>
	);
};

export default VerticalView;
