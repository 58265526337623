import {
	FC,
	useState,
	useRef,
	useEffect,
	Dispatch,
	SetStateAction,
} from 'react';
import { FlexAlign, FlexCol, FlexRow, Label, Text } from 'components/basic';
import { useSelector } from 'react-redux';
import Table from 'services/table.service';
import { selectorApp } from 'store/app/app.slice';
import { TElement, TStructure } from 'types/table';
import { elemToArray, isEmpty, language, translateName } from 'utils';
import { useResizeDetector } from 'react-resize-detector';
import { Chip, Media } from 'components/basic/others';
import { PrintColumn, PrintField } from 'components/basic/print';
import {
	InputArray,
	InputFile,
	InputLocation,
	InputText,
} from 'components/basic/inputs';
import moment from 'moment';
import CronInput from 'components/hoc/cron-input';
import { Checkbox } from 'components/basic/buttons';
import ObjectIdInput from 'components/hoc/objectid-input';
import MenuInput from 'components/hoc/menu-input';
import PeriodInput from 'components/hoc/period-input';
import TimeRangeInput from 'components/hoc/timerange-input';
import InputTranslation from 'components/hoc/translation-input';
import { InputSelectionModal } from 'components/modal/upsert-column/upsert-column.style';
import { TOption } from 'types/app';
import { ArrowSmall } from 'components/basic/assets';

interface VerticalProps {
	maxHeight?: string | number;
	table: TStructure;
	element: TElement;
	setElement: Dispatch<SetStateAction<TElement>>;
	isCreate: boolean;
	onEditColumn?: (column: string) => void;
	editableColumn?: (column: string) => boolean;
}

const VerticalInput: FC<VerticalProps> = ({
	maxHeight = '60vh',
	table,
	element = {},
	onEditColumn,
	editableColumn = () => false,
	setElement,
	isCreate,
}) => {
	const { tableName, tableSchema } = table;
	const { visibleDefaultFields, t } = useSelector(selectorApp);
	const [selected, setSelected] = useState<string>();
	const [input, setInput] = useState<JSX.Element>();
	const { height: descriptionHeight, ref: descriptionRef } =
		useResizeDetector<HTMLDivElement>();
	const schemaRef = useRef<HTMLDivElement>(null);
	const propertiesAttribute = [
		'required',
		'immutable',
		'unique',
		'enum',
		'translatable',
		'lowercase',
		'uppercase',
		'trim',
		'mimetypes',
	] as const;

	const handleInput = (value: any, field: string) => {
		setElement((elem) => ({ ...elem, [field]: value }));
	};

	const PrintInput = (fieldName: string): JSX.Element => {
		const defaultElement = element?.[fieldName];
		const attributes = tableSchema[fieldName] ?? {};
		const inputHeight = `calc(60vh - ${(descriptionHeight ?? 0) + 28}px)`;
		if (!attributes?.type) return <div />;
		const isArrayInput =
			Array.isArray(attributes.type) || attributes.type === 'Array';

		const refId = `verticalInput${fieldName}`;

		const fieldType = Table.getType(attributes.type);

		if (attributes.enum) {
			const options: TOption[] = attributes.translatable
				? Object.entries(attributes.enum[language]).map(([value, label]) => ({
						label,
						value,
				  }))
				: attributes.enum.map((value: string) => ({
						label: value,
						value,
				  }));

			return (
				<InputSelectionModal
					key={refId}
					id={`selection:${refId}`}
					defaultSelected={defaultElement}
					multiple={isArrayInput}
					options={{ _nogroup: options }}
					onSelection={(value) => handleInput(value, fieldName)}
				/>
			);
		}

		if (attributes.translatable) {
			return (
				<InputTranslation
					key={refId}
					style={{ maxHeight: inputHeight }}
					className='overflow-y-auto p-0.5'
					multiple={isArrayInput}
					defaultTranslate={defaultElement}
					setValue={(value) => handleInput(value, fieldName)}
				/>
			);
		}

		if (fieldType === 'TimeRange') {
			return (
				<TimeRangeInput
					key={refId}
					defaultRanges={defaultElement}
					setValue={(value) => handleInput(value, fieldName)}
				/>
			);
		}
		if (fieldType === 'Period') {
			return (
				<PeriodInput
					key={refId}
					isArray={isArrayInput}
					defaultPeriods={
						isEmpty(defaultElement) ? [] : elemToArray(defaultElement)
					}
					setValue={(value) => handleInput(value, fieldName)}
				/>
			);
		}

		if (fieldType === 'Quantity') {
			return (
				<MenuInput
					key={refId}
					multiple={isArrayInput}
					defaultValues={elemToArray(defaultElement ?? [])}
					setValue={(value) => handleInput(value, fieldName)}
					maxHeight={inputHeight}
				/>
			);
		}

		if (fieldType === 'ObjectId') {
			if (isEmpty(attributes.ref)) return <></>;
			return (
				<ObjectIdInput
					key={refId}
					retrieveElement={() => element}
					previousTableField={fieldName}
					setValue={(value) => handleInput(value, fieldName)}
					tableName={attributes.ref}
					multiple={isArrayInput}
					defaultValues={elemToArray(defaultElement ?? [])}
					initialFields={elemToArray(attributes.column?.value)}
					maxHeight={inputHeight}
				/>
			);
		}

		if (fieldType === 'Boolean') {
			return (
				<FlexAlign key={refId} className='w-full justify-center'>
					<Checkbox
						className='z-30 h-6 w-6'
						onClick={() => handleInput(!defaultElement, fieldName)}
						color='gray'
						checked={defaultElement}
					/>
				</FlexAlign>
			);
		}
		if (fieldType === 'File') {
			return (
				<InputFile
					tableName={tableName}
					key={refId}
					mimetypes={attributes.mimetypes}
					className='z-20'
					fieldName={fieldName}
					onDrop={(files) => handleInput(files[0], fieldName)}
				>
					<FlexAlign className='justify-center cursor-pointer min-w-100px min-h-8 text-xs text-primary font-semibold'>
						{defaultElement ? (
							<Media
								className='rounded mx-1 object-contain w-full'
								style={{ maxHeight: inputHeight }}
								file={defaultElement}
							/>
						) : (
							`${t('placeholder.upload')}`.toUpperCase()
						)}
					</FlexAlign>
				</InputFile>
			);
		}
		if (fieldType === 'Location') {
			return (
				<InputLocation
					key={refId}
					defaultValue={defaultElement}
					onChange={(value) => handleInput(value, fieldName)}
				/>
			);
		}
		if (fieldType === 'Cron') {
			return (
				<CronInput
					key={refId}
					defaultCron={defaultElement}
					onChange={(value) => handleInput(value, fieldName)}
				/>
			);
		}
		const inputType =
			fieldType === 'DateTime' ? 'datetime-local' : fieldType.toLowerCase();

		const defaultElements = elemToArray(defaultElement).map((elem) => {
			switch (inputType) {
				case 'datetime-local':
					return moment(elem).utcOffset(0).format('YYYY-MM-DDTHH:mm');
				case 'time':
					return moment(elem).utcOffset(0).format('HH:mm');
				case 'date':
					return moment(elem).utcOffset(0).format('YYYY-MM-DD');
				default:
					return elem;
			}
		});

		if (
			isArrayInput &&
			['String', 'Number', 'Array', 'Date', 'Time', 'DateTime'].includes(
				fieldType
			)
		)
			return (
				<InputArray
					key={refId}
					className='z-20 h-12 flex-nowrap'
					inputProps={{
						maxLength: attributes.maxLength,
						minLength: attributes.minLength,
						min: attributes.min,
						max: attributes.max,
						type: inputType,
						autoFocus: true,
					}}
					defaultValue={isEmpty(defaultElement) ? undefined : defaultElements}
					setValue={(arr) => handleInput(arr, fieldName)}
				/>
			);

		return (
			<InputText
				type={inputType}
				key={refId}
				autoFocus
				min={attributes.min}
				max={attributes.max}
				minLength={attributes.minLength}
				maxLength={attributes.maxLength}
				defaultValue={defaultElements[0]}
				onChange={(e) => {
					const value = parseFloat(e.currentTarget.value);
					if (attributes.max && value > attributes.max)
						e.currentTarget.value = attributes.max.toString();
					if (attributes.min && value < attributes.min)
						e.currentTarget.value = attributes.min.toString();
					handleInput(
						inputType === 'number'
							? parseFloat(e.currentTarget.value)
							: e.currentTarget.value,
						fieldName
					);
				}}
			/>
		);
	};

	useEffect(() => {
		if (selected) setInput(PrintInput(selected));
		// description + edit label height
	}, [selected, descriptionHeight]);

	useEffect(() => {
		if (schemaRef.current) schemaRef.current.scrollTo({ top: 0 });
	}, []);

	return (
		<FlexRow>
			<FlexCol
				ref={schemaRef}
				className={`flex-1 max-w-1/2 pr-3 max-h-${maxHeight} overflow-x-hidden border-r border-gray-dark overflow-y-auto`}
			>
				{Object.entries(tableSchema)
					.filter(
						([fieldName]) =>
							!Table.defaultFields.includes(fieldName) ||
							(Table.defaultFields.includes(fieldName) &&
								visibleDefaultFields?.includes(fieldName))
					)
					.map(([fieldName, fieldSchema], index) => (
						<FlexCol
							key={`vertical${tableName}${fieldName}${index}`}
							className={index !== 0 ? 'mt-3' : ''}
						>
							{fieldSchema && (
								<PrintColumn
									fieldName={fieldName}
									fieldSchema={fieldSchema}
									color='black'
									headerClick={() =>
										onEditColumn &&
										editableColumn(fieldName) &&
										onEditColumn(fieldName)
									}
								/>
							)}
							<FlexAlign
								className={`w-full mt-3 rounded-small cursor-pointer bg-gray px-3 min-h-12 ${
									selected === fieldName ? 'border border-primary' : ''
								}`}
								onClick={() => setSelected(fieldName)}
							>
								{PrintField(element[fieldName], fieldSchema, {
									column: fieldName,
								})}
							</FlexAlign>
						</FlexCol>
					))}
			</FlexCol>
			<FlexCol className='flex-1 max-w-1/2 pl-3'>
				{selected && (
					<>
						<FlexCol ref={descriptionRef}>
							<Label>{t('table.input.column_description')}</Label>
							<FlexCol className='w-full px-3 py-2 mb-3 border border-gray-dark'>
								<Text fontSize='xs' className='mb-2'>
									{t('attributes.type')}
								</Text>
								<Chip className='w-content mr-2'>{`${t(
									`attributes.type.${Table.getType(
										tableSchema[selected]?.type
									)}`
								)}`}</Chip>
								{Object.entries(tableSchema[selected]).filter(
									([attribute, value]) =>
										propertiesAttribute.includes(
											attribute as typeof propertiesAttribute[number]
										) && !!value
								).length > 0 && (
									<>
										<Text fontSize='xs' className='my-2'>
											{t('attributes.properties')}
										</Text>
										<FlexAlign>
											{Object.entries(tableSchema[selected])
												.filter(
													([attr, value]) =>
														propertiesAttribute.includes(
															attr as typeof propertiesAttribute[number]
														) && !!value
												)
												.map(([attribute, value], ind) => (
													<Chip
														key={`${attribute},${ind}`}
														className='w-content mr-2'
													>
														{(attribute as typeof propertiesAttribute[number]) ===
														'mimetypes'
															? value.join(', ')
															: t(`attributes.${attribute}`)}
													</Chip>
												))}
										</FlexAlign>
									</>
								)}
								{tableSchema[selected]?.description && (
									<Text fontSize='xs' className='my-2'>
										{tableSchema[selected].description[language]}
									</Text>
								)}
							</FlexCol>
						</FlexCol>
						<FlexCol>
							{(isCreate || !tableSchema[selected]?.immutable) &&
							!Table.defaultFields.includes(selected) ? (
								<>
									<Label>
										{`${t('placeholder.edit')} "${translateName(
											selected,
											tableSchema[selected].alias,
											t,
											language
										)}"`}
									</Label>
									{input}
								</>
							) : (
								<>
									<Label>{t('placeholder.value')}</Label>
									<FlexAlign className='w-full rounded-small cursor-pointer bg-gray px-3 min-h-8'>
										{PrintField(element[selected], tableSchema[selected], {
											column: selected,
										})}
									</FlexAlign>
								</>
							)}
						</FlexCol>
					</>
				)}
			</FlexCol>
		</FlexRow>
	);
};

export default VerticalInput;
