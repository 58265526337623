import { FC } from 'react';
import { Line } from 'components/basic/others';
import { TSelectable } from 'types/app';
import { FlexCol } from 'components/basic';

export const ListElement: FC<TSelectable & { color?: string }> = ({
	selected,
	color = 'black',
}) => (
	<div
		className='px-5 py-3 mb-1'
		style={{ backgroundColor: selected ? color : 'white' }}
	>
		<Line width='auto' color={selected ? 'white' : 'black-light'} />
	</div>
);

export const TableColumn: FC<{ color: string }> = ({ color }) => (
	<FlexCol className='border-r border-white'>
		<div className='p-3' style={{ backgroundColor: color }}>
			<Line width='8' color='white' />
		</div>
		<div className='h-8 bg-gray' />
		<div className='h-8 bg-gray-dark' />
		<div className='h-8 bg-gray' />
		<div className='h-8 bg-gray-dark' />
	</FlexCol>
);
