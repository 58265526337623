import axios from 'axios';
import { baseUrl } from 'config';
import {
	TElement,
	TField,
	TGroupedElements,
	TPopulate,
	TSchema,
	TStructure,
} from 'types/table';
import { TUser } from 'types/auth';
import Api from './api.service';
import { handleError, isEmpty, isNotEmpty, objectFilter } from 'utils';
import { TTableOptions } from 'types/app';

axios.defaults.baseURL = baseUrl;
axios.defaults.headers.common.Accept = 'application/json';

class Table {
	static defaultFields = [
		'__v', // TODO: Can be removed in future projects, versionKey has been disabled
		'_id',
		'createdAt',
		'updatedAt',
		'deletedAt',
		'createdBy',
	];

	static getType = (type: string | string[]): string =>
		Array.isArray(type) ? type[0] : type;

	static isGroupedElements = (tableOptions: TTableOptions) =>
		isNotEmpty(tableOptions.group?.value);

	static hasSubgroup = (tableOptions: TTableOptions) =>
		Table.isGroupedElements(tableOptions) &&
		isNotEmpty(tableOptions.group?.subgroup);

	static newElement = (createdBy: Partial<TUser>): Partial<TElement> => ({
		_id: '',
		createdBy,
		createdAt: new Date().toString(),
		updatedAt: new Date().toString(),
	});

	static recursivePopulate = (column: {
		value: string;
		schema: TField;
	}): TPopulate[] | undefined => {
		const fieldType = Table.getType(column?.schema?.type);
		if (!fieldType || fieldType !== 'ObjectId') return undefined;
		let newPopulate: TPopulate = {
			path: column.value,
		};
		newPopulate.select = `_id${
			column.schema?.column?.value ? ` ${column.schema.column.value}` : ''
		}`;
		newPopulate.populate = Table.recursivePopulate(column.schema.column);
		return [newPopulate];
	};

	static getTable = (tables: TStructure[], searched?: string) =>
		tables.find(({ tableName }) => tableName === searched);

	static getFilteredFields = (
		tableSchema: TSchema,
		type: 'required' | 'immutable'
	) =>
		Object.entries(tableSchema).reduce(
			(filtereds, [fieldName, fieldSchema]) =>
				fieldSchema[type] ? [...filtereds, fieldName] : filtereds,
			[] as string[]
		);

	static loadTableStructure = async (
		name: string
	): Promise<TStructure | undefined> => {
		try {
			let structure: TStructure;
			switch (name) {
				case '_users':
				case 'User':
					structure = (await Api.getUserTable()).data;
					break;
				case '_roles':
					// TODO: Implement Role Structure
					structure = {
						tableName: 'Roles',
						tableSchema: {
							_id: { type: 'String' },
							name: { type: 'String' },
							createdAt: { type: 'DateTime' },
							updatedAt: { type: 'DateTime' },
						},
					};
					break;
				default:
					structure = (await Api.getTable(name)).data;
			}
			return structure;
		} catch (error) {
			handleError(error);
		}
		return undefined;
	};

	static calcPopulate = (tableSchema: TSchema) =>
		Object.entries(tableSchema).reduce((populate, [fieldName, fieldSchema]) => {
			const fieldType = Table.getType(fieldSchema.type);
			if (fieldType !== 'ObjectId') return populate;
			const newPopulate = Table.recursivePopulate({
				value: fieldName,
				schema: fieldSchema,
			});
			if (!newPopulate) return populate;
			return [...populate, newPopulate[0]];
		}, [] as TPopulate[]);

	static loadTableElements = async (
		tableName: string,
		tableOptions?: TTableOptions,
		page = 1,
		limit = 1000
	): Promise<TElement[] | TGroupedElements> => {
		if (!tableName) return [];
		try {
			const listOptions: any = {
				options: { sort: { createdAt: -1 } },
				page,
				limit,
			};
			const table = await Table.loadTableStructure(tableName);
			if (!table) return [];
			listOptions.options.populate = Table.calcPopulate(table.tableSchema);
			if (tableOptions) {
				const { filter, sort, hide, deleted, group } = tableOptions;
				if (isNotEmpty(filter)) listOptions.filter = filter;
				if (isNotEmpty(sort)) listOptions.options.sort = sort;
				if (isNotEmpty(hide)) listOptions.projection = hide;
				if (isNotEmpty(deleted)) listOptions.deleted = deleted;
				if (isNotEmpty(group)) listOptions.groupby = group;
			}
			let elements = [];
			switch (tableName) {
				case '_users':
					elements = (await Api.listUsers(listOptions)).data?.users;
					break;
				case '_roles':
					elements = (await Api.listRoles(listOptions)).data?.roles;
					break;
				default:
					elements = (await Api.listElements(table.tableName, listOptions)).data
						?.elements;
			}

			return elements;
		} catch (error) {
			handleError(error);
			return [];
		}
	};

	static insertElement = async (
		tableName: string,
		newElement: Partial<TElement>
	): Promise<TElement | undefined> => {
		try {
			// if element without default fields is empty
			if (
				isEmpty(
					objectFilter(
						newElement ?? {},
						([field]) => !Table.defaultFields.includes(field)
					)
				)
			) {
				return;
			}
			if (tableName === '_users') {
				return (await Api.createUser(newElement ?? {})).data;
			} else
				return (
					await Api.insertElement(tableName, {
						element: newElement,
					})
				)?.data?.element;
		} catch (error) {
			handleError(error);
		}
	};

	static updateElement = async (
		{ tableName, tableSchema }: TStructure,
		elementId: string,
		update: any
	): Promise<TElement | undefined> => {
		try {
			if (isEmpty(update)) return;
			if (['_users', 'User'].includes(tableName))
				return (await Api.updateUser(elementId, update)).data;
			else {
				await Api.updateElement(tableName, elementId, update);
				return (
					await Api.findElement(tableName, {
						filter: { _id: elementId },
						options: { populate: Table.calcPopulate(tableSchema) },
					})
				).data;
			}
		} catch (error) {
			handleError(error);
		}
	};

	static getElementsArray = (
		elements: TElement[] | TGroupedElements,
		tableOptions: TTableOptions
	): TElement[] => {
		if (Table.isGroupedElements(tableOptions)) {
			if (Table.hasSubgroup(tableOptions)) {
				return Object.values(
					Object.values(elements as TGroupedElements).reduce(
						(acc, subgroup) => ({ ...acc, ...subgroup }),
						{}
					)
				).flat();
			} else return Object.values(elements as TGroupedElements).flat();
		} else return elements as TElement[];
	};
}

export default Table;
