import { FC } from 'react';
import { BackgroundElement } from '../basic/others';

export const Column: FC<{ margin?: number | string }> = ({
	children,
	margin = 0,
}) => (
	<div
		className={`flex-col h-screen w-1/3 max-w-sm min-w-300px mx-8 -my-${margin}`}
	>
		{children}
	</div>
);

export const FullColumn: FC<{ margin?: number | string }> = ({ margin }) => (
	<Column margin={margin}>
		<BackgroundElement />
		<BackgroundElement />
	</Column>
);
