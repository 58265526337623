import { FC, useState, useEffect } from 'react';
import { FlexAlign } from 'components/basic';
import { CommonModal } from 'components/basic/others';
import { TEditedCell, TElement, TStructure } from 'types/table';
import { isEmpty, language, objectFilter, translateName } from 'utils';
import { TDeleteOptions, TTableOperation } from 'types/app';
import { FilledButton, OutlinedButton } from 'components/basic/buttons';
import { selectorApp, setPreviewSession } from 'store/app/app.slice';
import { useSelector } from 'react-redux';
import Table from 'services/table.service';
import { useAppDispatch } from 'store';
import lodash from 'lodash';
import VerticalInput from 'components/hoc/vertical-input';

interface TPreviewModalProps {
	onHide: () => void;
	startOperation: (operationOpt: TTableOperation) => void;
}

const PreviewModal: FC<TPreviewModalProps> = ({ onHide, startOperation }) => {
	const dispatch = useAppDispatch();

	const { previewSession, t } = useSelector(selectorApp);

	const [isCreate, setIsCreate] = useState<boolean>(
		isEmpty(previewSession[0].element._id)
	);

	const [table, setTable] = useState<TStructure>(previewSession[0].table);
	const [element, setElement] = useState<TElement>(
		previewSession[previewSession.length - 1].element
	);

	useEffect(() => {
		if (previewSession.length > 0) {
			const lastInsertStep = previewSession[previewSession.length - 1];
			setTable(lastInsertStep.table);
			setIsCreate(isEmpty(lastInsertStep.element._id));
			setElement(lastInsertStep?.element);
		}
	}, [previewSession]);

	return (
		<CommonModal
			width='80vw'
			header={previewSession.map(
				({ table, element: { _id } }) =>
					`${t(`placeholder.${isEmpty(_id) ? 'add' : 'edit'}`)} ${t(
						'table.input.element_in_table'
					)} "${translateName(table.tableName, table.alias, t, language)}"`
			)}
			onHide={() =>
				previewSession.length === 1
					? onHide()
					: startOperation({
							table,
							operation: isEmpty(
								objectFilter(
									element,
									([field]) => !Table.defaultFields.includes(field)
								)
							)
								? 'closeInsert'
								: 'cancelInsert',
					  })
			}
		>
			<VerticalInput
				element={element}
				setElement={setElement}
				isCreate={isCreate}
				table={table}
			/>
			<FlexAlign className='justify-center mt-4'>
				{isCreate ? (
					<OutlinedButton
						className='max-w-1/4 mr-1'
						onClick={() =>
							previewSession?.length === 1
								? onHide()
								: startOperation({ operation: 'closeInsert', table })
						}
					>
						{`${t('placeholder.cancel')} ${t('table.operation.insert')}`}
					</OutlinedButton>
				) : (
					<OutlinedButton
						className='max-w-1/4 mr-1'
						onClick={() =>
							startOperation({
								table,
								operation: 'deleteRow',
								options: { deleteRows: [element._id] } as TDeleteOptions,
							})
						}
					>
						{`${t('placeholder.delete')} ${t('table.element')}`}
					</OutlinedButton>
				)}
				<FilledButton
					className='max-w-1/4 ml-1'
					onClick={async () => {
						const newSession = lodash.cloneDeep(previewSession);
						const immutableFields = [
							...Table.defaultFields,
							...Table.getFilteredFields(table.tableSchema, 'immutable'),
						];
						const currentFields = Object.keys(table.tableSchema);
						const update = objectFilter(
							element,
							([fieldName]) =>
								!immutableFields.includes(fieldName) &&
								currentFields.includes(fieldName)
						);

						if (previewSession.length === 1) {
							if (isCreate) {
								startOperation({
									table,
									operation: 'insertRow',
									options: { element },
								});
							} else {
								if (!element._id) return;
								startOperation({
									table,
									operation: 'editRow',
									options: {
										update,
										address: { row: element._id, tableName: table.tableName },
									} as TEditedCell,
								});
							}
							onHide();
						} else {
							let newElement: TElement | undefined;
							if (isCreate) {
								newElement = await Table.insertElement(
									table.tableName,
									element
								);
							} else {
								if (!element._id) return;
								newElement = await Table.updateElement(
									table,
									element._id,
									update
								);
							}
							if (!newElement) return;
							const index = previewSession.findIndex(
								({ table: { tableName } }) => tableName === table.tableName
							);
							if (index === -1) return;
							const previousInsertStep = newSession[index - 1];
							const previousElement = previousInsertStep.element;
							const previousField = newSession[index]
								.previousTableField as string;

							newSession[index - 1] = {
								...previousInsertStep,
								element: {
									...previousElement,
									[previousField]: Array.isArray(previousElement[previousField])
										? [...previousElement[previousField], newElement]
										: newElement,
								},
							};
							dispatch(
								setPreviewSession(newSession.slice(0, newSession.length - 1))
							);
						}
					}}
				>
					{`${t(`placeholder.${isCreate ? 'insert' : 'save'}`)} ${t(
						'table.element'
					)}`}
				</FilledButton>
			</FlexAlign>
		</CommonModal>
	);
};

export default PreviewModal;
