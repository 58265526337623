import { FC, useState, useEffect } from 'react';
import { CommonModal, Media } from 'components/basic/others';
import { TArrayModalOpt, TTableOperation } from 'types/app';
import { Cross } from 'components/basic/assets';
import { Text, FlexCol, FlexRow, FlexAlign } from 'components/basic';
import { InputArea, InputFile, InputText } from 'components/basic/inputs';
import { Checkbox, FilledButton, UploadButton } from 'components/basic/buttons';
import moment from 'moment';

import { TLocation } from 'types/table';
import { useSelector } from 'react-redux';
import { selectorApp, setInsertSession } from 'store/app/app.slice';
import { useAppDispatch } from 'store';

interface ArrayModalProps {
	onHide: () => void;
	opt: TArrayModalOpt;
	startOperation: (opt: TTableOperation) => void;
}

type FieldType =
	| 'Array'
	| 'File'
	| 'Boolean'
	| 'Number'
	| 'Date'
	| 'Decimal128'
	| 'Location';

const ArrayModal: FC<ArrayModalProps> = ({
	onHide,
	startOperation,
	opt: { table, arr, fieldType, address },
}) => {
	const dispatch = useAppDispatch();
	const [currentArray, setCurrentArray] = useState<any[]>([]);
	const [coord, setCoord] = useState<Partial<TLocation>>();
	const [importCoord, setImportCoord] = useState<boolean>(false);
	const { insertSession, t } = useSelector(selectorApp);

	useEffect(() => {
		setCurrentArray(Array.isArray(arr) ? arr : []);
	}, [arr]);
	if (!fieldType) return <></>;

	const importCoords = ({ currentTarget: { value } }: any) => {
		setCurrentArray(
			JSON.parse(value).map(([lng, lat]: [number, number]) => ({
				lat,
				lng,
			}))
		);
		setImportCoord(false);
	};

	const PrintField = (elem: any) => {
		switch (fieldType as FieldType) {
			case 'File':
				return <Media className='h-48 w-48 rounded' hasStats file={elem} />;
			case 'Date':
				return <>{moment(elem).format('YYYY-MM-DD')}</>;
			case 'Boolean':
				return <Checkbox checked={elem} />;
			case 'Location':
				return <FlexRow>{`lat:${elem.lat}, lng:${elem.lng}`}</FlexRow>;
			default:
				return <>{elem.toString()}</>;
		}
	};

	const printInput = () => {
		switch (fieldType as FieldType) {
			case 'File':
				return (
					<InputFile
						className='z-20 mt-7'
						multiple
						fieldName={address?.column}
						tableName={address?.tableName}
						children={
							<UploadButton
								className='h-48 w-48'
								text={`${t('placeholder.upload')} ${t('attributes.type.File')}`}
							/>
						}
						onDrop={(files) => setCurrentArray([...currentArray, ...files])}
					/>
				);
			case 'Location':
				return (
					<FlexAlign className='mt-3'>
						{importCoord ? (
							<InputArea
								className='min-h-18 min-w-250px'
								placeholder={`${t('modal.location.placeholder')}`}
								onBlur={importCoords}
							/>
						) : (
							<>
								<InputText
									className='mr-2'
									placeholder='lat'
									type='number'
									min={-90}
									max={90}
									onBlur={({ currentTarget: { value } }) => {
										if (coord?.lng) {
											setCurrentArray([
												...currentArray,
												{ lng: coord.lng, lat: value },
											]);
											setCoord(undefined);
										} else setCoord({ lat: parseFloat(value) });
									}}
								/>
								<InputText
									placeholder='lng'
									type='number'
									min={-180}
									max={180}
									onBlur={({ currentTarget: { value } }) => {
										if (coord?.lat) {
											setCurrentArray([
												...currentArray,
												{ lat: coord.lat, lng: value },
											]);
											setCoord(undefined);
										} else setCoord({ lng: parseFloat(value) });
									}}
								/>
							</>
						)}
						<UploadButton
							className={`ml-2 ${importCoord ? 'transform rotate-180' : ''}`}
							text={<></>}
							onClick={() => {
								setImportCoord(!importCoord);
								setCurrentArray([]);
							}}
						/>
					</FlexAlign>
				);
			default:
				return (
					<InputText
						type={fieldType?.toLowerCase()}
						className='max-w-300px my-4'
						onBlur={({ currentTarget: { value } }) => {
							if (value === '') return;
							setCurrentArray([...currentArray, value]);
							value = '';
						}}
					/>
				);
		}
	};

	return (
		<CommonModal
			className='overflow-auto'
			onHide={onHide}
			header={`${t(`modal.header.${fieldType}`)}`}
		>
			<FlexAlign className='justify-center flex-wrap'>
				{currentArray.map((elem, index) => (
					<FlexCol
						key={`elem${index}`}
						className={`${index !== elem.length - 1 ? 'mr-4' : ''}`}
					>
						<FlexAlign className='justify-between my-1 mx-2'>
							<Text>{index + 1}</Text>
							<Cross
								color='primary'
								className='cursor-pointer w-2 h-2'
								onClick={() =>
									setCurrentArray(
										currentArray.filter((_el, ind) => ind !== index)
									)
								}
							/>
						</FlexAlign>
						{PrintField(elem)}
					</FlexCol>
				))}
				{printInput()}
			</FlexAlign>
			<FlexAlign className='justify-center w-full my-3 w-'>
				<FilledButton
					onClick={() => {
						if (!table || !address) return;

						if (insertSession)
							dispatch(
								setInsertSession({
									...insertSession,
									element: {
										...insertSession.element,
										[address.column]: currentArray,
									},
								})
							);
						else
							startOperation({
								table,
								operation: 'editRow',
								options: {
									address,
									update: { [address.column]: currentArray },
								},
							});
						onHide();
					}}
					className='max-w-50px'
				>
					{t('placeholder.submit')}
				</FilledButton>
			</FlexAlign>
		</CommonModal>
	);
};

export default ArrayModal;
