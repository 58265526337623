import { FC, useState, useEffect } from 'react';
import {
	Checkbox,
	FilledButton,
	OutlinedButton,
} from 'components/basic/buttons';
import {
	InputCheckbox,
	InputFile,
	InputLocation,
	InputText,
} from 'components/basic/inputs';
import { CommonModal, Media } from 'components/basic/others';
import { TLanguage, TModalOperationOpt, TModalOpt, TOption } from 'types/app';
import { TField, TSelectionAlias, TStructure } from 'types/table';
import {
	isEmpty,
	handleError,
	language,
	locales,
	replaceObjectKey,
	isNotEmpty,
} from 'utils';
import {
	CheckboxWrapper,
	InputArrayModal,
	InputRow,
	InputSelectionModal,
} from './upsert-column.style';
import { Label, FlexRow, FlexAlign, Grid, FlexCol } from 'components/basic';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectorApp } from 'store/app/app.slice';
import { selectorAuth } from 'store/auth/auth.slice';
import { Flag } from 'components/basic/assets';
import { v4 as uuidv4 } from 'uuid';
import Table from 'services/table.service';
import InputTranslation from 'components/hoc/translation-input';
import ConfirmModal from '../confirm.modal';

interface TableModalProps extends TModalOperationOpt, TModalOpt {
	onSubmit: (
		newStructure: TStructure,
		renamedColumn?: { [newColumnName: string]: string }
	) => void;
}

const UpsertColumnModal: FC<TableModalProps> = ({
	onHide,
	operation,
	onSubmit,
	table,
	oldSchema,
}) => {
	const [columnAttributes, setColumnAttributes] = useState<TField>();
	const [attributes, setAttributes] = useState<string[]>([]);
	const [newColumnName, setNewColumnName] = useState<string>('');
	const [confirmModal, setConfirmModal] = useState<boolean>(false);
	const [currentType, setCurrentType] = useState<string>('');
	const { properties, t } = useSelector(selectorApp);
	const { tables } = useSelector(selectorAuth);

	useEffect(() => {
		if (oldSchema && Object.keys(oldSchema).length > 0) {
			const [fieldName, fieldSchema] = Object.entries(oldSchema)[0];
			setNewColumnName(fieldName);
			setColumnAttributes(fieldSchema);
		} else setColumnAttributes({ type: '' });
	}, [oldSchema, operation]);

	useEffect(() => {
		const {
			generalAttributes,
			stringAttributes,
			fileAttributes,
			objectIdAttributes,
			dateAttributes,
			numberAttributes,
		} = properties?.fieldAttributes ?? {};
		const type = Table.getType(columnAttributes?.type ?? '');
		let newAttributes: string[] | undefined;
		switch (type) {
			case 'String':
			case 'Menu':
				newAttributes = stringAttributes;
				break;
			case 'Number':
				newAttributes = numberAttributes;
				break;
			case 'Date':
			case 'DateTime':
				newAttributes = dateAttributes;
				break;
			case 'ObjectId':
				newAttributes = objectIdAttributes;
				break;
			case 'Quantity':
				newAttributes = [...(stringAttributes ?? []), 'max'];
				break;
			case 'File':
				newAttributes = fileAttributes;
				break;
			default:
				newAttributes = generalAttributes;
		}
		setAttributes(newAttributes ?? []);
		setCurrentType(type);
	}, [columnAttributes, properties]);

	if (!table || attributes.length === 0 || !columnAttributes) return <></>;

	const PrintDefaultValue = () => {
		if (columnAttributes.type === '') return <div />;
		const isArrayInput =
			Array.isArray(columnAttributes.type) || columnAttributes.type === 'Array';

		/* 	const position = document
			.getElementById('column:defaultValue')
			?.getBoundingClientRect() ?? { top: 0, left: 0, width: 0, height: 0 }; */

		if (isNotEmpty(columnAttributes.enum)) {
			const options: TOption[] = columnAttributes.translatable
				? Object.entries(columnAttributes.enum[language])?.map(
						([value, label]) => ({
							label,
							value,
						})
				  )
				: columnAttributes.enum.map((value: string) => ({
						label: value,
						value,
				  }));

			return (
				<InputSelectionModal
					id='column:defaultValue'
					defaultSelected={columnAttributes.default}
					multiple={isArrayInput}
					options={{ _nogroup: options }}
					onSelection={(value) =>
						setColumnAttributes({
							...columnAttributes,
							default: value,
						})
					}
				/>
			);
		}

		/* TODO: implement this modal default values
		if (currentType === 'TimeRange') {
			return (
				<TimeRangeModal
					position={position}
					defaultRanges={columnAttributes.default}
					onClose={(value) =>
						setColumnAttributes({ ...columnAttributes, default: value })
					}
				/>
			);
		}
		if (currentType === 'Period') {
			return (
				<PeriodModal
					position={position}
					isArray={isArrayInput}
					defaultPeriods={
						isEmpty(columnAttributes.default)
							? []
							: elemToArray(columnAttributes.default)
					}
					onClose={(value) =>
						setColumnAttributes({ ...columnAttributes, default: value })
					}
				/>
			);
		} 
		
		if (currentType === 'ObjectId') {
			if (!loadTableElements || isEmpty(columnAttributes.ref))
				return <></>;
			return (
				<ObjectIdModal
					previousTableField={}//column name
					position={position}
					onClose={(value) =>
						setColumnAttributes({ ...columnAttributes, default: value })
					}
					tableName={columnAttributes.ref}
					multiple={isArrayInput}
					loadTableElements={loadTableElements}
					defaultValues={elemToArray(columnAttributes.default ?? []).map(
						(elem) => elem._id ?? elem
					)}
					initialFields={elemToArray(columnAttributes.column?.value)}
				/>
			);
		} */

		if (currentType === 'Boolean') {
			return (
				<Checkbox
					className='h-8 w-8'
					onClick={() =>
						setColumnAttributes({
							...columnAttributes,
							default: !columnAttributes.default,
						})
					}
					color='gray'
					checked={columnAttributes.default}
				/>
			);
		}
		if (currentType === 'File') {
			return (
				<InputFile
					fieldName={newColumnName}
					tableName={table.tableName}
					mimetypes={columnAttributes.mimetypes}
					onDrop={(files) =>
						setColumnAttributes({
							...columnAttributes,
							default: isArrayInput ? files : files[0],
						})
					}
				>
					<FlexAlign className='rounded justify-center cursor-pointer min-w-100px h-8 text-xs text-primary font-semibold'>
						{columnAttributes.default ? (
							<Media
								className='rounded mx-1 object-contain h-35px w-35px min-w-35px '
								file={columnAttributes.default}
							/>
						) : (
							`${t('placeholder.upload')}`.toUpperCase()
						)}
					</FlexAlign>
				</InputFile>
			);
		}
		if (currentType === 'Location') {
			return (
				<InputLocation
					defaultValue={columnAttributes.default}
					onChange={(value) =>
						setColumnAttributes({ ...columnAttributes, default: value })
					}
				/>
			);
		}
		const inputType =
			currentType === 'DateTime' ? 'datetime-local' : currentType.toLowerCase();

		if (
			isArrayInput &&
			['String', 'Number', 'Array', 'Date', 'Time', 'DateTime'].includes(
				currentType
			)
		)
			return (
				<InputArrayModal
					inputProps={{
						maxLength: columnAttributes.maxLength,
						minLength: columnAttributes.minLength,
						min: columnAttributes.min,
						max: columnAttributes.max,
						type: inputType,
					}}
					defaultValue={columnAttributes.default}
					setValue={(value) =>
						setColumnAttributes({ ...columnAttributes, default: value })
					}
				/>
			);
		return (
			<InputText
				type={inputType}
				min={columnAttributes.min}
				max={columnAttributes.max}
				minLength={columnAttributes.minLength}
				maxLength={columnAttributes.maxLength}
				defaultValue={columnAttributes.default}
				onChange={(e) => {
					const value = parseFloat(e.currentTarget.value);
					if (columnAttributes.max && value > columnAttributes.max)
						e.currentTarget.value = columnAttributes.max.toString();
					if (columnAttributes.min && value < columnAttributes.min)
						e.currentTarget.value = columnAttributes.min.toString();
					setColumnAttributes({
						...columnAttributes,
						default: e.currentTarget.value,
					});
				}}
			/>
		);
	};
	const selectionsOptions = (attr: string) => {
		switch (attr) {
			case 'type':
				return (
					properties?.fieldTypes.map((type) => ({
						label: `${t(`attributes.type.${type}`)}`,
						value: type,
					})) ?? []
				);
			case 'ref':
				return tables
					.filter(({ tableName }) => tableName !== table.tableName)
					.map(({ tableName, alias }) => ({
						label: alias?.[language] ?? tableName,
						value: tableName,
					}));
			case 'mimetypes':
				return (
					properties?.supportedMimetypes.map((mime) => ({
						label: mime,
						value: mime,
					})) ?? []
				);
			case 'column':
				if (!columnAttributes?.ref) return [];
				const referredTable = tables.filter(
					({ tableName }) => tableName === columnAttributes.ref
				)?.[0];
				if (!referredTable) return [];
				return columnAttributes.ref
					? Object.entries(referredTable.tableSchema).map(
							([field, { alias, ...schema }]) => ({
								value: { value: field, schema },
								label: alias ?? field,
							})
					  )
					: [];
			default:
				return [];
		}
	};

	const attributeInput = (attr: string): JSX.Element => {
		const tAttr = t(`attributes.${attr}`);
		//fallthrough switch
		switch (attr) {
			case 'type':
			case 'mimetypes':
			case 'ref':
				const options: TOption[] = selectionsOptions(attr);
				return (
					<>
						<Label>{tAttr}</Label>
						<InputSelectionModal
							id={`upsertcolumn${attr}`}
							multiple={attr === 'mimetypes'}
							onSelection={(opt) => {
								if (attr === 'type') {
									setColumnAttributes({ type: opt });
								} else {
									const attrValue = {
										[attr]: opt,
									};
									if (attr === 'ref') {
										attrValue.column = {
											value: '_id',
											schema: table.tableSchema._id,
										};
									}
									setColumnAttributes({
										...columnAttributes,
										...attrValue,
									});
								}
							}}
							defaultSelected={
								attr !== 'mimetypes' && Array.isArray(columnAttributes[attr])
									? columnAttributes[attr][0]
									: columnAttributes[attr]
							}
							options={{ _nogroup: options }}
						/>
					</>
				);
			case 'immutable':
			case 'trim':
			case 'unique':
			case 'required':
			case 'lowercase':
			case 'uppercase':
			case 'translatable':
				return (
					<InputCheckbox
						checked={columnAttributes[attr]}
						text={tAttr}
						circle={['uppercase', 'lowercase'].includes(attr)}
						setValue={(value) => {
							const attrValue: any = {};
							attrValue[attr] = value;
							if (attr === 'lowercase' && attrValue[attr])
								attrValue.uppercase = false;
							if (attr === 'uppercase' && attrValue[attr])
								attrValue.lowercase = false;
							if (attr === 'translatable' && columnAttributes.enum) {
								if (value) {
									if (
										Array.isArray(columnAttributes.enum) &&
										columnAttributes.enum.length > 0
									) {
										attrValue.enum = (columnAttributes.enum as string[]).reduce(
											(prev, next) => {
												const id = uuidv4();
												return {
													[language]: { ...prev[language], [id]: next },
												};
											},
											{ [language]: {} }
										);
									}
								} else {
									if (
										typeof columnAttributes.enum === 'object' &&
										Object.keys(columnAttributes.enum).length > 0
									)
										attrValue.enum = Object.values(
											columnAttributes.enum[language]
										);
								}
							}
							setColumnAttributes({ ...columnAttributes, ...attrValue });
						}}
					/>
				);
			case 'min':
			case 'max':
			case 'minLength':
			case 'maxLength':
			case 'maxWidth':
			case 'maxHeight':
				return (
					<>
						<Label>{tAttr}</Label>
						<InputText
							type={currentType === 'Date' ? 'date' : 'number'}
							defaultValue={columnAttributes[attr]}
							min={
								['minLength', 'maxLength', 'maxWidth', 'maxHeight'].includes(
									attr
								)
									? 0
									: undefined
							}
							onBlur={({ currentTarget: { value } }) => {
								if (columnAttributes[attr] === value || isNaN(parseInt(value)))
									return;
								setColumnAttributes({
									...columnAttributes,
									[attr]: parseInt(value),
								});
							}}
						/>
					</>
				);
			case 'enum':
				return (
					<>
						<Label>{tAttr}</Label>
						{columnAttributes?.translatable ? (
							<>
								<FlexAlign className='justify-between bg-gray rounded'>
									<InputArrayModal
										defaultValue={Object.values(
											columnAttributes.enum?.[language] ?? {}
										)}
										setValue={(array) => {
											if (array) {
												const newEnums =
													array.length > 0
														? {
																[language]: array.reduce((enumObj, value) => {
																	const id = uuidv4();
																	enumObj = {
																		...enumObj,
																		[id]: value,
																	};
																	return enumObj;
																}, {} as TSelectionAlias),
														  }
														: {};
												setColumnAttributes({
													...columnAttributes,
													[attr]: {
														...columnAttributes[attr],
														...newEnums,
													},
												});
											} else {
												setColumnAttributes({
													...columnAttributes,
													[attr]: undefined,
												});
											}
										}}
									/>
									<Flag
										lang={language}
										className='mx-2'
										style={{ minWidth: 19 }}
									/>
								</FlexAlign>
								{columnAttributes.enum &&
									isNotEmpty(
										columnAttributes.enum[Object.keys(columnAttributes.enum)[0]]
									) && (
										<FlexCol className='max-h-200px overflow-auto border-2 p-3 rounded border-gray-dark mt-3'>
											{Object.entries(columnAttributes.enum[language]).map(
												([id, label], index: number) => (
													<FlexCol className='mb-2' key={`translation${index}`}>
														<Label>{label as string}</Label>
														{Object.keys(locales)
															.filter((lang) => lang !== language)
															.map((lang, ind) => (
																<FlexAlign
																	className='mb-1 pr-2 bg-gray rounded'
																	key={`translation${index}${ind}`}
																>
																	<input
																		onBlur={({ currentTarget: { value } }) =>
																			value?.replace(' ', '') !== '' &&
																			setColumnAttributes({
																				...columnAttributes,
																				[attr]: {
																					...columnAttributes[attr],
																					[lang]: {
																						...columnAttributes[attr][lang],
																						[id]: value,
																					},
																				},
																			})
																		}
																		defaultValue={
																			columnAttributes[attr]?.[lang]?.[id]
																		}
																		className='rounded py-1 px-2 mr-2 bg-transparent focus:outline-none focus:ring-2 focus:ring-primary w-full'
																	/>
																	<Flag
																		style={{ minWidth: 19 }}
																		lang={lang as TLanguage}
																	/>
																</FlexAlign>
															))}
													</FlexCol>
												)
											)}
										</FlexCol>
									)}
							</>
						) : (
							<InputArrayModal
								inputProps={{
									type: Table.getType(columnAttributes.type).toLowerCase(),
								}}
								defaultValue={columnAttributes[attr]}
								setValue={(array) => {
									if (columnAttributes[attr] === array) return;
									setColumnAttributes({ ...columnAttributes, [attr]: array });
								}}
							/>
						)}
					</>
				);
			case 'column':
				return columnAttributes.ref ? (
					<>
						<Label>{tAttr}</Label>
						<InputSelectionModal
							id={`upsertcolumn${attr}`}
							defaultSelected={columnAttributes.column}
							onSelection={(value) =>
								setColumnAttributes({ ...columnAttributes, [attr]: value })
							}
							options={{ _nogroup: selectionsOptions('column') }}
						/>
					</>
				) : (
					<></>
				);
			case 'default':
				return <>{PrintDefaultValue()}</>;
			case 'description':
				return (
					<>
						<Label>{tAttr}</Label>
						<InputTranslation
							defaultTranslate={columnAttributes.description}
							setValue={(value) =>
								setColumnAttributes({ ...columnAttributes, [attr]: value })
							}
						/>
					</>
				);
			default:
				return (
					<>
						<Label>{tAttr}</Label>
						<InputText
							placeholder={attr === 'match' ? 'RegExp' : ''}
							defaultValue={columnAttributes[attr]}
							onBlur={({ currentTarget: { value } }) => {
								let newValue = value.trim();
								if (attr === 'match') {
									let temp = value.split('');
									if (temp[0] === '/') temp = temp.slice(1, temp.length);
									if (temp[temp.length - 1] === '/')
										temp = temp.slice(0, temp.length - 1);
									newValue = temp.join('');
									try {
										new RegExp(newValue);
									} catch (error) {
										handleError(error);
										return;
									}
								}
								setColumnAttributes({ ...columnAttributes, [attr]: newValue });
							}}
						/>
					</>
				);
		}
	};

	const maxSizeChanged = (): boolean => {
		if (operation === 'addColumn') return false;
		const { maxWidth: oldWidth, maxHeight: oldHeight } = Object.values(
			oldSchema ?? {}
		)[0];
		const { maxWidth: newWidth, maxHeight: newHeight } = columnAttributes;
		if (oldWidth === newWidth && oldHeight === newHeight) return false;
		if ((newWidth && !oldWidth) || (newHeight && !oldHeight)) return true;
		if (newWidth < oldWidth || newHeight < oldHeight) return true;
		return false;
	};

	const close = () => {
		setColumnAttributes({ type: '' });
		setNewColumnName('');
		setCurrentType('');
		onHide();
	};

	const submitColumn = () => {
		if (!newColumnName || newColumnName.replace(' ', '') === '')
			return toast.warning(`${t('toast.no_column_name')}`);
		if (Table.getType(columnAttributes.type) === '')
			return toast.warning(`${t('toast.no_column_type')}`);
		const schemaAttributes = columnAttributes;
		for (const attr of Object.keys(schemaAttributes)) {
			if (isEmpty(schemaAttributes[attr])) {
				delete schemaAttributes[attr];
			}
		}
		let newTableSchema = { ...table.tableSchema };

		if (operation === 'addColumn') {
			newTableSchema[newColumnName] = schemaAttributes;
			onSubmit({
				tableName: table?.tableName,
				tableSchema: newTableSchema,
			});
		} else {
			if (!oldSchema) return close();
			const oldColumnName = Object.keys(oldSchema)[0];
			newTableSchema = replaceObjectKey(
				newTableSchema,
				oldColumnName,
				newColumnName,
				schemaAttributes
			);
			onSubmit(
				{
					tableName: table?.tableName,
					tableSchema: newTableSchema,
				},
				oldColumnName && oldColumnName !== newColumnName
					? { [oldColumnName]: newColumnName }
					: undefined
			);
		}
		close();
	};

	const deleteColumn = () => {
		if (!oldSchema) return;
		const deletedColumn = Object.keys(oldSchema)[0];
		if (!deletedColumn) return;
		delete table.tableSchema[deletedColumn];
		onSubmit({
			tableName: table?.tableName,
			tableSchema: table.tableSchema,
		});
		close();
	};

	return (
		<CommonModal
			className='max-h-90vh overflow-y-auto'
			onHide={close}
			header={`${t(`table.operation.${operation}`)}`}
		>
			<Grid className='grid-cols-1 gap-4 md:grid-cols-2'>
				<InputRow>
					<Label>{t('attributes.name')}</Label>
					<InputText
						autoFocus
						defaultValue={oldSchema ? Object.keys(oldSchema)[0] : ''}
						required
						onChange={({ currentTarget: { value } }) => setNewColumnName(value)}
					/>
				</InputRow>
				<InputRow>{attributeInput('type')}</InputRow>
				{columnAttributes.type !== '' && (
					<>
						<InputRow>
							<Label>{t('attributes.properties')}</Label>
							<FlexAlign className='flex-wrap h-35px -mt-2'>
								<CheckboxWrapper>
									<InputCheckbox
										checked={Array.isArray(columnAttributes.type)}
										text={t('attributes.array')}
										setValue={() =>
											Array.isArray(columnAttributes.type)
												? setColumnAttributes({
														...columnAttributes,
														type: columnAttributes.type[0],
												  })
												: setColumnAttributes({
														...columnAttributes,
														type: [columnAttributes.type],
												  })
										}
									/>
								</CheckboxWrapper>
								<CheckboxWrapper>{attributeInput('required')}</CheckboxWrapper>
								<CheckboxWrapper>{attributeInput('immutable')}</CheckboxWrapper>
								<CheckboxWrapper>{attributeInput('unique')}</CheckboxWrapper>
								{['String', 'Number'].includes(currentType) && (
									<CheckboxWrapper>
										{attributeInput('translatable')}
									</CheckboxWrapper>
								)}
							</FlexAlign>
						</InputRow>
						<InputRow>
							<Label id='column:defaultValue'>{t('attributes.default')}</Label>
							{attributeInput('default')}
						</InputRow>
						<InputRow>{attributeInput('alias')}</InputRow>
						{currentType === 'String' && (
							<InputRow>
								<Label>{t('attributes.format')}</Label>
								<FlexAlign className='flex-wrap min-h-35px'>
									<div className='mr-2'>{attributeInput('lowercase')}</div>
									<div className='mr-2'>{attributeInput('uppercase')}</div>
									<div>{attributeInput('trim')}</div>
								</FlexAlign>
							</InputRow>
						)}
						<InputRow>{attributeInput('description')}</InputRow>
						{attributes
							.filter(
								(attr) =>
									![
										'lowercase',
										'uppercase',
										'trim',
										'translatable',
										...(properties?.fieldAttributes?.generalAttributes ?? []),
									].includes(attr)
							)
							.map((attr, index) => (
								<InputRow key={`input${attr}${index}`}>
									{attributeInput(attr)}
								</InputRow>
							))}
					</>
				)}
			</Grid>
			<FlexRow className='justify-center w-full mt-5'>
				{operation === 'editColumn' && (
					<OutlinedButton
						className='mr-1 w-1/2 max-w-500px'
						onClick={deleteColumn}
					>
						{t(`table.operation.deleteColumn`)}
					</OutlinedButton>
				)}
				<FilledButton
					className='ml-1 w-1/2 max-w-500px'
					onClick={() => {
						console.log(maxSizeChanged());
						if (
							operation === 'editColumn' &&
							Table.getType(columnAttributes.type) === 'File' &&
							maxSizeChanged()
						)
							setConfirmModal(true);
						else submitColumn();
					}}
				>
					{t(`table.operation.${operation}`)}
				</FilledButton>
			</FlexRow>
			{confirmModal && (
				<ConfirmModal
					header={t(`table.operation.${operation}`)}
					explanation={t('modal.confirm_column')}
					onConfirm={submitColumn}
					onHide={() => setConfirmModal(false)}
				/>
			)}
		</CommonModal>
	);
};

export default UpsertColumnModal;
