import { FC, useState, useEffect } from 'react';
import { CommonModal, Media } from 'components/basic/others';
import { FlexAlign, FlexCol } from 'components/basic';
import { TFileModalOpt, TModalOpt, TTableOperation } from 'types/app';
import { FilledButton, OutlinedButton } from 'components/basic/buttons';

import { InputFile } from 'components/basic/inputs';
import { TEditedCell, TFile } from 'types/table';
import { selectorApp } from 'store/app/app.slice';
import { useSelector } from 'react-redux';

interface FileModalProps extends TModalOpt {
	opt: TFileModalOpt;
	startOperation: (opt: TTableOperation) => void;
}

const FileModal: FC<FileModalProps> = ({
	onHide,
	startOperation,
	opt: { table, elem, address },
}) => {
	const { t } = useSelector(selectorApp);
	const [currentFile, setCurrentFile] = useState<TFile | undefined>(elem);

	useEffect(() => {
		setCurrentFile(elem);
	}, [elem]);

	if (!currentFile) return <></>;
	return (
		<CommonModal
			className='overflow-auto'
			onHide={onHide}
			header={currentFile.url?.split('/').pop() ?? 'File'}
		>
			<FlexCol className='items-center'>
				<Media file={currentFile} hasStats />

				<FlexAlign className='justify-center w-full my-3'>
					<InputFile
						fieldName={address.column}
						tableName={address.tableName}
						className='z-20'
						onDrop={(files) => setCurrentFile(files[0])}
					>
						<OutlinedButton>{t('placeholder.reupload')}</OutlinedButton>
					</InputFile>
					<FilledButton
						onClick={() => {
							table &&
								startOperation({
									operation: 'editRow',
									table: table,
									options: {
										address,
										update: { [address?.column]: currentFile },
									} as TEditedCell,
								});
							onHide();
						}}
						className='max-w-50px ml-2'
					>
						{t('placeholder.submit')}
					</FilledButton>
				</FlexAlign>
			</FlexCol>
		</CommonModal>
	);
};

export default FileModal;
