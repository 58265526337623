import { FlexAlign, FlexCol, FlexRow, Text } from 'components/basic';
import { Eye, Hide } from 'components/basic/assets';
import { PrintField } from 'components/basic/print';
import { FC, useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import Table from 'services/table.service';
import { selectorApp } from 'store/app/app.slice';
import { TElement, TSchema, TStructure } from 'types/table';
import { isNotEmpty, language, translateName } from 'utils';

interface ObjectProps {
	table: TStructure;
	initialFields: string[];
	element: TElement;
	index: number;
	selected?: boolean;
	onElemClick: () => void;
	className?: string;
}

const ObjectId: FC<ObjectProps> = ({
	table: { tableName, tableSchema },
	initialFields,
	element,
	index,
	selected,
	onElemClick,
	className = '',
}) => {
	const { t } = useSelector(selectorApp);
	const [visibleColumns, setVisibleColumns] = useState<TSchema>({});
	const setInitialSchema = () => {
		const initialSchema: TSchema = {};
		if (initialFields.length > 0 && isNotEmpty(initialFields))
			initialFields.forEach(
				(field) => (initialSchema[field] = tableSchema[field])
			);
		for (const field of Object.keys(tableSchema)) {
			if (!Table.defaultFields.includes(field)) {
				initialSchema[field] = tableSchema[field];
			}
			if (Object.keys(initialSchema).length >= 2) break;
		}
		if (Object.keys(initialSchema).length < 2)
			initialSchema['createdAt'] = tableSchema['createdAt'];
		setVisibleColumns(initialSchema);
	};

	const toggleVisible = () => {
		if (Object.keys(visibleColumns).length > 2) {
			setInitialSchema();
		} else setVisibleColumns(tableSchema);
	};

	useEffect(() => {
		setInitialSchema();
	}, []);

	return (
		<FlexRow
			onClick={onElemClick}
			className={`w-full justify-between rounded cursor-pointer ${
				selected ? 'border-primary border-2' : 'border-gray-dark border'
			} hover:border-primary ${className}`}
		>
			<FlexRow className='overflow-x-auto w-full'>
				{Object.keys(visibleColumns).map((field, ind) => (
					<FlexCol
						className={`min-w-content flex-1 pr-6 my-2 pl-3 ${
							ind !== Object.keys(visibleColumns).length - 1
								? 'border-r border-gray-dark'
								: ''
						}`}
						key={`modal:${tableName}${field}${index}:${ind}`}
					>
						<Text
							fontSize='xs'
							fontWeight='semibold'
							color='primary'
							className='mb-1'
						>
							{translateName(field, tableSchema[field]?.alias, t, language)}
						</Text>
						<Text
							fontSize='xs'
							fontWeight='semibold'
							className='flex flex-col justify-center h-35px'
						>
							{PrintField(element[field], tableSchema[field], {
								column: field,
								row: element._id ?? '',
								tableName,
							})}
						</Text>
					</FlexCol>
				))}
			</FlexRow>
			<FlexAlign
				className='justify-center bg-primary px-2 rounded-r ring-1 ring-primary'
				onClick={(e) => {
					e.stopPropagation();
					toggleVisible();
				}}
			>
				{Object.keys(tableSchema).length ===
				Object.keys(visibleColumns).length ? (
					<Hide color='white' />
				) : (
					<Eye />
				)}
			</FlexAlign>
		</FlexRow>
	);
};

export default ObjectId;
