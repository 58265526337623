import { FC, Dispatch, SetStateAction } from 'react';
import { FlexAlign, FlexCol, FlexRow, Label, Text } from 'components/basic';
import {
	InputAlias,
	InputColor,
	InputFile,
	InputText,
	InputToggle,
} from 'components/basic/inputs';
import { armoniaColors, toggleElementInArray } from 'utils';
import { TBrand } from 'types/app';
import { UploadButton } from 'components/basic/buttons';
import { useSelector } from 'react-redux';
import { selectorApp, setSchemaSettings } from 'store/app/app.slice';
import { Media } from 'components/basic/others';

import { Upload } from 'components/basic/assets';
import { useAppDispatch } from 'store';
import Table from 'services/table.service';

interface TFormProps {
	setBrand: (value: TBrand) => void;
	brand: TBrand;
	visibleFields: string[];
	setVisibleFields: Dispatch<SetStateAction<string[]>>;
}

const SettingsForm: FC<TFormProps> = ({
	brand,
	setBrand,
	visibleFields,
	setVisibleFields,
}) => {
	const dispatch = useAppDispatch();
	const { properties, schemaSettings, t } = useSelector(selectorApp);
	const imageMimetypes = properties?.supportedMimetypes?.filter((mime) =>
		mime.startsWith('image')
	);

	const InputLogo: FC<{ logo: 'landingLogo' | 'headerLogo' | 'favicon' }> = ({
		logo,
	}) => {
		return (
			<FlexCol>
				<Label>{t(`input.logo.${logo}`)}</Label>
				<InputFile
					mimetypes={imageMimetypes ?? []}
					onDrop={(files) => {
						setBrand({ ...brand, [logo]: files[0] });
					}}
				>
					{brand[logo].url.startsWith('%PUBLIC_URL%') ? (
						<UploadButton className='w-16 h-16 min-w-12' text='' />
					) : (
						<div className='relative'>
							<Media
								className='w-16 h-16 min-w-12 rounded'
								file={brand[logo]}
							/>
							<FlexAlign className='absolute top-0 justify-center w-16 h-16 rounded bg-white opacity-0 hover:opacity-75'>
								<Upload className='opacity-25' />
							</FlexAlign>
						</div>
					)}
				</InputFile>
			</FlexCol>
		);
	};

	return (
		<FlexCol className='overflow-x-auto px-1'>
			<Label>{t('attributes.name')}</Label>
			<InputText
				defaultValue={brand.name}
				onBlur={(e) => setBrand({ ...brand, name: e.currentTarget.value })}
			/>
			<Label className='mt-3'>{t('settings.welcome_message')}</Label>
			<InputAlias
				id='settingswelcome'
				defaultValue={brand.welcome}
				onChange={(welcome) => setBrand({ ...brand, welcome })}
			/>
			<Label className='mt-3'>{t('settings.show_defaults')}</Label>
			<FlexAlign className='flex-wrap'>
				{Table.defaultFields
					.filter((field) => field !== '__v')
					.map((field) => (
						<FlexCol className='flex-1 mr-2'>
							<Text fontSize='xs' className='mb-1 whitespace-nowrap'>
								{t(`table.column.${field}`)}
							</Text>
							<InputToggle
								checked={visibleFields.includes(field)}
								setValue={() =>
									setVisibleFields((fields) =>
										toggleElementInArray(field, fields)
									)
								}
							/>
						</FlexCol>
					))}
			</FlexAlign>
			<Label className='mt-3 -mb-1'>{t('settings.main_color')}</Label>
			<FlexAlign>
				{armoniaColors.map(({ r, g, b }, index) => (
					<div
						key={`color:${index}`}
						className='p-1 mr-3 hover:bg-primary-15'
						onClick={() => setBrand({ ...brand, color: { r, g, b } })}
					>
						<div
							className='rounded w-4 h-4 min-w-4 cursor-pointer'
							style={{ backgroundColor: `rgb(${r},${g},${b})` }}
						/>
					</div>
				))}
				<InputColor
					id='settingscolor'
					defaultValue={brand.color}
					onChange={(color) => setBrand({ ...brand, color })}
				/>
			</FlexAlign>
			<Label className='mt-3'>{t('settings.logo')}</Label>
			<FlexCol className='border border-gray-dark py-4 px-8 items-center'>
				<FlexRow className='w-full justify-around mb-4'>
					<InputLogo logo='landingLogo' />
					<InputLogo logo='headerLogo' />
					<InputLogo logo='favicon' />
				</FlexRow>
				<Text fontWeight='semibold' className='opacity-25'>
					{t('settings.drag')}
				</Text>
			</FlexCol>
			{/* TODO: Remove this toggle when implementing frontend permissions */}
			<Label className='mt-3'>Mostra impostazioni di schema</Label>
			<InputToggle
				checked={schemaSettings}
				setValue={(value) => dispatch(setSchemaSettings(value))}
			/>
		</FlexCol>
	);
};

export default SettingsForm;
