import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { applyBrand, defaultTheme } from 'themes/brand';
import {
	TBrand,
	TInsertSession,
	TPreviewSession,
	TTranslateMessage,
} from 'types/app';
import { TProperties } from 'types/table';

interface AppState {
	brand: TBrand;
	insertSession?: TInsertSession;
	previewSession: TPreviewSession;
	properties?: TProperties;
	visibleDefaultFields: string[];
	schemaSettings?: boolean;
	t: TTranslateMessage;
}

const initialState: AppState = {
	brand: defaultTheme,
	previewSession: [],
	visibleDefaultFields: [],
	insertSession: undefined,
	properties: undefined,
	schemaSettings: false,
	t: () => 'translation',
};

const app = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setBrand(state, action: PayloadAction<Partial<TBrand>>) {
			const newBrand = action.payload ?? {};
			state.brand = {
				...defaultTheme,
				...newBrand,
			};
			applyBrand(state.brand);
		},
		setInsertSession(
			state,
			{ payload }: PayloadAction<TInsertSession | undefined>
		) {
			state.insertSession = payload;
		},
		setPreviewSession(state, { payload }: PayloadAction<TPreviewSession>) {
			state.previewSession = payload;
		},
		setProperties(state, { payload }: PayloadAction<TProperties>) {
			state.properties = payload;
		},
		setVisibleDefaultFields(state, { payload }: PayloadAction<string[]>) {
			state.visibleDefaultFields = payload;
		},
		setSchemaSettings(state, { payload }: PayloadAction<boolean>) {
			state.schemaSettings = payload;
		},
		setTranslateFunction(state, { payload }: PayloadAction<TTranslateMessage>) {
			state.t = payload;
		},
		clearApp(state) {
			for (const [key, value] of Object.entries(initialState)) {
				state[key as keyof typeof initialState] = value;
			}
		},
	},
});

export const selectorApp = (state: { app: AppState }) => state.app;
export const {
	setBrand,
	setInsertSession,
	setPreviewSession,
	setProperties,
	setVisibleDefaultFields,
	setSchemaSettings,
	setTranslateFunction,
	clearApp,
} = app.actions;
export default app.reducer;
