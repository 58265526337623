import { FC, useEffect, useState } from 'react';
import { PlusView } from 'components/basic/assets';
import { ListWrapper } from 'components/basic/others';
import ListElement from './list-element';
import { ClickableScreen, Menu } from 'components/basic/others';

import { FlexAlign, FlexCol, Label } from 'components/basic';
import { TTableOperation } from 'types/app';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectorAuth } from 'store/auth/auth.slice';
import { language, translateName } from 'utils';
import { selectorApp } from 'store/app/app.slice';

interface TablesListProps {
	startOperation: (opt: TTableOperation) => void;
}

const TablesList: FC<TablesListProps> = ({ startOperation }) => {
	const history = useHistory();
	const { pathname } = useLocation();

	const [visible, setVisible] = useState<number>();
	const [tableName, setTableName] = useState<string>();
	const { tables } = useSelector(selectorAuth);
	const { schemaSettings, t } = useSelector(selectorApp);

	useEffect(() => {
		setTableName(pathname.split('/').pop());
	}, [pathname]);

	return (
		<ListWrapper>
			{/* Visible can be 0 too */}
			{visible !== undefined && (
				<ClickableScreen cb={() => setVisible(undefined)} />
			)}
			<FlexAlign className='justify-between px-4 my-2'>
				<Label className='-mb-1'>
					{t('table.entity')?.toString().toUpperCase()}
				</Label>
				{schemaSettings && (
					<PlusView
						className='cursor-pointer'
						onClick={() =>
							startOperation({
								operation: 'addTable',
								table: { tableName: '', tableSchema: {} },
							})
						}
					/>
				)}
			</FlexAlign>
			<FlexCol className='overflow-y-auto resize-y h-50vh'>
				{tables
					.filter(({ tableName }) => tableName !== '_users')
					.map((table, index: number) => (
						<div key={`${table.tableName}:${index}`}>
							<Menu
								id={`${table.tableName}:${index}`}
								visible={visible === index}
								className='-ml-3 mt-5 z-50'
								optStyle='flex items-center h-8'
								options={[
									{
										onClick: () => {
											startOperation({
												table,
												operation: 'editTable',
											});
											setVisible(undefined);
										},
										text: `${t('placeholder.edit')}`,
									},
									{
										onClick: () => {
											startOperation({
												table,
												operation: 'deleteTable',
											});
											setVisible(undefined);
										},
										text: `${t('placeholder.delete')}`,
									},
								]}
							/>
							<ListElement
								id={`${table.tableName}:${index}`}
								onElemClick={() =>
									history.push(`/dashboard/${table.tableName}`)
								}
								onContextClick={() => schemaSettings && setVisible(index)}
								rightIcon={schemaSettings ? 'dots' : undefined}
								text={translateName(table.tableName, table.alias, t, language)}
								tooltip={table.tableName}
								selected={tableName === table.tableName}
								selectable
							/>
						</div>
					))}
				<ListElement
					onElemClick={() => history.push('/dashboard/User')}
					onContextClick={() => history.push('/dashboard/User')}
					rightIcon='arrow'
					text={t('screen.users')}
					tooltip='_users'
					selected={tableName === 'User'}
					selectable
				/>
			</FlexCol>
		</ListWrapper>
	);
};

export default TablesList;
