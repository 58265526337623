import { FC, HTMLAttributes, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TModalOpt, TPosition, TTableModalProps } from 'types/app';
import { elemToArray, makeUrl, nFormatter } from 'utils';
import { Cross } from './assets';
import { FlexAlign, FlexCol, FlexRow, Text } from '.';
import { TFile } from 'types/table';
import { version } from 'config';
import { useResizeDetector } from 'react-resize-detector';

export const BackgroundElement: FC<{ height?: number | string }> = ({
	children,
	height = '70vh',
}) => (
	<div className={`col-span-1 h-${height} w-full my-8 bg-gray-dark rounded-10`}>
		{children}
	</div>
);

export const ClickableScreen: FC<
	HTMLAttributes<HTMLDivElement> & {
		cb: (e?: any) => void;
		zIndex?: number;
	}
> = ({ className = '', children, cb, zIndex = 1, ...props }) => (
	<div
		className={`fixed top-0 left-0 h-screen w-screen ${className}`}
		style={{ zIndex }}
		onMouseDown={cb}
		{...props}
	>
		{children}
	</div>
);

export const Modal: FC<
	HTMLAttributes<HTMLDivElement> & {
		onHide: () => void | Promise<void>;
	}
> = ({ onHide, className = '', children, ...props }) => (
	<ClickableScreen
		cb={onHide}
		zIndex={100}
		className='flex flex-row bg-black-modal justify-center items-center'
	>
		<div
			onMouseDown={(e) => e.stopPropagation()}
			className={className}
			{...props}
		>
			{children}
		</div>
	</ClickableScreen>
);

export const CommonModal: FC<HTMLAttributes<HTMLDivElement> & TModalOpt> = ({
	onHide,
	className = '',
	header = 'Modal',
	children,
	width = 'content',
	...props
}) => (
	<Modal
		className={`flex flex-col bg-white cursor-default rounded w-${width} overflow-hidden max-w-1000px`}
		onHide={onHide}
		{...props}
	>
		<FlexCol>
			{elemToArray(header).map((head, index) => (
				<FlexAlign
					key={`modalheader${index}`}
					style={{
						opacity: 0.5 + (0.5 / elemToArray(header).length) * (index + 1),
					}}
					className='relative justify-between py-2 px-4 w-full bg-primary text-white font-semibold'
				>
					<FlexAlign>
						{Array(index)
							.fill(1)
							.map((_, ind) => (
								<div
									style={{ left: `${0.75 * ind + 1}rem` }}
									className='absolute h-9 border-white-50 border-r'
								/>
							))}
						<Text
							style={{ marginLeft: `${0.75 * index}rem` }}
							color='white'
							fontWeight='semibold'
						>
							{head}
						</Text>
					</FlexAlign>
					{index === elemToArray(header).length - 1 && (
						<div
							className='bg-white-15 rounded p-1 cursor-pointer'
							onClick={onHide}
						>
							<Cross className='w-2 h-2' />
						</div>
					)}
				</FlexAlign>
			))}
		</FlexCol>
		<FlexCol className={`h-full w-full max-h-70vh p-5 ${className}`}>
			{children}
		</FlexCol>
	</Modal>
);

export const TableModal: FC<HTMLAttributes<HTMLDivElement> & TTableModalProps> =
	({ className = '', children, zIndex = 30, refId, ...props }) => {
		const {
			height: modalHeight,
			width: modalWidth,
			ref: modalRef,
		} = useResizeDetector<HTMLDivElement>();

		// ref values
		const [{ top, left, width, height }, setRefPosition] = useState<TPosition>({
			top: 0,
			left: 0,
			width: 0,
			height: 0,
		});

		useEffect(() => {
			const { height, width, top, left } = document
				.getElementById(refId)
				?.getBoundingClientRect() ?? { top: 0, left: 0, width: 0, height: 0 };
			setRefPosition({ top, left, height, width });
		}, [refId, modalHeight, modalWidth]);

		// if all ref values are 0
		if ((height === 0 && width === 0) || top === 0 || left === 0) return <></>;

		return (
			<FlexCol
				{...props}
				ref={modalRef}
				style={{
					top:
						top > window.innerHeight / 2
							? top - (modalHeight ?? 0) - 3
							: top + (height ?? 0),
					left: left > window.innerWidth / 2 ? left - (modalWidth ?? 0) : left,
					zIndex,
				}}
				className={`fixed select-none py-3 px-4 shadow-md rounded border border-gray bg-white ${className}`}
			>
				{children}
			</FlexCol>
		);
	};

export const Toast: FC = () => (
	<ToastContainer
		position='bottom-center'
		bodyClassName='text-white'
		closeButton={<Cross className='w-3 h-3' />}
		pauseOnHover
		draggable
		closeOnClick
		autoClose={5000}
	/>
);

export const Media: FC<
	HTMLAttributes<HTMLElement> & { file: TFile; hasStats?: boolean }
> = ({ file, hasStats, className = '', ...props }) => {
	if (!file?.mimetype) return <></>;
	const StatsOverlay: FC = () => (
		<FlexCol className='absolute bottom-0 w-full p-3 rounded-t bg-gradient-to-t from-black to-transparent'>
			<Text fontSize='xs' color='white'>
				{file.url.split('/').pop()}
			</Text>
			<Text fontSize='xs' color='white'>
				{nFormatter(file.size)}
			</Text>
		</FlexCol>
	);
	if (file.mimetype.startsWith('image'))
		return (
			<FlexCol className='relative'>
				<img
					{...props}
					className={className}
					src={makeUrl(file.url)}
					alt={file.url}
				/>
				{hasStats && <StatsOverlay />}
			</FlexCol>
		);

	if (file.mimetype.startsWith('video'))
		return (
			<FlexCol className='relative'>
				<video autoPlay muted loop className={className} {...props}>
					<source src={makeUrl(file.url)} />
				</video>
				{hasStats && <StatsOverlay />}
			</FlexCol>
		);

	return (
		<FlexCol {...props} className={`relative bg-gray-dark ${className}`}>
			<StatsOverlay />
		</FlexCol>
	);
};

export const Menu: FC<
	HTMLAttributes<HTMLDivElement> & {
		options: { text: string | any; onClick?: () => void }[];
		optStyle?: string;
		visible: boolean;
		id: string;
	}
> = ({
	children,
	className = '',
	options,
	optStyle = '',
	visible,
	id,
	...props
}) => {
	const [{ top, left, width }, setPosition] = useState<{
		top: number;
		left: number;
		width: number;
	}>({
		top: 0,
		left: 0,
		width: 0,
	});

	useEffect(() => {
		if (!id) return;
		const element = document.getElementById(id);
		if (!element) return;
		const { top, left, width } = element.getBoundingClientRect() ?? {
			top: 0,
			left: 0,
		};
		setPosition({ top, left, width });
	}, [visible, id]);

	return (
		<FlexCol
			className={`fixed bg-white shadow-md rounded w-content transition-maxh duration-quick overflow-hidden whitespace-nowrap ${
				visible ? 'max-h-1000px ease-in' : 'max-h-0 ease-out'
			} ${className}`}
			style={{
				top,
				left: left + width,
			}}
			{...props}
		>
			<FlexCol className='border border-black-light rounded'>
				{options.map(({ text, onClick }, index) => (
					<div
						key={`menuopt${text}${index}`}
						className={`p-2 hover:bg-primary hover:text-white cursor-pointer ${optStyle}`}
						onClick={onClick}
					>
						{text}
					</div>
				))}
			</FlexCol>
		</FlexCol>
	);
};

export const Tooltip: FC<HTMLAttributes<HTMLSpanElement>> = ({
	children,
	className = '',
	...props
}) => (
	<FlexRow
		className={`absolute bg-white border py-1 px-2 rounded border-black-light ${className}`}
		{...props}
	>
		{children}
	</FlexRow>
);

export const AppVersion: FC = () => (
	<Text
		color='black-light'
		fontSize='xs'
		className='absolute bottom-0 right-0 py-1 px-2 bg-gray rounded-tl'
	>
		v {version}
	</Text>
);

export const Loader: FC<HTMLAttributes<HTMLDivElement>> = ({
	className = '',
	...props
}) => <div className={`loader ${className}`} {...props} />;

export const SmallLoader: FC = () => (
	<Loader
		style={{
			height: '1.5rem',
			width: '1.5rem',
			borderWidth: '0.15rem',
			minWidth: '1.5rem',
		}}
	/>
);

export const ScreenLoader: FC = () => (
	<ClickableScreen
		zIndex={1000}
		cb={() => null}
		className='flex items-center justify-center backdrop-filter backdrop-blur-sm'
	>
		<Loader style={{ height: '4rem', width: '4rem' }} />
	</ClickableScreen>
);

export const ListWrapper: FC<HTMLAttributes<HTMLDivElement>> = ({
	children,
	className = '',
	...props
}) => (
	<FlexCol className={`w-full py-2 bg-white rounded ${className}`} {...props}>
		{children}
	</FlexCol>
);

export const Line: FC<
	HTMLAttributes<HTMLDivElement> & { width?: string; color?: string }
> = ({
	children,
	className = '',
	width = '22',
	color = 'black-light',
	...props
}) => (
	<div
		className={`w-${width} border border-${color} rounded-small ${className}`}
		{...props}
	/>
);

export const Chip: FC<
	HTMLAttributes<HTMLDivElement> & { isFile?: boolean; isRecursive?: boolean }
> = ({ children, className = '', isFile, isRecursive, ...props }) => (
	<FlexAlign
		className={`bg-primary rounded text-white text-xs whitespace-nowrap ${
			isRecursive ? '' : `px-2 ${isFile ? 'py-0.5' : 'py-1'}`
		} ${className}`}
		{...props}
	>
		{children}
	</FlexAlign>
);
