import { FC, useState, useEffect } from 'react';
import { TMenu, TQuantity } from 'types/table';
import {
	elemToArray,
	isNotEmpty,
	replaceElementInArray,
	toggleElementInArray,
} from 'utils';
import lodash from 'lodash';
import { FlexAlign, FlexCol, Label, Text } from 'components/basic';
import { ArrowSmall, Cross, Plus } from 'components/basic/assets';

import { selectorApp } from 'store/app/app.slice';
import { useSelector } from 'react-redux';

interface MenuInputProps {
	defaultValues: TQuantity | TQuantity[] | TMenu | TMenu[];
	multiple?: boolean;
	setValue: (value: TQuantity | TQuantity[] | TMenu | TMenu[]) => void;
	maxHeight: string;
	hasQuantity?: boolean;
}

const MenuInput: FC<MenuInputProps> = ({
	defaultValues,
	multiple,
	setValue,
	maxHeight,
	hasQuantity,
}) => {
	const { t } = useSelector(selectorApp);
	const [empty] = useState<TQuantity | TMenu>(
		hasQuantity ? { item: '', price: 0, quantity: 1 } : { item: '', price: 0 }
	);
	const [selectedValues, setSelectedValues] = useState<TQuantity[] | TMenu[]>(
		isNotEmpty(defaultValues) ? elemToArray(defaultValues) : [empty]
	);
	const [quantityInterval, setQuantityInterval] = useState<NodeJS.Timer>();
	const [visibleInput, setVisibleInput] = useState<number>();

	useEffect(() => {
		if (
			!lodash.isEqual(selectedValues, defaultValues) &&
			isNotEmpty(selectedValues)
		) {
			const values = selectedValues.filter(
				(value) =>
					isNotEmpty(value) &&
					value.price > 0 &&
					(!hasQuantity || (hasQuantity && (value as TQuantity).quantity > 0))
			);
			setValue(multiple ? values : values[0]);
		}
	}, [selectedValues]);

	const updateQuantity = ({
		index,
		value,
		subtract,
	}: {
		index: number;
		value?: number;
		subtract?: boolean;
	}) => {
		setSelectedValues((values) => {
			const { item, price, quantity } = values[index] as TQuantity;
			if ((quantity === 1 && subtract) || value === 0)
				return toggleElementInArray(values[index], values);
			const newValue = {
				item,
				price,
				quantity: value ?? subtract ? quantity - 1 : quantity + 1,
			};
			return multiple
				? replaceElementInArray(values as TQuantity[], newValue, index)
				: [newValue];
		});
	};

	return (
		<FlexCol
			style={{ maxHeight: `calc(${maxHeight} - 2rem)` }}
			className='pr-2 max-w-full overflow-auto'
			onMouseLeave={() => quantityInterval && clearInterval(quantityInterval)}
		>
			<FlexAlign>
				<Label className='ml-1 w-44'>Item</Label>
				<FlexAlign>
					<Label className='ml-3 w-20'>Price</Label>
					{hasQuantity && (
						<Label className='flex justify-center ml-3 w-6'>Qt.</Label>
					)}
				</FlexAlign>
			</FlexAlign>
			{selectedValues.map((value: TQuantity | TMenu, index: number) => (
				<FlexAlign
					className='ml-1 mb-2'
					key={`${JSON.stringify(value)}${index}`}
				>
					<input
						onBlur={({ currentTarget: { value: item } }) =>
							setSelectedValues((values) =>
								replaceElementInArray(values, { ...values[index], item }, index)
							)
						}
						defaultValue={value.item}
						className='rounded-small w-44 h-5 text-black px-2 py-1 focus:outline-none focus:ring-primary focus:ring-2 text-xs bg-gray'
						placeholder={`${t('placeholder.insert')}`}
					/>
					<FlexAlign className='ml-3'>
						<input
							onBlur={({ currentTarget: { value: price } }) =>
								setSelectedValues((values) =>
									replaceElementInArray(
										values,
										{ ...values[index], price: parseFloat(price) },
										index
									)
								)
							}
							type='number'
							defaultValue={value.price}
							className='rounded-small h-5 w-16 text-black px-2 py-1 focus:outline-none focus:ring-primary focus:ring-2 text-xs bg-gray'
							placeholder={`${t('placeholder.insert')}`}
						/>
						<Text fontSize='xs' className='w-4 pl-1'>
							€
						</Text>
						{hasQuantity && (
							<FlexCol className='ml-3 cursor-pointer'>
								<div
									className='py-1'
									onClick={() => updateQuantity({ index })}
									onMouseUp={() => {
										if (quantityInterval) clearInterval(quantityInterval);
										setQuantityInterval(undefined);
									}}
									onMouseDown={() => {
										if (quantityInterval) clearInterval(quantityInterval);
										setQuantityInterval(
											setInterval(() => {
												updateQuantity({ index });
											}, 100)
										);
									}}
								>
									<ArrowSmall className='transform rotate-180 w-full' />
								</div>
								{visibleInput === index ? (
									<input
										className='text-black font-xs w-6 focus:outline-none focus:ring-2 focus: ring-primary rounded'
										type='number'
										min={0}
										autoFocus
										defaultValue={(value as TQuantity).quantity}
										onBlur={({ currentTarget: { value } }) => {
											if (!isNaN(parseInt(value)))
												updateQuantity({
													index,
													value: parseInt(value),
												});
											setVisibleInput(undefined);
										}}
									/>
								) : (
									<Text
										className='flex w-6 justify-center'
										fontSize='xs'
										onClick={() => setVisibleInput(index)}
									>
										{(value as TQuantity).quantity}
									</Text>
								)}
								<div
									className='h-3 py-1'
									onClick={() => updateQuantity({ index, subtract: true })}
									onMouseUp={() => {
										if (quantityInterval) clearInterval(quantityInterval);
										setQuantityInterval(undefined);
									}}
									onMouseDown={() => {
										if (quantityInterval) clearInterval(quantityInterval);
										setQuantityInterval(
											setInterval(() => {
												updateQuantity({
													index,
													subtract: true,
												});
											}, 100)
										);
									}}
								>
									<ArrowSmall className='w-full' />
								</div>
							</FlexCol>
						)}
					</FlexAlign>
					<div className='ml-3 h-2 min-w-2'>
						{index === selectedValues?.length - 1 && multiple && (
							<Plus
								className='w-full h-full cursor-pointer'
								onClick={() =>
									setSelectedValues((values) => [...values, empty])
								}
							/>
						)}
					</div>
					{multiple && index > 0 && (
						<Cross
							className='w-2 h-2 min-w-2 ml-3 cursor-pointer'
							color='primary-50'
							onClick={() =>
								hasQuantity
									? updateQuantity({ index, value: 0 })
									: setSelectedValues((values) =>
											toggleElementInArray(values[index], values)
									  )
							}
						/>
					)}
				</FlexAlign>
			))}
		</FlexCol>
	);
};

export default MenuInput;
