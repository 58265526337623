import { FlexAlign, FlexCol, FlexRow, Label, Text } from 'components/basic';
import { Plus } from 'components/basic/assets';
import { DashedButton } from 'components/basic/buttons';
import { useEffect } from 'react';
import { FC, useState } from 'react';

import { useSelector } from 'react-redux';
import Table from 'services/table.service';
import { selectorApp } from 'store/app/app.slice';
import { selectorAuth } from 'store/auth/auth.slice';
import {
	TAtomicFilter,
	TGroupedOptions,
	TNestedFilter,
	TOption,
} from 'types/app';
import {
	TAction,
	TAtomicCondition,
	TAutomation,
	TAutomationProperties,
	TNestedConditions,
} from 'types/automation';
import { TSchema } from 'types/table';
import {
	createFilterOptions,
	isEmpty,
	isAtomicFilter,
	language,
	replaceElementInArray,
	translateName,
} from 'utils';
import ActionEdit from '../action-edit.component';
import InputFilter from '../filter-input/nested-filter-input.component';
import {
	InputSelectionAutomation,
	SectionConnector,
	SectionWrapper,
} from './automation-edit.style';

interface TAutomationEditProps {
	automation: TAutomation;
	setAutomation: (automation: TAutomation) => void;
	properties: TAutomationProperties;
}

const AutomationEdit: FC<TAutomationEditProps> = ({
	automation,
	properties,
	setAutomation,
}) => {
	const { tables } = useSelector(selectorAuth);

	const { t } = useSelector(selectorApp);
	const [actionOptions, setActionOptions] = useState<TGroupedOptions>({});

	const [actions, setActions] = useState<TAction[]>(automation.actions ?? []);

	// open = number refers to action index
	const [open, setOpen] = useState<'event' | 'condition' | number>();

	useEffect(() => {
		setActions(automation.actions);
	}, [automation]);

	useEffect(() => {
		if (isEmpty(properties)) return;
		const actionOpts: TGroupedOptions = {};
		Object.entries(properties.actions).forEach(([actionType, options]) => {
			const actionLabel = `${t(`automations.action.${actionType}`)}`;
			actionOpts[actionLabel] = options.map((opt: string) => ({
				label: `${t(`automations.action.${opt}`)}`,
				value: opt,
			}));
		});
		setActionOptions(actionOpts);
	}, [properties]);

	useEffect(() => {
		setAutomation({ ...automation, actions });
	}, [actions]);

	const translateConditions = (
		conditions: TAtomicCondition | TNestedConditions = {},
		tableSchema: TSchema
	): TAtomicFilter | TNestedFilter => {
		if (isEmpty(conditions)) return {};
		if (isAtomicFilter(conditions)) {
			const { firstOperand, operator, secondOperand } =
				conditions as TAtomicCondition;
			return {
				column: {
					fieldName: firstOperand,
					fieldSchema: tableSchema[firstOperand?.split('.')[0] ?? ''],
				},
				mode: operator,
				value: secondOperand,
			} as TAtomicFilter;
		}
		const operator =
			(Object.keys(conditions)[0] as keyof TNestedConditions) ?? '_and';
		return (
			{
				[operator]: (conditions as TNestedConditions)[operator]?.map(
					(atc: TNestedConditions | TAtomicCondition) =>
						translateConditions(atc, tableSchema)
				),
			} ?? {}
		);
	};

	const translateFilter = (
		filters: TAtomicFilter | TNestedFilter = {}
	): TAtomicCondition | TNestedConditions => {
		if (isEmpty(filters)) return {};
		if (isAtomicFilter(filters)) {
			const { column, mode, value } = filters as TAtomicFilter;
			return {
				firstOperand: column.fieldName,
				operator: mode,
				secondOperand: value,
			} as TAtomicCondition;
		}
		const operator = (Object.keys(filters)[0] as keyof TNestedFilter) ?? '_and';
		return {
			[operator]: (filters as TNestedFilter)[operator]?.map(
				(filt: TAtomicFilter | TNestedFilter) => translateFilter(filt)
			),
		};
	};

	const getActionType = (action: TAction) =>
		(Object.keys(properties.actions).find((type) => type in action) ??
			'crudl') as keyof Omit<TAction, 'actionRef'>;

	const getActionOptions = (index: number): TOption[] => {
		if (index === 0 || getActionType(actions[index - 1]) === 'crudl')
			return Object.entries(
				Table.getTable(
					tables,
					index === 0 ? automation.table : actions[index - 1]?.actionRef
				)?.tableSchema ?? {}
			).map(([fieldName, { alias }]) => ({
				label: translateName(fieldName, alias, t, language),
				value: { _actionIndex: index, _key: fieldName },
			}));

		return [
			{
				label: `${t('placeholder.value')}`,
				value: { _actionIndex: index, _key: 'value' },
			},
		];
	};

	return (
		<FlexCol className='p-4 w-1/2'>
			<Text className='mb-5' color='primary' fontWeight='semibold'>
				{automation.name}
			</Text>
			<FlexRow>
				<FlexCol
					style={{ scrollBehavior: 'smooth' }}
					className='w-full pr-2 overflow-y-auto max-h-full-grid'
				>
					<SectionWrapper
						open={open === 'event'}
						setOpen={setOpen}
						section='event'
					>
						<Label color={open === 'event' ? 'black' : 'white'}>Tipo</Label>
						<InputSelectionAutomation
							isFirst
							defaultSelected={automation.event}
							id='eventselection'
							onSelection={(event) =>
								setAutomation({
									...automation,
									event,
								})
							}
							options={{
								_nogroup: properties.events.map((prop) => ({
									label: t(`automations.event.${prop}`),
									value: prop,
								})),
							}}
						/>
						<Label
							className='mt-3'
							color={open === 'event' ? 'black' : 'white'}
						>
							{t('table.name')}
						</Label>
						<InputSelectionAutomation
							defaultSelected={automation.table}
							id='eventtableselection'
							onSelection={(table) =>
								setAutomation({
									...automation,
									table,
								})
							}
							options={{
								_nogroup: tables.map(({ tableName, alias }) => ({
									label: translateName(tableName, alias, t, language),
									value: tableName,
								})),
							}}
						/>
					</SectionWrapper>
					<SectionConnector />
					<SectionWrapper
						open={open === 'condition'}
						setOpen={setOpen}
						onClick={() =>
							open !== 'condition' &&
							isEmpty(automation.conditions) &&
							setAutomation({ ...automation, conditions: {} })
						}
						section='condition'
						onDelete={
							automation.conditions
								? () => {
										setAutomation({ ...automation, conditions: undefined });
										setOpen(undefined);
								  }
								: undefined
						}
					>
						<Label color={open === 'condition' ? 'black' : 'white'}>
							{`${t('automations.continue_if')}...`}
						</Label>
						{automation.conditions && (
							<InputFilter
								id='conditionsFilter'
								zIndex={98}
								automations={{}}
								setValue={(value) => {
									setAutomation({
										...automation,
										conditions: translateFilter(value),
									});
								}}
								defaultFilter={translateConditions(
									automation.conditions,
									Table.getTable(tables, automation.table)?.tableSchema ?? {}
								)}
								options={createFilterOptions(
									Table.getTable(tables, automation.table)?.tableSchema ?? {},
									t,
									[]
								)}
							/>
						)}
					</SectionWrapper>
					{actions?.map((currentAction, index) => (
						<ActionEdit
							key={`automationAction${index}`}
							getActionOptions={getActionOptions}
							getActionType={getActionType}
							open={open === index}
							setOpen={setOpen}
							translateConditions={translateConditions}
							translateFilter={translateFilter}
							onDelete={
								index > 0
									? () => setActions(actions.filter((_, ind) => ind !== index))
									: undefined
							}
							currentAction={currentAction}
							actionIndex={index}
							actionOptions={actionOptions}
							editAction={(action) =>
								setActions((acts) => replaceElementInArray(acts, action, index))
							}
						/>
					))}
					<SectionConnector />
					<DashedButton
						className='min-h-13'
						onClick={() => setActions([...actions, {}])}
					>
						<FlexAlign>
							<Plus className='h-2 w-2 min-w-2 mr-2' />
							<Text fontWeight='semibold' color='primary'>
								{`${t('placeholder.add')} ${t('automations.header.action')}`}
							</Text>
						</FlexAlign>
					</DashedButton>
				</FlexCol>
			</FlexRow>
		</FlexCol>
	);
};

export default AutomationEdit;
