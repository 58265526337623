import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TUser } from 'types/auth';
import { TStructure } from 'types/table';

interface AuthState {
	access_token: string | undefined;
	user: TUser | undefined;
	tables: TStructure[];
}

const initialState: AuthState = {
	tables: [],
	access_token: undefined,
	user: undefined,
};

const auth = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setUser(state, action: PayloadAction<TUser | undefined>) {
			state.user = action.payload;
		},

		logout(state) {
			state.access_token = undefined;
			state.user = undefined;
			state.tables = [];
		},

		login(state, action: PayloadAction<{ access_token: string; user: TUser }>) {
			state.access_token = action.payload.access_token;
			state.user = action.payload.user;
		},

		setTables(state, action: PayloadAction<TStructure[]>) {
			state.tables = action.payload ?? [];
		},

		clearAuth(state) {
			state = initialState;
		},
	},
});

export const selectorAuth = (state: { auth: AuthState }) => state.auth;
export const { setUser, logout, login, setTables, clearAuth } = auth.actions;
export default auth.reducer;
