import { FC, HTMLAttributes, useEffect } from 'react';
import { FlexAlign, Text } from 'components/basic';
import { TSelectable } from 'types/app';
import { trimText } from 'utils';
import { HorizontalArrow, VerticalDots } from 'components/basic/assets';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'themes/tippy.css';
import { useSelector } from 'react-redux';
import { selectorApp } from 'store/app/app.slice';
interface Props extends TSelectable {
	Icon?: JSX.Element;
	text: string | any;
	tooltip?: string;
	onElemClick?: () => void;
	onContextClick?: () => void;
	maxTextLength?: number;
	rightIcon?: 'arrow' | 'dots';
}

const ListElement: FC<HTMLAttributes<HTMLDivElement> & Props> = ({
	Icon,
	text,
	tooltip,
	selected,
	selectable,
	onContextClick,
	onElemClick,
	rightIcon,
	maxTextLength,
	className = '',
	...props
}) => {
	const { schemaSettings } = useSelector(selectorApp);
	useEffect(() => {
		tooltip &&
			schemaSettings &&
			tippy(`#element${tooltip}`, {
				content: tooltip,
				arrow: false,
				hideOnClick: true,
				placement: 'right-start',
				zIndex: 50,
				theme: 'tooltip',
			});
	}, [tooltip]);

	return (
		<FlexAlign
			className={`justify-between whitespace-nowrap mb-1 h-8 min-h-8 ${
				selectable ? 'px-4' : ''
			} ${
				onElemClick
					? `cursor-pointer ${
							selected ? 'text-white bg-primary' : 'hover:bg-primary-15'
					  }`
					: ''
			} ${className}`}
			onClick={onElemClick}
			onContextMenu={(e) => {
				if (onContextClick) {
					e.preventDefault();
					onContextClick();
				}
			}}
			{...props}
		>
			<FlexAlign>
				{Icon && <div className='mr-2 w-min h-auto'>{Icon}</div>}
				<Text
					id={`element${tooltip}`}
					color={selected ? 'white' : 'black'}
					fontWeight={selected ? 'semibold' : 'normal'}
				>
					{maxTextLength ? trimText(text, maxTextLength) : text}
				</Text>
			</FlexAlign>
			{rightIcon === 'dots' && (
				<VerticalDots
					className={`z-20 ml-2 ${selected ? '' : 'opacity-25'}`}
					color={selectable ? (selected ? 'white' : 'black') : 'primary'}
					onClick={(e) => {
						e.stopPropagation();
						onContextClick && onContextClick();
					}}
				/>
			)}
			{rightIcon === 'arrow' && (
				<HorizontalArrow
					className={`z-20 ml-2 ${selected ? '' : 'opacity-25'}`}
					color={selectable ? (selected ? 'white' : 'black') : 'primary'}
					onClick={(e) => {
						e.stopPropagation();
						onContextClick && onContextClick();
					}}
				/>
			)}
		</FlexAlign>
	);
};

export default ListElement;
