import { FC, useRef, useState, useEffect } from 'react';
import { FlexAlign, FlexCol, Text } from 'components/basic';
import { Delete } from 'components/basic/assets';
import { ActionButton } from 'components/basic/buttons';
import { InputArea, InputToggle } from 'components/basic/inputs';
import { ClickableScreen } from 'components/basic/others';

import { toast } from 'react-toastify';
import { TAutomation } from 'types/automation';
import { isEmpty } from 'utils';
import { selectorApp } from 'store/app/app.slice';
import { useSelector } from 'react-redux';

interface AutomationProps {
	automation: TAutomation;
	selected?: boolean;
	onDelete: () => void;
	onToggle: (value: boolean) => void;
	onSelect: () => void;
	onSubmit: (name: string, description?: string) => void;
	className?: string;
}

const AutomationElement: FC<AutomationProps> = ({
	selected,
	className = '',
	onDelete,
	onToggle,
	onSelect,
	onSubmit,
	automation,
}) => {
	const { t } = useSelector(selectorApp);
	const [edit, setEdit] = useState<'name' | 'description' | undefined>(
		automation.name === '' ? 'name' : undefined
	);
	const [name, setName] = useState<string>(automation.name);
	const [description, setDescription] = useState<string | undefined>(
		automation.description
	);

	useEffect(() => {
		if (edit === 'name') inputRef.current?.focus();
	}, [edit]);

	const inputRef = useRef<HTMLInputElement>(null);

	return (
		<>
			{edit && (
				<ClickableScreen
					cb={() => {
						if (isEmpty(name)) {
							toast.error('Name cannot be empty');
							setEdit('name');
						}
						if (
							name !== automation.name ||
							description !== automation.description
						)
							onSubmit(name, description);
						setEdit(undefined);
					}}
				/>
			)}
			<FlexCol
				className={`p-4 rounded-small border border-primary cursor-pointer ${
					selected ? 'bg-primary' : 'bg-white'
				} ${className}`}
				onClick={() => !edit && onSelect()}
			>
				<FlexAlign className='justify-between mb-3'>
					<Text
						onDoubleClick={() => setEdit('name')}
						color={selected ? 'white' : 'black'}
						fontWeight='semibold'
						className='min-w-1/2'
					>
						{edit === 'name' ? (
							<input
								ref={inputRef}
								autoFocus
								defaultValue={automation.name}
								className={`w-full relative text-sm font-semibold z-20 border-b border-${
									selected ? 'white' : 'primary'
								} focus:outline-none bg-transparent`}
								onChange={({ currentTarget: { value } }) => setName(value)}
							/>
						) : isEmpty(automation.name) ? (
							name
						) : (
							automation.name
						)}
					</Text>
					<FlexAlign className='z-20' onClick={(e) => e.stopPropagation()}>
						<ActionButton
							Icon={<Delete color={selected ? 'white' : 'primary'} />}
							className='mr-2'
							color={selected ? 'white' : 'primary'}
							onClick={onDelete}
						>
							{t('placeholder.remove')}
						</ActionButton>
						<InputToggle
							background={
								selected
									? 'white-50'
									: automation.active
									? 'primary'
									: 'gray-dark'
							}
							setValue={(value) => onToggle(value)}
							checked={automation.active}
						/>
					</FlexAlign>
				</FlexAlign>
				<Text
					onDoubleClick={() => description && setEdit('description')}
					onClick={(e) => {
						e.stopPropagation();
						!description && setEdit('description');
					}}
					fontSize='xs'
					className='z-20 w-full'
					color={selected ? 'white' : 'black'}
				>
					{edit === 'description' ? (
						<InputArea
							autoFocus
							defaultValue={automation.description}
							onChange={({ currentTarget: { value } }) => setDescription(value)}
						/>
					) : (
						automation.description ?? '+ Aggiungi una descrizione'
					)}
				</Text>
			</FlexCol>
		</>
	);
};

export default AutomationElement;
