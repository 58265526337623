import { FlexAlign, Text } from 'components/basic';
import { Cross } from 'components/basic/assets';
import { Checkbox } from 'components/basic/buttons';
import { InputText } from 'components/basic/inputs';
import { FC, useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import Table from 'services/table.service';
import { selectorApp } from 'store/app/app.slice';
import { TAlias, TAtomicFilter, TColumn, TOption } from 'types/app';
import { TOperand } from 'types/automation';
import { getFilterModes, language } from 'utils';
import { InputSelectionFilter } from './filter-input.style';

type TFilterProps = {
	filter: TAtomicFilter;
	position: string;
	automations?: {
		getActionOptions?: (index: number) => TOption[];
		actionIndex?: number;
	};
	onDelete: (position: string) => void;
	refId: string;
	options: TOption[];
	editFilter: (position: string, value: any) => void;
};

const AtomicFilter: FC<TFilterProps> = ({
	filter: { mode, column, value },
	position,
	automations,
	refId,
	onDelete,
	options,
	editFilter,
}) => {
	const [inputType, setInputType] = useState<'custom' | number>('custom');
	const [index, setIndex] = useState<number>();
	const { t } = useSelector(selectorApp);
	const previousActionsLabel = `${t('automations.previous_actions')}`;

	useEffect(() => {
		if (automations && value && !('_constant' in (value as TOperand))) {
			setInputType((value as TOperand)._actionIndex ?? 'custom');
		}
	}, [automations]);

	useEffect(() => {
		setIndex(parseInt(position.split('.').pop() ?? '-1'));
	}, [position]);

	const handleInput = (value: any) =>
		editFilter(
			index === -1 ? 'value' : `${position}.value`,
			automations ? { _constant: value } : value
		);

	const printInput = (column: TColumn, position: string): JSX.Element => {
		if (!column?.fieldSchema) return <></>;
		const { fieldSchema } = column;
		const actualValue = automations ? (value as TOperand)?._constant : value;
		if (fieldSchema?.enum) {
			const enumOptions: TOption[] = fieldSchema.translatable
				? Object.entries(fieldSchema.enum[language]).map(([value, label]) => ({
						label,
						value,
				  }))
				: fieldSchema.enum.map((value: string) => ({
						label: value,
						value,
				  }));
			return (
				<InputSelectionFilter
					key={`filterinput${refId}`}
					id={`${refId}4${JSON.stringify(position)}`}
					defaultSelected={actualValue}
					hasBorder
					arrowColor={automations ? 'primary' : 'black-light'}
					options={{ _nogroup: enumOptions }}
					onSelection={(opt) => handleInput(opt)}
				/>
			);
		}
		const fieldType = Table.getType(fieldSchema.type);
		switch (fieldType) {
			case 'ObjectId':
				const { value: fieldName, schema: subFieldSchema } = fieldSchema.column;
				return printInput(
					{ fieldName, fieldSchema: subFieldSchema },
					`position.${fieldName}`
				);
			case 'Boolean':
				return (
					<Checkbox
						key={`filterinput${refId}`}
						className='h-7 w-7'
						onClick={() => handleInput(!actualValue)}
						checked={actualValue as boolean}
					/>
				);
			default:
				return (
					<InputText
						key={`filterinput${refId}`}
						className='min-w-100px'
						type={
							['Number', 'String'].includes(fieldType)
								? fieldType.toLowerCase()
								: 'text'
						}
						defaultValue={
							actualValue
								? fieldSchema.translatable
									? (actualValue as TAlias)[language]
									: (actualValue as string)
								: undefined
						}
						onChange={({ currentTarget: { value } }) =>
							handleInput(
								fieldSchema?.translatable ? { [language]: value } : value
							)
						}
					/>
				);
		}
	};

	return (
		<FlexAlign className='my-1'>
			{!!automations && (
				<Cross
					color='black-light'
					className='mr-3 w-2 min-w-2 h-2 cursor-pointer'
					onClick={() => onDelete(position)}
				/>
			)}
			{index === -1 && !automations && (
				<Text className='mr-2' fontSize='xs'>
					{t('placeholder.where')}
				</Text>
			)}
			<InputSelectionFilter
				id={`${refId}1${JSON.stringify(position)}`}
				hasBorder
				arrowColor={!!automations ? 'primary' : 'black-light'}
				options={{ _nogroup: options }}
				defaultSelected={column}
				onSelection={(value) =>
					editFilter(index === -1 ? 'column' : `${position}.column`, value)
				}
			/>
			<InputSelectionFilter
				id={`${refId}2${JSON.stringify(position)}`}
				hasBorder
				arrowColor={!!automations ? 'primary' : 'black-light'}
				options={{ _nogroup: getFilterModes(column, t) }}
				defaultSelected={mode}
				onSelection={(value) =>
					editFilter(index === -1 ? 'mode' : `${position}.mode`, value)
				}
			/>
			{!!automations && (
				<InputSelectionFilter
					id={`${refId}3${JSON.stringify(position)}`}
					hasBorder
					arrowColor={!!automations ? 'primary' : 'black-light'}
					options={{
						_nogroup: [
							{
								label: `${t('input.cron.custom')}`,
								value: 'custom',
							},
							{
								label: `${t('automations.event_element')}`,
								value: 0,
							},
						],
						[previousActionsLabel]: Array(automations.actionIndex ?? 0)
							.fill(0)
							.map((_, index) => ({
								label: `${t('automations.header.action')} ${index + 1}`,
								value: index + 1,
							})),
					}}
					defaultSelected={inputType}
					onSelection={(value) => setInputType(value)}
				/>
			)}
			{inputType === 'custom' ? (
				<div className='flex-1'>{printInput(column, position)}</div>
			) : (
				<InputSelectionFilter
					id={`${refId}4${JSON.stringify(position)}`}
					hasBorder
					arrowColor={!!automations ? 'primary' : 'black-light'}
					options={{
						_nogroup: automations?.getActionOptions
							? automations.getActionOptions(inputType)
							: [],
					}}
					defaultSelected={value}
					onSelection={(value) =>
						editFilter(index === -1 ? 'value' : `${position}.value`, value)
					}
				/>
			)}
		</FlexAlign>
	);
};

export default AtomicFilter;
