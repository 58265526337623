import { FC, useState, useEffect } from 'react';
import { FlexAlign, FlexCol, Text } from 'components/basic';
import { Cross } from 'components/basic/assets';
import { InputSelection } from 'components/basic/inputs';
import { ClickableScreen, TableModal } from 'components/basic/others';

import {
	TColumn,
	TNestedFilter,
	TOption,
	TAtomicFilter,
	AnyObject,
} from 'types/app';
import { isEmpty, isAtomicFilter } from 'utils';
import lodash from 'lodash';
import InputFilter from 'components/hoc/filter-input/nested-filter-input.component';
import { InputSelectionFilter } from 'components/hoc/filter-input/filter-input.style';
import { selectorApp } from 'store/app/app.slice';
import { useSelector } from 'react-redux';

interface OptionProps {
	options: TOption[];
	tableOptionId: string;
	defaultOptions: any;
	type: 'filter' | 'group' | 'sort' | 'hide';
	onClose: (value: any) => void;
}

type TFieldOption = { [field: string]: -1 | 0 | 1 | any };

const TableOptionModal: FC<OptionProps> = ({
	options,
	tableOptionId,
	defaultOptions,
	onClose,
	type,
}) => {
	const { t } = useSelector(selectorApp);
	const [currentOptions, setCurrentOptions] = useState<TFieldOption>(
		defaultOptions ?? {}
	);
	const [order, setOrder] = useState<'ASC' | 'DESC'>('ASC');

	const handleClose = () => onClose(currentOptions);

	const isAtomicOption = (options: TFieldOption) =>
		!options?.$or && !options?.$and;

	const createFilter = ({ column, mode, value }: TAtomicFilter): AnyObject => {
		if (isEmpty(column?.fieldName) || isEmpty(mode)) return {};
		// TODO: Implement other modes
		let filter = {};
		switch (mode) {
			case 'isEqual':
				filter = { [column.fieldName]: value };
				break;
			case 'isNotEqual':
				filter = { [column.fieldName]: { $ne: value } };
				break;
			case 'contains':
				filter = { [column.fieldName]: { $regex: value, $options: 'i' } };
				break;
			case 'notContains':
				filter = {
					[column.fieldName]: { $not: { $regex: value, $options: 'i' } },
				};
				break;
			case 'isGreater':
				filter = { [column.fieldName]: { $gt: value } };
				break;
			case 'isLesser':
				filter = { [column.fieldName]: { $lt: value } };
				break;
		}
		return filter;
	};

	const translateFilter = (temp: AnyObject): TAtomicFilter | object => {
		const filter = lodash.cloneDeep(temp);
		const fieldName = Object.keys(filter)[0];

		const column: TColumn = {
			fieldName,
			fieldSchema: options.filter(
				({ value }) => value.fieldName === fieldName
			)[0]?.value?.fieldSchema,
		};

		if (typeof filter[fieldName] === 'object') {
			if ('$ne' in filter[fieldName])
				return { column, value: filter[fieldName].$ne, mode: 'isNotEqual' };
			if ('$regex' in filter[fieldName])
				return { column, value: filter[fieldName].$regex, mode: 'contains' };
			if ('$not' in filter[fieldName])
				return {
					column,
					value: filter[fieldName].$not.$regex,
					mode: 'notContains',
				};
			if ('$gt' in filter[fieldName])
				return { column, value: filter[fieldName].$gt, mode: 'isGreater' };
			if ('$lt' in filter[fieldName])
				return { column, value: filter[fieldName].$lt, mode: 'isLesser' };
		}

		return { column, value: filter[fieldName], mode: 'isEqual' };
	};

	const updateCurrentOptions = (filterOpts: TAtomicFilter | TNestedFilter) => {
		if (isAtomicFilter(filterOpts)) {
			setCurrentOptions(createFilter(filterOpts as TAtomicFilter));
		} else {
			const outerOp = Object.keys(filterOpts)[0] as keyof TNestedFilter;
			const innerOp: keyof TNestedFilter = outerOp === '_and' ? '_or' : '_and';
			const newOptions = {
				[outerOp.replace('_', '$')]:
					(filterOpts as TNestedFilter)[outerOp]?.map((filter: any) =>
						isAtomicFilter(filter)
							? createFilter(filter as TAtomicFilter)
							: {
									[innerOp.replace('_', '$')]:
										(filter as TNestedFilter)[innerOp]?.map((inner: any) =>
											createFilter(inner as TAtomicFilter)
										) ?? undefined,
							  }
					) ?? [],
			};
			setCurrentOptions(newOptions);
		}
	};

	const translateCurrentOptions = () => {
		if (isAtomicOption(currentOptions)) {
			return translateFilter(currentOptions);
		} else {
			const outerOp = Object.keys(currentOptions)[0].replace('_', '$') as
				| '$and'
				| '$or';
			const innerOp = outerOp === '$and' ? '$or' : '$and';
			const newOptions = {
				[outerOp.replace('$', '_')]:
					currentOptions[outerOp]?.map((filter: any) =>
						isAtomicOption(filter)
							? translateFilter(filter)
							: {
									[innerOp.replace('$', '_')]:
										filter[innerOp]?.map((inner: any) =>
											translateFilter(inner)
										) ?? undefined,
							  }
					) ?? [],
			};
			return newOptions;
		}
	};

	useEffect(() => {
		if (type !== 'sort') return;
		let value: -1 | 0 | 1 = -1;
		if (order === 'ASC') value = 1;
		const newOptions: TFieldOption = {};
		Object.keys(currentOptions).forEach((opt) => (newOptions[opt] = value));
		setCurrentOptions(newOptions);
	}, [order]);

	return (
		<>
			<ClickableScreen cb={handleClose} />
			<TableModal id={`${type}optionmodal`} zIndex={100} refId={tableOptionId}>
				{type !== 'filter' ? (
					<FlexCol>
						<FlexAlign className='justify-between mb-3'>
							<FlexAlign className='cursor-pointer' onClick={handleClose}>
								<Cross color='black-light' className='w-2 h-2 mr-3' />
								<Text fontSize='xs'>{t(`table.option.${type}`)}</Text>
							</FlexAlign>
							{type === 'sort' && (
								<div className='w-12'>
									<InputSelection
										id='sortselection'
										optionsListClass='bg-white'
										buttonClass='min-h-6 pl-1 bg-white'
										optionClass='min-h-6 pl-1'
										options={{
											_nogroup: [
												{ label: 'A-Z', value: 'ASC' },
												{ label: 'Z-A', value: 'DESC' },
											],
										}}
										defaultSelected={order}
										onSelection={(value) => setOrder(value)}
									/>
								</div>
							)}
						</FlexAlign>
						<InputSelectionFilter
							id='hidesortgroupselection'
							multiple={type !== 'group'}
							lastRounded
							hasBorder
							options={{
								_nogroup: (type === 'hide'
									? options.filter(({ value }) => value.fieldName !== '_id')
									: options
								).map(({ label, value }) => ({
									label,
									value: value?.fieldName?.split('.')[0],
								})),
							}}
							defaultSelected={
								type === 'group'
									? currentOptions.value
									: Object.keys(currentOptions)
							}
							onSelection={(value) => {
								if (type === 'group')
									setCurrentOptions({ value: value.split('.')[0] });
								else
									setCurrentOptions(
										value.reduce(
											(obj: any, field: string) => ({
												...obj,
												[field.split('.')[0]]:
													type === 'hide' ? 0 : order === 'ASC' ? 1 : -1,
											}),
											{}
										)
									);
							}}
						/>
						{type === 'group' && currentOptions.value && (
							<>
								<FlexAlign
									className='cursor-pointer mt-2 mb-3'
									onClick={() => setCurrentOptions(({ value }) => ({ value }))}
								>
									<Cross color='black-light' className='w-2 h-2 mr-3' />
									<Text fontSize='xs'>{t(`table.option.subgroup`)}</Text>
								</FlexAlign>
								<InputSelectionFilter
									id='subgroupselection'
									lastRounded
									hasBorder
									options={{
										_nogroup: options
											.filter(
												({ value }) => value?.fieldName !== currentOptions.value
											)
											.map(({ label, value }) => ({
												label,
												value: value?.fieldName,
											})),
									}}
									defaultSelected={currentOptions.subgroup}
									onSelection={(subgroup) =>
										setCurrentOptions({ ...currentOptions, subgroup })
									}
								/>
							</>
						)}
					</FlexCol>
				) : (
					<InputFilter
						id='tableFilter'
						defaultFilter={translateCurrentOptions()}
						options={options}
						setValue={(value) => updateCurrentOptions(value)}
						handleClose={handleClose}
					/>
				)}
			</TableModal>
		</>
	);
};

export default TableOptionModal;
