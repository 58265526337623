import { FC, useState, useEffect } from 'react';
import ObjectIdElement from 'components/hoc/objectid-element';
import { TElement, TSchema, TStructure } from 'types/table';

import {
	createFilterOptions,
	elemToArray,
	isNotEmpty,
	objectFilter,
	toggleElementInArray,
} from 'utils';
import TableOption from '../hoc/table-option';
import lodash from 'lodash';
import { SmallLoader } from 'components/basic/others';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FlexAlign, FlexCol } from 'components/basic';
import { useSelector } from 'react-redux';
import { selectorAuth } from 'store/auth/auth.slice';
import { InputArray, InputText } from 'components/basic/inputs';
import { Plus } from 'components/basic/assets';
import { ActionButton } from 'components/basic/buttons';
import { selectorApp, setPreviewSession } from 'store/app/app.slice';
import { useAppDispatch } from 'store';
import Table from 'services/table.service';

interface ObjectIdInputProps {
	initialFields: string[];
	defaultValues: TElement[] | TElement;
	multiple?: boolean;
	setValue: (value: TElement[] | TElement) => void;
	tableName: string;
	maxHeight: string;
	previousTableField: string; // Column of the current selected table
	retrieveElement?: () => Partial<TElement>;
}

const ObjectIdInput: FC<ObjectIdInputProps> = ({
	initialFields,
	defaultValues,
	multiple,
	setValue,
	tableName,
	maxHeight,
	previousTableField,
	retrieveElement,
}) => {
	const dispatch = useAppDispatch();
	const { tables, user } = useSelector(selectorAuth);
	const { previewSession, visibleDefaultFields, t } = useSelector(selectorApp);
	const [selectedValues, setSelectedValues] = useState<TElement[]>([]);
	const [currentTable, setCurrentTable] = useState<TStructure>();
	const [filter, setFilter] = useState<any>({});
	const [sort, setSort] = useState<any>({});
	const [elements, setElements] = useState<TElement[]>([]);
	const [page, setPage] = useState<number>(2);
	const [hasNextPage, setHasNextPage] = useState<boolean>(true);
	const limit = 10;

	const defaultLoadElements = async (reset = false) => {
		const newElements = (await Table.loadTableElements(
			tableName,
			{ filter, sort },
			reset ? 1 : page,
			limit
		)) as TElement[];
		if (reset) {
			const selecteds = isNotEmpty(selectedValues)
				? elemToArray(selectedValues)
				: [];
			const selectedElements = (
				selecteds?.length > 0
					? await Table.loadTableElements(tableName, {
							filter: {
								_id: {
									$in: selecteds.map(({ _id }) => _id),
								},
							},
							sort,
					  })
					: []
			) as TElement[];
			setElements(
				lodash.uniqBy([...selectedElements, ...newElements], (elem) => elem._id)
			);
			setHasNextPage(true);
			setPage(2);
		} else {
			if (newElements.length > 0) {
				setElements(
					lodash.uniqBy([...elements, ...newElements], (elem) => elem._id)
				);
				setPage((page) => page + 1);
			} else setHasNextPage(false);
		}
	};

	useEffect(() => {
		(async () => {
			if (tableName === '_roles') {
				// TODO: Temporarly defined role schema without endpoint
				setCurrentTable({
					tableName: 'Roles',
					tableSchema: {
						_id: { type: 'String', immutable: true },
						name: { type: 'String' },
						updatedAt: { type: 'DateTime', immutable: true },
						createdAt: { type: 'DateTime', immutable: true },
					},
				});
			} else {
				setCurrentTable(Table.getTable(tables, tableName));
			}
			const defaults = isNotEmpty(defaultValues)
				? elemToArray(defaultValues)
				: [];
			const defaultElements = (
				defaults?.length > 0
					? await Table.loadTableElements(tableName, {
							filter: {
								_id: {
									$in: defaults.map(({ _id }) => _id),
								},
							},
							sort,
					  })
					: []
			) as TElement[];
			setSelectedValues(defaults);
			const allElements = (await Table.loadTableElements(
				tableName,
				{ filter, sort },
				1,
				limit
			)) as TElement[];
			if (allElements.length === 0) setHasNextPage(false);
			setElements(
				lodash.uniqBy([...defaultElements, ...allElements], (elem) => elem._id)
			);
		})();
	}, [tableName]);

	useEffect(() => {
		if (elements.length > 0) defaultLoadElements(true);
	}, [filter, sort]);

	useEffect(() => {
		if (
			!lodash.isEqual(selectedValues, defaultValues) &&
			isNotEmpty(selectedValues)
		)
			setValue(multiple ? selectedValues : selectedValues[0]);
	}, [selectedValues]);

	// Fallback to simple input if table is not found
	if (!currentTable)
		return multiple ? (
			<InputArray
				setValue={(value) =>
					setSelectedValues(value?.map((_id) => ({ _id })) ?? [])
				}
			/>
		) : (
			<InputText
				autoFocus
				onChange={({ currentTarget: { value } }) =>
					setSelectedValues([{ _id: value }])
				}
			/>
		);

	return (
		<FlexCol>
			<FlexAlign className='justify-between mb-2'>
				<FlexAlign>
					<TableOption
						id='objectidfilter'
						onCancel={() => setFilter({})}
						selected={isNotEmpty(filter)}
						className='mr-2'
						type='filter'
						onModalSubmit={(filter) => setFilter(filter)}
						options={createFilterOptions(
							currentTable.tableSchema,
							t,
							visibleDefaultFields,
							true
						)}
						defaultOptions={filter}
					/>
					<TableOption
						id='objectidsort'
						onCancel={() => setSort({})}
						selected={isNotEmpty(sort)}
						type='sort'
						onModalSubmit={(sort) => setSort(sort)}
						options={createFilterOptions(
							currentTable.tableSchema,
							t,
							visibleDefaultFields
						)}
						defaultOptions={sort}
					/>
				</FlexAlign>
				<ActionButton
					Icon={<Plus className='w-13px h-13px min-w-13px' />}
					onClick={() => {
						const newSession = lodash.cloneDeep([
							...previewSession,
							{
								table: currentTable,
								element: Table.newElement(user ?? {}),
								previousTableField,
							},
						]);
						const secondLastElement = retrieveElement && retrieveElement();
						if (secondLastElement)
							newSession[newSession.length - 2].element = secondLastElement;
						dispatch(setPreviewSession(newSession));
					}}
				>
					{t('placeholder.add')}
				</ActionButton>
			</FlexAlign>
			<FlexCol
				id='objectidscrollable'
				style={{ maxHeight: `calc(${maxHeight} - 2rem)` }}
				className='pr-1 max-w-full overflow-auto'
			>
				<InfiniteScroll
					scrollableTarget='objectidscrollable'
					dataLength={elements.length}
					next={defaultLoadElements}
					hasMore={!!hasNextPage}
					scrollThreshold={0.5}
					loader={<SmallLoader />}
				>
					{elements.map((elem, index) => (
						<ObjectIdElement
							className='mb-2'
							key={`objectid:${JSON.stringify(elem)}${index}`}
							onElemClick={() => {
								let newValues: TElement[] = [];
								if (multiple) {
									newValues = toggleElementInArray(
										elem,
										selectedValues,
										(value) => value._id !== elem._id
									);
								} else {
									if (lodash.isEqual(selectedValues[0], elem)) newValues = [];
									else newValues = [elem];
								}
								setSelectedValues(newValues);
							}}
							selected={
								!!elem._id &&
								selectedValues.map(({ _id }) => _id).includes(elem._id)
							}
							index={index}
							initialFields={initialFields}
							table={{
								...currentTable,
								tableSchema: objectFilter(
									currentTable.tableSchema,
									([fieldName]) =>
										!Table.defaultFields.includes(fieldName) ||
										(Table.defaultFields.includes(fieldName) &&
											visibleDefaultFields?.includes(fieldName))
								) as TSchema,
							}}
							element={elem}
						/>
					))}
				</InfiniteScroll>
			</FlexCol>
		</FlexCol>
	);
};

export default ObjectIdInput;
