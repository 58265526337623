import { FC, useState, useEffect } from 'react';
import { FlexAlign, FlexCol } from 'components/basic';
import { TOption, TTableModalProps } from 'types/app';
import { Cross, Plus } from 'components/basic/assets';
import { TElement, TStructure } from 'types/table';
import lodash from 'lodash';
import {
	elemToArray,
	isEmpty,
	language,
	isNotEmpty,
	replaceElementInArray,
	replaceObjectKey,
	translateName,
} from 'utils';

import { InputSelectionAutomation } from './automation-edit/automation-edit.style';
import { ActionButton, Checkbox } from 'components/basic/buttons';
import TranslationModal from 'components/modal/translation.modal';
import TimeRangeModal from 'components/modal/time-range.modal';
import PeriodModal from 'components/modal/period.modal';
import ObjectIdModal from 'components/modal/objectid.modal';
import {
	InputArray,
	InputFile,
	InputLocation,
	InputText,
} from 'components/basic/inputs';
import { ClickableScreen, Media } from 'components/basic/others';
import CronModal from 'components/modal/cron.modal';
import moment from 'moment';
import { PrintField } from 'components/basic/print';
import Table from 'services/table.service';
import MenuModal from 'components/modal/menu.modal';
import { selectorApp } from 'store/app/app.slice';
import { useSelector } from 'react-redux';

interface TSelectorProps extends TTableModalProps {
	actionIndex: number;
	table: TStructure;
	editElement: (elem: TElement) => void;
	currentElement?: TElement;
	getActionOptions: (index: number) => TOption[];
	onPlusElementClick?: () => void;
}

const ActionSelector: FC<TSelectorProps> = ({
	actionIndex,
	table: { tableName, tableSchema },
	refId,
	editElement,
	currentElement = {},
	getActionOptions,
	onPlusElementClick,
}) => {
	const { t } = useSelector(selectorApp);
	const previousActionsLabel = `${t('automations.previous_actions')}`;
	const [visibleInput, setVisibleInput] = useState<string>();
	const [fields, setFields] = useState<
		{ field: string; type?: 'custom' | number }[]
	>([]);
	const [fieldOptions, setFieldOptions] = useState<TOption[]>([]);

	const [tempValue, setTempValue] = useState<any>();

	useEffect(() => {
		setFieldOptions(
			Object.entries(tableSchema)
				.filter(
					([fieldName]) =>
						/* TODO: Implement grouped Input Selection
						 	!fields.map((field) => field).includes(fieldName) && */
						!Table.defaultFields.includes(fieldName)
				)
				.map(([fieldName, fieldSchema]) => ({
					label: translateName(fieldName, fieldSchema.alias, t, language),
					value: fieldName,
				}))
		);
	}, [tableSchema]);

	useEffect(() => {
		const currentFields = Object.entries(currentElement ?? {}).map(
			([field, value]) => ({
				field,
				type: '_constant' in value ? 'custom' : value._actionIndex,
			})
		);
		setFields(
			isEmpty(currentFields)
				? [
						{
							type: 'custom',
							field:
								Object.keys(tableSchema).find(
									(field) => !Table.defaultFields.includes(field)
								) ?? '',
						},
				  ]
				: currentFields
		);
	}, [tableSchema]);

	const handleInput = (field: string, value?: any) => {
		editElement({
			...currentElement,
			[field]: { _constant: value ?? tempValue },
		});
		setTempValue(undefined);
		setVisibleInput(undefined);
	};

	const PrintInput = (field: string) => {
		const attributes = tableSchema[field];
		if (!attributes?.type) return <div />;
		const isArrayInput =
			Array.isArray(attributes.type) || attributes.type === 'Array';
		const fieldType = Table.getType(attributes.type);
		const id = `${refId}${field}`;
		const element = currentElement[field]?.['_constant'];

		if (attributes.enum) {
			const options: TOption[] = attributes.translatable
				? Object.entries(attributes.enum[language]).map(([value, label]) => ({
						label,
						value,
				  }))
				: attributes.enum.map((value: string) => ({
						label: value,
						value,
				  }));

			return (
				<InputSelectionAutomation
					buttonClass='w-full'
					id={id}
					defaultSelected={element}
					multiple={isArrayInput}
					options={{ _nogroup: options }}
					onSelection={(opt) => handleInput(field, opt)}
				/>
			);
		}

		if (attributes.translatable) {
			return (
				<TranslationModal
					multiple={isArrayInput}
					onClose={(value) => handleInput(field, value)}
					defaultValue={element}
					refId={id}
				/>
			);
		}

		if (fieldType === 'TimeRange') {
			return (
				<TimeRangeModal
					refId={id}
					defaultRanges={element}
					onClose={(value) => handleInput(field, value)}
				/>
			);
		}
		if (fieldType === 'Period') {
			return (
				<PeriodModal
					refId={id}
					isArray={isArrayInput}
					defaultPeriods={isEmpty(element) ? [] : elemToArray(element)}
					onClose={(value) => handleInput(field, value)}
				/>
			);
		}

		if (fieldType === 'Quantity' || fieldType === 'Menu') {
			return (
				<MenuModal
					hasQuantity={fieldType === 'Quantity'}
					refId={refId}
					multiple={isArrayInput}
					defaultValues={element}
					onClose={(value) => handleInput(field, value)}
				/>
			);
		}

		if (fieldType === 'ObjectId') {
			if (isEmpty(attributes.ref)) return <></>;
			return (
				<ObjectIdModal
					previousTableField={field}
					refId={refId}
					defaultValues={element}
					onClose={(value) => handleInput(field, value)}
					tableName={attributes.ref}
					multiple={isArrayInput}
					initialFields={elemToArray(attributes.column?.value)}
				/>
			);
		}

		if (fieldType === 'Boolean') {
			return (
				<FlexAlign className='w-full justify-center'>
					<Checkbox
						className='z-30 h-6 w-6'
						onClick={() => handleInput(field, !element)}
						color='white'
						checked={element}
						key={id}
					/>
				</FlexAlign>
			);
		}
		if (fieldType === 'File') {
			return (
				<InputFile
					fieldName={field}
					tableName={tableName}
					mimetypes={attributes.mimetypes}
					className='z-20'
					onDrop={(files) => handleInput(field, files[0])}
				>
					<FlexAlign className='justify-center cursor-pointer min-w-100px h-12 text-xs text-primary font-semibold'>
						{element ? (
							<Media
								/* 								className={mediaCSS}
								 */ file={element}
							/>
						) : (
							`${t('placeholder.upload')}`.toUpperCase()
						)}
					</FlexAlign>
				</InputFile>
			);
		}
		if (fieldType === 'Location') {
			return (
				<InputLocation
					defaultValue={element}
					onChange={(value) => setTempValue(value)}
				/>
			);
		}
		if (fieldType === 'Cron') {
			return (
				<CronModal
					refId={id}
					defaultCron={element}
					onClose={(value) => handleInput(field, value)}
				/>
			);
		}
		const inputType =
			fieldType === 'DateTime' ? 'datetime-local' : fieldType.toLowerCase();
		let defaultElement;

		switch (inputType) {
			case 'datetime-local':
				defaultElement = moment(element)
					.utcOffset(0)
					.format('YYYY-MM-DDTHH:mm');
				break;
			case 'time':
				defaultElement = moment(element).utcOffset(0).format('HH:mm');
				break;
			case 'date':
				defaultElement = moment(element).utcOffset(0).format('YYYY-MM-DD');
				break;
			default:
				defaultElement = element;
		}

		defaultElement = elemToArray(defaultElement);

		if (
			isArrayInput &&
			['String', 'Number', 'Array', 'Date', 'Time', 'DateTime'].includes(
				fieldType
			)
		)
			return (
				<InputArray
					className='z-20 h-12 w-full flex-nowrap'
					key={id}
					inputProps={{
						maxLength: attributes.maxLength,
						minLength: attributes.minLength,
						min: attributes.min,
						max: attributes.max,
						type: inputType,
						autoFocus: true,
					}}
					defaultValue={defaultElement}
					setValue={(value) => setTempValue(value)}
				/>
			);
		return (
			<InputText
				key={id}
				type={inputType}
				autoFocus
				min={attributes.min}
				max={attributes.max}
				minLength={attributes.minLength}
				maxLength={attributes.maxLength}
				defaultValue={defaultElement}
				className='w-full'
				onChange={(e) => {
					const value = parseFloat(e.currentTarget.value);
					if (attributes.max && value > attributes.max)
						e.currentTarget.value = attributes.max.toString();
					if (attributes.min && value < attributes.min)
						e.currentTarget.value = attributes.min.toString();
					setTempValue(
						inputType === 'number'
							? parseFloat(e.currentTarget.value)
							: e.currentTarget.value
					);
				}}
			/>
		);
	};

	return (
		<FlexCol>
			{fields.length > 0 &&
				fields.map(({ field, type }, index) => (
					<FlexAlign key={`${refId}:${index}`} className='mb-3'>
						{visibleInput === field && (
							<ClickableScreen cb={() => handleInput(field)} />
						)}
						<FlexAlign
							className='justify-center w-8 min-w-8 h-full cursor-pointer'
							onClick={() => {
								setFields(fields.filter((_, ind) => ind !== index));
								const element = lodash.cloneDeep(currentElement);
								delete element[field];
								editElement(element);
							}}
						>
							<Cross className='w-2 h-2 min-w-2' color='primary' />
						</FlexAlign>
						<FlexAlign className='w-full'>
							{fieldOptions.length > 1 && (
								<div className='flex-1'>
									<InputSelectionAutomation
										options={{
											_nogroup: fieldOptions,
										}}
										buttonClass='w-full'
										defaultSelected={field}
										onSelection={(newField) => {
											if (fields.map(({ field }) => field).includes(newField))
												return;
											setFields(
												replaceElementInArray(
													fields,
													{ field: newField, type: 'custom' },
													index
												)
											);
											editElement(
												replaceObjectKey(
													currentElement,
													field,
													newField,
													undefined
												)
											);
										}}
										id={`field${refId}${index}`}
									/>
								</div>
							)}
							<div className={`flex-1 ${isEmpty(type) ? 'ml-2' : 'mx-2'}`}>
								<InputSelectionAutomation
									options={{
										_nogroup: [
											{
												label: `${t('input.cron.custom')}`,
												value: 'custom',
											},
											{
												label: `${t('automations.event_element')}`,
												value: 0,
											},
										],
										[previousActionsLabel]: Array(actionIndex)
											.fill(0)
											.map((_, index) => ({
												label: `${t('automations.header.action')} ${index + 1}`,
												value: index + 1,
											})),
									}}
									defaultSelected={type}
									onSelection={(type) => {
										setFields(
											replaceElementInArray(
												fields,
												{ field: fields[index].field, type },
												index
											)
										);
									}}
									buttonClass='w-full'
									id={`iscustom${refId}${index}`}
								/>
							</div>
							{isNotEmpty(type) && (
								<>
									<FlexAlign
										className={`h-9 bg-gray rounded justify-between flex-1 cursor-${
											visibleInput !== field ? 'pointer' : 'default'
										}`}
										onClick={() => setVisibleInput(field)}
										id={`${refId}${field}`}
									>
										{type === 'custom' ? (
											fields.map(({ field }) => field).includes(field) &&
											visibleInput === field ? (
												PrintInput(field)
											) : (
												PrintField(
													currentElement[field]?._constant,
													tableSchema[field],
													{ column: field }
												)
											)
										) : (
											<div
												className='w-full'
												onClick={(e) => e.stopPropagation()}
											>
												<InputSelectionAutomation
													options={{
														_nogroup: getActionOptions(type as number),
													}}
													buttonClass='w-full'
													defaultSelected={currentElement[field]}
													onSelection={(value) =>
														editElement({
															...currentElement,
															[field]: value,
														})
													}
													id={`actionfield${refId}${index}`}
												/>
											</div>
										)}
									</FlexAlign>
								</>
							)}
						</FlexAlign>
					</FlexAlign>
				))}
			<FlexAlign>
				{Object.keys(tableSchema).some(
					(fieldName) =>
						!(
							fields.map(({ field }) => field).includes(fieldName) ||
							Table.defaultFields.includes(fieldName)
						)
				) && (
					<ActionButton
						onClick={() => {
							const newField = Object.keys(tableSchema).find(
								(fieldName) =>
									!(
										fields.map(({ field }) => field).includes(fieldName) ||
										Table.defaultFields.includes(fieldName)
									)
							);
							if (newField)
								setFields((currentFields) => [
									...currentFields,
									{ field: newField, isCustom: true },
								]);
						}}
						className='mb-3'
						Icon={<Plus className='w-2 h-2 min-w-2' />}
					>
						{t('rows.delete_field')}
					</ActionButton>
				)}
				{onPlusElementClick && (
					<ActionButton
						onClick={onPlusElementClick}
						Icon={<Plus className='w-2 h-2 min-w-2' />}
						className='mb-3'
					>
						{t('table.element')}
					</ActionButton>
				)}
			</FlexAlign>
		</FlexCol>
	);
};

export default ActionSelector;
