import { FC, useEffect, useState } from 'react';
import { HTMLAttributes } from 'hoist-non-react-statics/node_modules/@types/react';
import moment from 'moment';
import 'tippy.js/dist/tippy.css';
import 'themes/tippy.css';
import { toast } from 'react-toastify';
import { TLanguage } from 'types/app';
import {
	TCellAddress,
	TElement,
	TField,
	TFile,
	TLocation,
	TMenu,
	TQuantity,
} from 'types/table';
import {
	days,
	elemToArray,
	language,
	locales,
	isNotEmpty,
	trimText,
	translateName,
} from 'utils';
import { FlexAlign, FlexRow, Text } from '.';
import { ArrowSmall, Check, Cross, Flag, Link } from './assets';
import { Chip, Media } from './others';
import cronstrue from 'cronstrue/i18n';
import Table from 'services/table.service';
import { selectorApp } from 'store/app/app.slice';
import { useSelector } from 'react-redux';
import tippy from 'tippy.js';
import { selectorAuth } from 'store/auth/auth.slice';

export const PrintId: FC<
	{
		id?: string;
		className?: string;
		color?: string;
		isDefaultField?: boolean;
	} & HTMLAttributes<HTMLDivElement>
> = ({ id, color, isDefaultField = false, ...props }) => {
	const { t } = useSelector(selectorApp);
	return (
		<Text
			color={color}
			fontSize='xs'
			onClick={() => {
				if (typeof id === 'string' && isNotEmpty(id)) {
					navigator?.clipboard?.writeText(id ?? '');
					toast.success(`${t('toast.id_copied')}`);
				}
			}}
			{...props}
		>
			{typeof id === 'string' && isNotEmpty(id)
				? `...${id.substr(id.length - 5)}`
				: isDefaultField
				? t('placeholder.filled')
				: 'ID'}
		</Text>
	);
};

interface PrintColumnProps {
	fieldName: string;
	fieldSchema: TField;
	headerClick: () => void;
	color: string;
}

export const PrintColumn: FC<PrintColumnProps> = ({
	fieldName,
	color,
	fieldSchema,
	headerClick,
}) => {
	const { schemaSettings, t } = useSelector(selectorApp);
	const [linkedTable, setLinkedTable] = useState<string>();

	const { tables } = useSelector(selectorAuth);

	useEffect(() => {
		(async () => {
			if (
				!Table.defaultFields.includes(fieldName) &&
				Table.getType(fieldSchema.type) === 'ObjectId'
			) {
				const { alias } =
					tables.filter(({ tableName }) => tableName === fieldSchema.ref)[0] ??
					{};
				setLinkedTable(alias?.[language] ?? fieldSchema.ref);
			}
		})();
	}, [fieldSchema]);

	useEffect(() => {
		fieldName &&
			schemaSettings &&
			tippy(`#header${fieldName}`, {
				content: fieldName,
				arrow: false,
				hideOnClick: true,
				placement: 'bottom-start',
				zIndex: 50,
				theme: 'tooltip',
			});
	}, [fieldName]);

	useEffect(() => {
		const template = document.getElementById(`template${linkedTable}`);
		if (!template) return;
		linkedTable &&
			tippy(`#link${linkedTable}`, {
				content: template.innerHTML,
				allowHTML: true,
				arrow: false,
				hideOnClick: true,
				placement: 'bottom-start',
				zIndex: 50,
				theme: 'tooltip',
			});
	}, [linkedTable]);

	return (
		<FlexAlign
			className={
				schemaSettings && !Table.defaultFields.includes(fieldName)
					? 'cursor-pointer'
					: 'cursor-default'
			}
			onClick={(e) => {
				if (schemaSettings && !Table.defaultFields.includes(fieldName)) {
					e.stopPropagation();
					headerClick();
				}
			}}
		>
			<Text
				className='relative'
				fontWeight='semibold'
				color={color}
				id={`header${fieldName}`}
			>
				{`${translateName(fieldName, fieldSchema.alias, t, language)} ${
					fieldSchema?.required ? '*' : ''
				}`}
			</Text>
			{linkedTable && (
				<>
					<Link className='ml-2' id={`link${linkedTable}`} color={color} />
					<div id={`template${linkedTable}`} className='hidden'>
						<FlexRow>
							<Link />
							<Text fontSize='xs' className='mx-1'>
								{t('placeholder.from')}
							</Text>
							<Text fontSize='xs' fontWeight='semibold' className='underline'>
								{linkedTable}
							</Text>
						</FlexRow>
					</div>
				</>
			)}
			{schemaSettings && !Table.defaultFields.includes(fieldName) && (
				<ArrowSmall className='ml-2' color={color} />
			)}
		</FlexAlign>
	);
};

export const PrintField = (
	elem: any,
	attributes: TField,
	address: Partial<TCellAddress>,
	color = 'black',
	mediaCSS?: string
) => {
	// color === 'white' means it's an objectId chip
	if (address.column === '_id' && typeof elem === 'string')
		return <PrintId isDefaultField id={elem} />;

	if (!attributes?.type) return <div />;
	const hasElem = isNotEmpty(elem);
	if (!hasElem) return <></>;
	// transform to array to avoid multiple "if array" conditions
	const elemArray = elemToArray(elem);
	const fieldType = Table.getType(attributes.type);
	const media =
		mediaCSS ??
		`rounded mx-1 object-contain ${
			fieldType === 'ObjectId' &&
			Table.getType(attributes.column?.schema?.type) === 'File'
				? 'h-5 w-5 min-w-15px'
				: 'h-35px w-35px min-w-35px'
		}`;
	switch (fieldType) {
		case 'JSX':
			return <>{elemArray[0]}</>;
		case 'DateTime':
		case 'Date':
			return (
				<FlexRow>
					{elemArray.slice(0, 2).map((date: Date, index: number) => (
						<Text
							key={`${date.toString()}:${index}:${JSON.stringify(address)}`}
							fontSize='xs'
							color={color}
							className={color !== 'white' ? 'mx-1' : ''}
						>
							{(Table.defaultFields.includes(address.column ?? '')
								? moment(date)
								: moment(date).utcOffset(0)
							).format(`YYYY-MM-DD${fieldType === 'DateTime' ? ' HH:mm' : ''}`)}
						</Text>
					))}
					{elemArray.length > 2 && (
						<Text fontSize='xs' color={color}>
							...+{elemArray.length - 2}
						</Text>
					)}
				</FlexRow>
			);
		case 'Quantity':
		case 'Menu':
			return (
				<FlexAlign>
					{elemArray
						.slice(0, 2)
						.map((element: TQuantity | TMenu, index: number) => {
							return (
								<FlexAlign
									className='mr-2'
									key={`${element}${index}:${JSON.stringify(address)}`}
								>
									<Text fontSize='xs'>{`${
										element.item
									} ${element.price?.toFixed(2)}€`}</Text>
									{'quantity' in element && (
										<>
											<Cross color={color} className='mx-1 w-2 h-2' />
											<Text fontSize='xs'>{element.quantity}</Text>
										</>
									)}
								</FlexAlign>
							);
						})}
					{elemArray.length > 2 && (
						<Text fontSize='xs' color={color}>
							...+{elemArray.length - 2}
						</Text>
					)}
				</FlexAlign>
			);
		case 'ObjectId':
			return (
				<FlexAlign>
					{elemArray
						.slice(0, 2)
						.map((elem: TElement | string, index: number) => {
							const element: TElement =
								typeof elem === 'string'
									? elem.startsWith('{') && elem.endsWith('}')
										? JSON.parse(elem)
										: { _id: elem }
									: elem;
							const hasColumn = isNotEmpty(element?.[attributes.column?.value]);
							return (
								<Chip
									key={`${element._id}:${index}:${JSON.stringify(address)}`}
									isRecursive={color === 'white'}
									className={color !== 'white' ? 'mx-1' : ''}
									isFile={
										hasColumn &&
										Table.getType(attributes.column?.schema?.type) === 'File'
									}
								>
									<FlexAlign>
										{PrintField(
											hasColumn ? (
												element?.[attributes.column?.value]
											) : (
												<PrintId
													isDefaultField={Table.defaultFields.includes(
														attributes.column?.value
													)}
													className='pr-2'
													id={element._id}
													color='white'
												/>
											),
											hasColumn ? attributes.column?.schema : { type: 'JSX' },
											address,
											'white',
											media
										)}
									</FlexAlign>
								</Chip>
							);
						})}
					{elemArray.length > 2 && (
						<Text fontSize='xs' color={color}>
							...+{elemArray.length - 2}
						</Text>
					)}
				</FlexAlign>
			);
		case 'Boolean':
			return (
				<FlexRow className='w-full justify-center'>
					{elemArray.map((elem: boolean, index: number) =>
						elem ? (
							<Check
								key={`${elem}:${JSON.stringify(address)},${index}`}
								className={`w-4 h-4 min-w-2 ${color !== 'white' ? 'mx-1' : ''}`}
								color='primary'
							/>
						) : (
							<Cross
								key={`${elem}:${JSON.stringify(address)},${index}`}
								className={`w-4 h-4 min-w-2 ${color !== 'white' ? 'mx-1' : ''}`}
								color='red'
							/>
						)
					)}
				</FlexRow>
			);
		case 'File':
			return (
				<FlexAlign>
					{elemArray.slice(0, 2).map((file: TFile, index: number) => (
						<Media
							key={`${file.url}:${JSON.stringify(address)},${index}`}
							className={
								file.mimetype?.startsWith('image') ||
								file.mimetype?.startsWith('video')
									? media
									: color !== 'white'
									? 'mx-1'
									: ''
							}
							file={file}
						/>
					))}
					{elemArray.length > 2 && (
						<Text fontSize='xs' color={color}>
							...+{elemArray.length - 2}
						</Text>
					)}
				</FlexAlign>
			);
		case 'Location':
			return (
				<FlexRow>
					{elemArray
						.slice(0, 1)
						.map(({ lat, lng }: TLocation, index: number) => (
							<Text
								key={`${lat},${lng}:${JSON.stringify(address)},${index}`}
								fontSize='xs'
								color={color}
								className={color !== 'white' ? 'mx-1' : ''}
							>{`LAT: ${lat}, LNG: ${lng} `}</Text>
						))}
					{elemArray.length > 1 && (
						<Text fontSize='xs' color={color}>
							...+{elemArray.length - 1}
						</Text>
					)}
				</FlexRow>
			);
		case 'TimeRange':
			const shownRanges = Object.entries(elemArray[0]).filter(
				([day, ranges]) =>
					(ranges as string[]).length > 0 && days.includes(day as any)
			);
			return (
				<FlexRow>
					{shownRanges.slice(0, 2).map(([day, ranges], index) => (
						<Text
							key={`${day},${ranges}:${JSON.stringify(address)},${index}`}
							fontSize='xs'
							color={color}
							className={color !== 'white' ? 'mx-1' : ''}
						>
							{`${moment()
								.day(days.indexOf(day as any))
								.format('ddd')}: ${(ranges as string[]).toString()} 
							`}
						</Text>
					))}
					{shownRanges.length > 2 && (
						<Text fontSize='xs' color={color}>
							...+{shownRanges.length - 2}
						</Text>
					)}
				</FlexRow>
			);
		//@ts-ignore
		case 'String':
			if (attributes.translatable) {
				return (
					<FlexRow>
						{elemArray.slice(0, 2).map((elem, index: number) => {
							let currentLanguage: TLanguage = language;
							let printedElement = attributes.enum
								? attributes.enum[language]?.elem
								: elem[language];
							if (!printedElement) {
								currentLanguage =
									(Object.keys(locales).find(
										(lang) =>
											(attributes.enum
												? attributes.enum[lang]?.[elem]
												: elem[lang as TLanguage]) !== undefined
									) as TLanguage) ?? 'en';
								printedElement = attributes.enum
									? attributes.enum[currentLanguage]?.[elem]
									: elem[currentLanguage];
							}
							return printedElement ? (
								<FlexAlign
									className={`justify-between min-w-full ${
										color !== 'white' ? 'mx-1' : ''
									}`}
									key={`${JSON.stringify(elem)}:${JSON.stringify(
										address
									)},${index}`}
								>
									<Text fontSize='xs' color={color}>
										{trimText(printedElement ?? '', 100)}
									</Text>
									<Flag className='ml-6' lang={currentLanguage} />
								</FlexAlign>
							) : (
								<></>
							);
						})}
						{elemArray.length > 2 && (
							<Text fontSize='xs' color={color}>
								...+{elemArray.length - 2}
							</Text>
						)}
					</FlexRow>
				);
			}
		// eslint-disable-next-line no-fallthrough
		default:
			return (
				<FlexRow>
					{elemArray.slice(0, 2).map((elem: any, index: number) => (
						<Text
							key={`${JSON.stringify(elem)}:${JSON.stringify(
								address
							)},${index}`}
							fontSize='xs'
							color={color}
							className={color !== 'white' ? 'mx-1' : ''}
						>
							{fieldType === 'Cron'
								? cronstrue.toString(elem, { locale: language })
								: trimText(elem.toString(), 100)}
						</Text>
					))}
					{elemArray.length > 2 && (
						<Text fontSize='xs' color={color}>
							...+{elemArray.length - 2}
						</Text>
					)}
				</FlexRow>
			);
	}
};
