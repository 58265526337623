import { FlexAlign } from 'components/basic';
import { forwardRef, HTMLAttributes } from 'react';
import { TCellAddress } from 'types/table';

interface TCellProps extends HTMLAttributes<HTMLDivElement> {
	address: TCellAddress;
	bgColor: 'gray' | 'gray-dark';
}

const TableIndex = forwardRef<HTMLDivElement, TCellProps>(
	({ className = '', address, bgColor, children, ...props }, ref) => {
		return (
			<div
				ref={ref}
				onContextMenu={(e) => e.preventDefault()}
				id={`cell:${JSON.stringify(address)}`}
				className={`sticky left-0 overflow-hidden cursor-pointer z-30 bg-${bgColor}`}
			>
				<FlexAlign
					className={`justify-center w-12 whitespace-nowrap text-xs font-bold h-12 ${className}`}
					{...props}
				>
					{children}
				</FlexAlign>
			</div>
		);
	}
);

export default TableIndex;
