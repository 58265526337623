import { FC, HTMLAttributes, useState } from 'react';
import { FlexAlign, Text } from 'components/basic';
import { TOption, TSelectable, TTableOptions } from 'types/app';
import { Cross, Filter, Group, Hide, Sort } from 'components/basic/assets';

import TableOptionModal from 'components/modal/table-option.modal';
import { selectorApp } from 'store/app/app.slice';
import { useSelector } from 'react-redux';

interface TableOptionProps extends TSelectable {
	id: string;
	onCancel: () => void;
	type: 'hide' | 'filter' | 'sort' | 'group';
	onModalSubmit: (value: TTableOptions[TableOptionProps['type']]) => void;
	options: TOption[];
	defaultOptions: any;
}

const TableOption: FC<HTMLAttributes<HTMLDivElement> & TableOptionProps> = ({
	type,
	selected,
	onCancel,
	className = '',
	onModalSubmit,
	options,
	defaultOptions,
	id,
	...props
}) => {
	const { t } = useSelector(selectorApp);

	const [visibleModal, setVisibleModal] = useState<boolean>(false);

	const getIcon = (
		optionType: 'hide' | 'filter' | 'sort' | 'group'
	): JSX.Element => {
		const color = selected ? 'white' : 'black-light';
		const className = 'mr-1';
		switch (optionType) {
			case 'filter':
				return <Filter color={color} className={className} />;
			case 'group':
				return <Group color={color} className={className} />;
			case 'hide':
				return <Hide color={color} className={className} />;
			case 'sort':
				return <Sort color={color} className={className} />;
		}
	};

	return (
		<>
			<FlexAlign
				className={`justify-between h-6 px-2 z-20 rounded cursor-pointer ${
					selected
						? 'text-white bg-orange'
						: 'text-black border-2 border-gray hover:bg-orange-light'
				} ${className}`}
				onClick={() => setVisibleModal(true)}
				id={id}
				{...props}
			>
				{getIcon(type)}
				<Text
					color={selected ? 'white' : 'black'}
					fontWeight={selected ? 'semibold' : 'normal'}
					fontSize='xs'
				>
					{t(`table.option.${type}`)}
				</Text>
				{selected && (
					<Cross
						color='white'
						className='w-2.5 h-2.5 ml-2'
						onClick={(e) => {
							e.stopPropagation();
							onCancel();
						}}
					/>
				)}
			</FlexAlign>
			{visibleModal && (
				<TableOptionModal
					tableOptionId={id}
					type={type}
					onClose={(newOptions) => {
						onModalSubmit(newOptions);
						setVisibleModal(false);
					}}
					defaultOptions={defaultOptions}
					options={options}
				/>
			)}
		</>
	);
};

export default TableOption;
