import { FlexAlign, FlexCol } from 'components/basic';
import { FC, useEffect, useState, Fragment } from 'react';

import { TAtomicFilter, TFilterMode, TNestedFilter, TOption } from 'types/app';
import lodash from 'lodash';
import {
	elemToArray,
	isEmpty,
	isAtomicFilter,
	replaceElementInArray,
	getFilterModes,
} from 'utils';
import { AddFilter } from './filter-input.style';
import AtomicFilter from './atomic-filter-input.component';
import { selectorApp } from 'store/app/app.slice';
import { useSelector } from 'react-redux';

type InputFilterProps = {
	options: TOption[];
	handleClose?: () => void;
	setValue: (value: TAtomicFilter | TNestedFilter) => void;
	defaultFilter: TAtomicFilter | TNestedFilter;
	zIndex?: number;
	automations?: {
		getActionOptions?: (index: number) => TOption[];
		actionIndex?: number;
	};
	id: string;
};

const InputFilter: FC<InputFilterProps> = ({
	options,
	handleClose,
	setValue,
	defaultFilter,
	automations,
	id,
}) => {
	const { t } = useSelector(selectorApp);

	const [filterValue, setFilterValue] = useState<TAtomicFilter | TNestedFilter>(
		defaultFilter
	);

	const randomFilter = (): TAtomicFilter => ({
		column: options[0]?.value,
		mode: getFilterModes(options[0]?.value, t)[0]?.value as TFilterMode,
		value: undefined,
	});

	useEffect(() => {
		if (isEmpty(defaultFilter)) setFilterValue(randomFilter());
		else setFilterValue(defaultFilter);
	}, [defaultFilter]);

	if (isEmpty(filterValue)) return <></>;

	const editFilter = (position: string, value: any) => {
		let newOptions = lodash.cloneDeep(filterValue);
		if (isAtomicFilter(newOptions)) {
			(newOptions as TAtomicFilter)[position as keyof TAtomicFilter] = value;
		} else {
			newOptions = lodash.set(newOptions, position, value);
		}
		if (!lodash.isEqual(filterValue, newOptions)) {
			setFilterValue(newOptions);
			setValue(newOptions);
		}
	};

	const deleteFilter = (position: string) => {
		const filterArray = position.split('.');
		// returns array index and removes from filterArray
		const index = parseInt(filterArray.splice(-1)[0]);
		if (index === -1) {
			setValue({});
			return handleClose && handleClose();
		}
		let newOptions = lodash.cloneDeep(filterValue);
		const filterPosition = filterArray.join('.');
		const newFilters =
			(lodash.get(newOptions, filterPosition) as TAtomicFilter[])?.filter(
				(_, ind) => ind !== index
			) ?? [];
		if (newFilters.length === 0)
			newOptions = lodash.omit(newOptions, [filterPosition]) as TNestedFilter;
		else newOptions = lodash.set(newOptions, filterPosition, newFilters);
		if (isEmpty(newOptions) && handleClose) {
			handleClose();
			setValue({});
		} else {
			setFilterValue(newOptions);
			setValue(newOptions);
		}
	};

	return (
		<FlexCol>
			{isAtomicFilter(filterValue) ? (
				<>
					<AtomicFilter
						filter={filterValue as TAtomicFilter}
						position='-1'
						automations={automations}
						editFilter={editFilter}
						onDelete={deleteFilter}
						options={options}
						refId={id}
					/>
					<FlexAlign>
						<AddFilter
							onClick={() =>
								// put single filter in AND with a random filter
								setFilterValue({
									_and: [
										(filterValue as TAtomicFilter) ?? randomFilter(),
										randomFilter(),
									],
								})
							}
						>
							{t('placeholder.and')}
						</AddFilter>
						<AddFilter
							className='ml-2'
							onClick={() =>
								// put single filter in OR with a random filter
								setFilterValue({
									_or: [
										(filterValue as TAtomicFilter) ?? randomFilter(),
										randomFilter(),
									],
								})
							}
						>
							{t('placeholder.or')}
						</AddFilter>
					</FlexAlign>
				</>
			) : (
				<>
					{'_and' in filterValue && (
						<>
							{filterValue._and?.map((filter, index) => (
								<AtomicFilter
									key={`filter:_and.${index}`}
									filter={filter as TAtomicFilter}
									position={`_and.${index}`}
									automations={automations}
									editFilter={editFilter}
									onDelete={deleteFilter}
									options={options}
									refId={id}
								/>
							))}
							<FlexAlign>
								<AddFilter
									onClick={() =>
										setFilterValue({
											_and: [...(filterValue._and ?? []), randomFilter()],
										})
									}
								>
									{t('placeholder.and')}
								</AddFilter>
								<AddFilter
									className='ml-2'
									onClick={() =>
										setFilterValue({
											_or: [
												{
													_and: ((filterValue as TNestedFilter)._and ??
														[]) as TAtomicFilter[],
												},
												randomFilter(),
											],
										})
									}
								>
									{t('placeholder.or')}
								</AddFilter>
							</FlexAlign>
						</>
					)}
					{'_or' in filterValue &&
						Array.isArray(filterValue._or) &&
						filterValue._or.map((filter, index) => (
							<Fragment key={`filter:_or.${index}`}>
								{isAtomicFilter(filter) ? (
									<AtomicFilter
										filter={filter as TAtomicFilter}
										position={`_or.${index}`}
										automations={automations}
										editFilter={editFilter}
										onDelete={deleteFilter}
										options={options}
										refId={id}
									/>
								) : (
									(filter as TNestedFilter)._and?.map((filt, ind) => (
										<AtomicFilter
											key={`filter:_or.${index}._and.${ind}`}
											filter={filt as TAtomicFilter}
											position={`_or.${index}._and.${ind}`}
											automations={automations}
											editFilter={editFilter}
											onDelete={deleteFilter}
											options={options}
											refId={id}
										/>
									))
								)}
								<FlexAlign>
									<AddFilter
										onClick={() => {
											const newFilter = {
												_and: [
													...(isAtomicFilter(filter)
														? elemToArray(filter)
														: (filter as TNestedFilter)._and ?? []),
													randomFilter(),
												],
											} as TNestedFilter;
											setFilterValue((opts) => ({
												_or: replaceElementInArray(
													(opts as TNestedFilter)._or ?? [],
													newFilter as any,
													index
												),
											}));
										}}
									>
										{t('placeholder.and')}
									</AddFilter>
									{index + 1 === filterValue._or?.length && (
										<AddFilter
											className='ml-2'
											onClick={() =>
												setFilterValue({
													_or: [...(filterValue._or ?? []), randomFilter()],
												})
											}
										>
											{t('placeholder.or')}
										</AddFilter>
									)}
								</FlexAlign>
							</Fragment>
						))}
				</>
			)}
		</FlexCol>
	);
};

export default InputFilter;
