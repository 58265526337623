import { FC } from 'react';
import { FlexAlign, FlexCol, FlexRow, Label } from 'components/basic';
import { Line } from 'components/basic/others';

import { TBrand } from 'types/app';
import { ListElement, TableColumn } from './preview.style';
import { selectorApp } from 'store/app/app.slice';
import { useSelector } from 'react-redux';

const Preview: FC<{ brand: TBrand }> = ({ brand: { color } }) => {
	const { t } = useSelector(selectorApp);
	const primaryColor = `rgb(${color.r},${color.g},${color.b})`;
	return (
		<FlexCol>
			<Label>{t('settings.preview')}</Label>
			<FlexCol className='rounded px-4 py-3 bg-gray'>
				<FlexAlign className='justify-between mb-3'>
					<div
						className='w-6 h-6 rounded'
						style={{ backgroundColor: primaryColor }}
					/>
					<Line style={{ borderColor: primaryColor }} width='16' />
					<FlexAlign>
						<FlexCol className='items-end mr-2'>
							<Line width='12' className='mb-2' />
							<Line width='8' />
						</FlexCol>
						<div
							className='w-6 h-6 rounded-circle'
							style={{
								backgroundColor: primaryColor,
							}}
						/>
					</FlexAlign>
				</FlexAlign>
				<FlexRow>
					<FlexCol className='w-1/4 mr-3'>
						<FlexCol className='py-3 bg-white rounded mb-3'>
							<ListElement />
							<ListElement color={primaryColor} selected />
							<ListElement />
							<ListElement />
						</FlexCol>
						<FlexCol className='py-3 bg-white rounded mb-3'>
							<ListElement />
						</FlexCol>
						<FlexCol className='py-3 bg-white rounded'>
							<ListElement />
							<ListElement />
							<ListElement />
							<ListElement />
						</FlexCol>
					</FlexCol>
					<FlexCol className='w-3/4'>
						<FlexRow
							className='rounded-t w-content'
							style={{
								backgroundColor: primaryColor,
							}}
						>
							<FlexRow className='bg-white p-3 rounded-t'>
								<Line width='10' />
							</FlexRow>
							<FlexRow className='p-3 rounded'>
								<Line width='10' color='white' />
							</FlexRow>
						</FlexRow>
						<FlexRow className='bg-white rounded-b rounded-tr w-auto p-4 h-full'>
							<FlexRow className='rounded overflow-hidden'>
								<TableColumn color={primaryColor} />
								<TableColumn color={primaryColor} />
								<TableColumn color={primaryColor} />
								<TableColumn color={primaryColor} />
								<TableColumn color={primaryColor} />
								<TableColumn color={primaryColor} />
							</FlexRow>
						</FlexRow>
					</FlexCol>
				</FlexRow>
			</FlexCol>
		</FlexCol>
	);
};

export default Preview;
