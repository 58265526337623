import { FC, HTMLAttributes, SetStateAction, Dispatch } from 'react';
import { FlexAlign, FlexCol, Text } from 'components/basic';
import { ArrowSmall, Delete } from 'components/basic/assets';
import { ActionButton } from 'components/basic/buttons';
import { InputSelection } from 'components/basic/inputs';

import { TSelectionProps } from 'types/app';
import { selectorApp } from 'store/app/app.slice';
import { useSelector } from 'react-redux';

export const InputSelectionAutomation: FC<
	HTMLAttributes<HTMLDivElement> & TSelectionProps & { isFirst?: boolean }
> = ({ isFirst, buttonClass = '', ...props }) => (
	<InputSelection
		{...props}
		optionsListClass='bg-white'
		hasBorder
		buttonClass={`bg-white py-2 px-3 text-xs ${
			isFirst ? 'text-primary font-semibold' : 'text-black'
		} ${buttonClass}`}
		labelClass='px-2 mt-3'
		optionClass='py-2 px-3 text-xs text-black'
	/>
);

export const SectionConnector: FC<HTMLAttributes<HTMLDivElement>> = ({
	className,
	...props
}) => (
	<div
		className={`h-4 min-h-4 w-1/2 border-r border-primary ${className}`}
		{...props}
	/>
);

export const SectionWrapper: FC<
	HTMLAttributes<HTMLDivElement> & {
		setOpen: Dispatch<
			SetStateAction<'event' | 'condition' | number | undefined>
		>;
		onDelete?: () => void;
		open: boolean;
		section: 'event' | 'condition' | number;
	}
> = ({
	children,
	className = '',
	setOpen,
	open,
	section,
	onDelete,
	onClick,
	...props
}) => {
	const { t } = useSelector(selectorApp);
	return (
		<FlexCol
			{...props}
			className={`border-2 border-primary rounded-small overflow-hidden transition-maxh duration-quick ${
				open ? 'max-h-full bg-white' : 'min-h-14 max-h-14 bg-primary'
			} ${className}`}
		>
			<FlexAlign
				className='p-4 cursor-pointer justify-between'
				onClick={(e) => {
					setOpen((op) => (op === section ? undefined : section));
					onClick && onClick(e);
				}}
			>
				<FlexAlign>
					<ArrowSmall
						className={`mr-2 transition-transform duration-quick transform ${
							open ? 'rotate-0' : 'rotate-180'
						}`}
						color={open ? 'primary' : 'white'}
					/>
					<Text
						color={open ? 'primary' : 'white'}
						fontSize='sm'
						fontWeight='semibold'
					>
						{`${t(
							`automations.header.${
								typeof section === 'number' ? 'action' : section
							}`
						)} ${typeof section === 'number' ? section + 1 : ''}`.toUpperCase()}
					</Text>
				</FlexAlign>
				{onDelete && (
					<ActionButton
						Icon={<Delete color={open ? 'primary' : 'white'} />}
						color={open ? 'primary' : 'white'}
						onClick={onDelete}
					>
						{t('placeholder.remove')}
					</ActionButton>
				)}
			</FlexAlign>
			<FlexCol className='px-4 pb-4 overflow-y-auto max-h-80 min-h-18'>
				{open && children}
			</FlexCol>
		</FlexCol>
	);
};
