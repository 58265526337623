import { FC } from 'react';
import { FlexAlign, FlexCol, Text } from 'components/basic';
import { FilledButton, OutlinedButton } from 'components/basic/buttons';
import { CommonModal } from 'components/basic/others';
import { TModalOpt } from 'types/app';
import { selectorApp } from 'store/app/app.slice';
import { useSelector } from 'react-redux';

interface ConfirmModalProps {
	onConfirm: () => void;
	explanation: string | any;
	confirmText?: string | any;
	cancelText?: string | any;
}

const ConfirmModal: FC<TModalOpt & ConfirmModalProps> = ({
	onHide,
	onConfirm,
	header,
	explanation,
	confirmText,
	cancelText,
}) => {
	const { t } = useSelector(selectorApp);
	return (
		<CommonModal
			className='overflow-auto min-w-500px'
			header={header}
			onHide={onHide}
		>
			<FlexCol>
				<Text>{explanation}</Text>
				<FlexAlign className='mt-5'>
					<OutlinedButton onClick={onHide} className='max-w-1/2 mr-1'>
						{cancelText ?? t('placeholder.cancel')}
					</OutlinedButton>
					<FilledButton
						onClick={() => {
							onConfirm();
							onHide();
						}}
						className='max-w-1/2 ml-1'
					>
						{confirmText ?? t('placeholder.confirm')}
					</FilledButton>
				</FlexAlign>
			</FlexCol>
		</CommonModal>
	);
};

export default ConfirmModal;
