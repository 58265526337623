import { FC, useState, useEffect } from 'react';
import { Delete, Duplicate, Plus, PlusView } from 'components/basic/assets';
import { Text, FlexRow, FlexCol, FlexAlign } from 'components/basic';
import {
	TDeleteOptions,
	TGeoFeatures,
	TTableOperation,
	TTableOptions,
} from 'types/app';
import {
	TEditedCell,
	TCellAddress,
	TElement,
	TStructure,
	TGroupedElements,
} from 'types/table';
import { useSelector } from 'react-redux';
import { selectorApp, setPreviewSession } from 'store/app/app.slice';
import { toast } from 'react-toastify';
import { createFilterOptions, isNotEmpty, objectFilter } from 'utils';
import { ActionButton } from 'components/basic/buttons';
import TableOption from '../../hoc/table-option';
import {
	ClickableScreen,
	ScreenLoader,
	SmallLoader,
} from 'components/basic/others';
import { InputCheckbox, InputFile } from 'components/basic/inputs';
import GridBody from './grid-body';
import { PrintField } from 'components/basic/print';
import GridHeader from './grid-header';
import Table from 'services/table.service';
import { useAppDispatch } from 'store';
import { selectorAuth } from 'store/auth/auth.slice';
import InfiniteScroll from 'react-infinite-scroll-component';
import TableIndex from 'components/hoc/table-index';

interface TableProps {
	table: TStructure;
	elements: TElement[] | TGroupedElements;
	startOperation: (operationOpt: TTableOperation) => void;
	setTableOptions: (options: TTableOptions) => void;
	tableOptions: TTableOptions;
	scrollOptions: {
		defaultLoadElements: () => void;
		hasNextPage: boolean;
	};
	uploadGeojson?: (features: TGeoFeatures, table: TStructure) => void;
	uploading?: boolean;
}

const GridView: FC<TableProps> = ({
	table,
	elements,
	startOperation,
	setTableOptions,
	tableOptions,
	uploadGeojson,
	scrollOptions: { defaultLoadElements, hasNextPage },
	uploading,
}) => {
	const { tableName, tableSchema, softDelete } = table;
	const { insertSession, visibleDefaultFields } = useSelector(selectorApp);
	const { user } = useSelector(selectorAuth);
	const { t } = useSelector(selectorApp);
	const dispatch = useAppDispatch();

	const [editCell, setEditCell] = useState<TCellAddress>();

	const [selectedCell, setSelectedCell] = useState<
		TCellAddress | TCellAddress[]
	>();

	const [hoveredRow, setHoveredRow] = useState<string>();

	const [selectedTable, setSelectedTable] = useState<string>();

	const [fields, setFields] = useState<string[]>([]);

	const [columnWidths, setColumnWidths] = useState<{
		[column: string]: number;
	}>({});

	useEffect(() => {
		if (!tableSchema) return setFields([]);
		const newFields = Object.keys(tableSchema).filter(
			(field) =>
				!Object.keys(tableOptions?.hide ?? {}).includes(field) &&
				(!Table.defaultFields.includes(field) ||
					visibleDefaultFields?.includes(field))
		);

		setFields(newFields);
	}, [table, tableOptions]);

	const duplicateElements = () => {
		if (!Array.isArray(selectedCell)) return;
		const currentElements: TElement[] = Table.getElementsArray(
			elements,
			tableOptions
		);
		for (const { row } of selectedCell) {
			const rawElement = currentElements.find(({ _id }) => _id === row);
			if (!rawElement) return;
			const duplicateElement = objectFilter(
				rawElement,
				([fieldName]) =>
					Object.keys(tableSchema).includes(fieldName) &&
					!Table.defaultFields.includes(fieldName)
			);
			startOperation({
				table,
				operation: 'insertRow',
				options: { element: duplicateElement },
			});
		}
	};

	const deleteElements = () => {
		let options: TDeleteOptions = {};
		if (selectedTable === tableName) {
			options.deleteTable = true;
			startOperation({ operation: 'deleteRow', table, options });
		} else {
			if (!selectedCell) return;
			if (Array.isArray(selectedCell)) {
				options.deleteRows = selectedCell
					.map(({ row }) => row)
					.filter((id) => isNotEmpty(id));
				startOperation({ operation: 'deleteRow', table, options });
			} else {
				if (tableSchema[selectedCell.column]?.immutable) return;
				(options as TEditedCell) = {
					address: {
						column: selectedCell.column,
						row: selectedCell.row,
						tableName: tableName,
						group: selectedCell.group,
					},
					update: {
						$unset: {
							[selectedCell.column]: (elements as TElement[]).find(
								({ _id }) => selectedCell.row === _id
							)?.[selectedCell.column],
						},
					},
				};
				startOperation({ operation: 'editRow', table, options });
			}
		}
		setSelectedCell(undefined);
		setSelectedTable(undefined);
	};

	return (
		<>
			{/* TODO: Z-Index adjustments or click outside hook */}
			{!editCell && (insertSession || selectedCell) && (
				<ClickableScreen
					cb={() => {
						if (insertSession) {
							startOperation({ table, operation: 'insertRow' });
							setEditCell(undefined);
						} else if (selectedCell) {
							setSelectedCell(undefined);
						}
					}}
				/>
			)}
			{uploading && <ScreenLoader />}
			<FlexRow
				className='p-4 rounded-b bg-white justify-between'
				style={{ zIndex: 32 }}
			>
				<FlexRow>
					<TableOption
						id='tableoptionhide'
						type='hide'
						onCancel={() => setTableOptions({ ...tableOptions, hide: {} })}
						selected={isNotEmpty(tableOptions.hide)}
						className='mr-2'
						onModalSubmit={(hide) =>
							setTableOptions({
								...tableOptions,
								hide: hide as TTableOptions['hide'],
							})
						}
						options={createFilterOptions(tableSchema, t, visibleDefaultFields)}
						defaultOptions={tableOptions.hide}
					/>
					<TableOption
						id='tableoptionfilter'
						className='mr-2'
						selected={isNotEmpty(tableOptions.filter)}
						onCancel={() => setTableOptions({ ...tableOptions, filter: {} })}
						type='filter'
						onModalSubmit={(filter) =>
							setTableOptions({
								...tableOptions,
								filter: filter as TTableOptions['filter'],
							})
						}
						options={createFilterOptions(
							tableSchema,
							t,
							visibleDefaultFields,
							true
						)}
						defaultOptions={tableOptions.filter}
					/>
					<TableOption
						id='tableoptiongroup'
						className='mr-2'
						selected={isNotEmpty(tableOptions.group)}
						onCancel={() =>
							setTableOptions({ ...tableOptions, group: undefined })
						}
						type='group'
						onModalSubmit={(group) =>
							setTableOptions({
								...tableOptions,
								group: group as TTableOptions['group'],
							})
						}
						options={createFilterOptions(tableSchema, t, visibleDefaultFields)}
						defaultOptions={tableOptions.group}
					/>
					<TableOption
						className='mr-2'
						id='tableoptionsort'
						selected={isNotEmpty(tableOptions.sort)}
						onCancel={() => setTableOptions({ ...tableOptions, sort: {} })}
						type='sort'
						onModalSubmit={(sort) =>
							setTableOptions({
								...tableOptions,
								sort: sort as TTableOptions['sort'],
							})
						}
						options={createFilterOptions(tableSchema, t, visibleDefaultFields)}
						defaultOptions={tableOptions.sort}
					/>
					<ActionButton
						onClick={(e) => {
							e.stopPropagation();
							dispatch(
								setPreviewSession([
									{
										table,
										element: Table.newElement(user ?? {}),
										previousTableField: null,
									},
								])
							);
						}}
						className='mr-2'
						Icon={<Plus color='primary' className='w-13px h-13px min-w-13px' />}
					>
						{`${t('placeholder.add')} ${t('table.row')}`}
					</ActionButton>
					{softDelete && (
						<FlexAlign
							className={`px-2 rounded bg-${
								tableOptions.deleted ? 'primary' : 'white'
							}`}
						>
							<InputCheckbox
								text={
									<Text
										fontSize='xs'
										color={tableOptions.deleted ? 'white' : 'black'}
									>
										{t('table.option.show_deleted')}
									</Text>
								}
								color={tableOptions.deleted ? 'white' : 'gray'}
								checked={tableOptions.deleted ?? false}
								setValue={(deleted) =>
									setTableOptions({ ...tableOptions, deleted })
								}
							/>
						</FlexAlign>
					)}
				</FlexRow>
				<FlexAlign>
					{(!!selectedCell || selectedTable === tableName) && (
						<FlexAlign>
							{Array.isArray(selectedCell) && (
								<ActionButton
									Icon={<Duplicate fill='primary' />}
									className='z-20 mr-4'
									onClick={duplicateElements}
								>
									{`${t('placeholder.duplicate')} ${t(
										`rows.delete_${
											selectedCell.length > 1 ? 'multiple' : 'one'
										}`
									)}`}
								</ActionButton>
							)}
							<ActionButton
								Icon={<Delete fill='primary' />}
								className='z-20 mr-4'
								onClick={deleteElements}
							>
								{`${t('placeholder.delete')} ${t(
									`rows.delete_${
										selectedTable === tableName
											? 'all'
											: Array.isArray(selectedCell)
											? selectedCell.length > 1
												? 'multiple'
												: 'one'
											: 'field'
									}`
								)
									?.toString()
									.toLowerCase()}`}
							</ActionButton>
						</FlexAlign>
					)}
					{Object.values(tableSchema).filter(
						({ type }) => type[0] === 'Location'
					).length > 0 &&
						uploadGeojson && (
							<InputFile
								mimetypes={['.json', '.geojson']}
								uploadToBackend={false}
								onDrop={(files) => {
									const reader = new FileReader();
									reader.onload = async (e) => {
										const { features } = JSON.parse(e.target?.result as string);
										if (!features)
											return toast.error(`${t('toast_not_valid_geojson')}`);
										uploadGeojson(features, table);
									};
									reader.readAsText((files as any[])[0]);
								}}
							>
								<ActionButton
									Icon={<PlusView fill='primary' className='w-13px h-13px' />}
									className='z-20'
								>
									{`${t('placeholder.import')} Geojson`}
								</ActionButton>
							</InputFile>
						)}
				</FlexAlign>
			</FlexRow>
			<FlexCol
				id='gridscrollable'
				className='ml-3 mr-12 max-h-full-grid overflow-auto'
			>
				<GridHeader
					setSelectedCell={setSelectedCell}
					setSelectedTable={setSelectedTable}
					selectedTable={selectedTable}
					table={table}
					startOperation={startOperation}
					fields={fields}
					setColumnWidths={setColumnWidths}
				/>
				<InfiniteScroll
					scrollableTarget='gridscrollable'
					dataLength={Table.getElementsArray(elements, tableOptions).length}
					next={defaultLoadElements}
					style={{
						overflow: 'visible',
						display: 'flex',
						flexDirection: 'column',
					}}
					hasMore={hasNextPage}
					scrollThreshold={0.8}
					loader={
						<TableIndex
							address={{
								tableName,
								row: 'loader',
								column: 'load',
								group: '',
							}}
							bgColor='gray'
							className='overflow-hidden text-primary bg-primary-15'
						>
							<SmallLoader />
						</TableIndex>
					}
				>
					{Array.isArray(elements) ? (
						<GridBody
							setHoveredRow={setHoveredRow}
							hoveredRow={hoveredRow}
							editCell={editCell}
							setEditCell={setEditCell}
							selectedCell={selectedCell}
							setSelectedCell={setSelectedCell}
							group=''
							elements={elements}
							columnWidths={columnWidths}
							fields={fields}
							selectedTable={selectedTable}
							setSelectedTable={setSelectedTable}
							startOperation={startOperation}
							table={table}
						/>
					) : (
						tableOptions?.group &&
						Object.entries(elements).map(
							([groupValue, groupElements], index) => (
								<FlexCol key={`gridgroup:${index}`}>
									<FlexAlign className='my-4 ml-4'>
										{PrintField(
											groupValue,
											tableSchema[tableOptions?.group?.value ?? ''],
											{
												column: tableOptions.group?.value ?? '',
												group: groupValue,
												tableName,
												row: '_group',
											},
											'primary'
										)}
									</FlexAlign>
									{Array.isArray(groupElements) ? (
										<GridBody
											setHoveredRow={setHoveredRow}
											hoveredRow={hoveredRow}
											editCell={editCell}
											setEditCell={setEditCell}
											selectedCell={selectedCell}
											setSelectedCell={setSelectedCell}
											group={groupValue}
											elements={groupElements}
											columnWidths={columnWidths}
											fields={fields}
											selectedTable={selectedTable}
											setSelectedTable={setSelectedTable}
											startOperation={startOperation}
											table={table}
										/>
									) : (
										Object.entries(groupElements).map(
											([subGroupValue, subGroupElements], ind) => (
												<FlexCol key={`gridsubgroup:${index}${ind}`}>
													<FlexAlign className='py-4 pl-8 bg-primary-15 w-full'>
														{PrintField(
															subGroupValue,
															tableSchema[tableOptions?.group?.subgroup ?? ''],
															{
																column: tableOptions.group?.subgroup ?? '',
																group: subGroupValue,
																tableName,
																row: '_subgroup',
															},
															'primary'
														)}
													</FlexAlign>
													<GridBody
														setHoveredRow={setHoveredRow}
														hoveredRow={hoveredRow}
														editCell={editCell}
														setEditCell={setEditCell}
														selectedCell={selectedCell}
														setSelectedCell={setSelectedCell}
														group={`${groupValue}.${subGroupValue}`}
														elements={subGroupElements as TElement[]}
														columnWidths={columnWidths}
														fields={fields}
														selectedTable={selectedTable}
														setSelectedTable={setSelectedTable}
														startOperation={startOperation}
														table={table}
													/>
												</FlexCol>
											)
										)
									)}
								</FlexCol>
							)
						)
					)}
				</InfiniteScroll>
			</FlexCol>
		</>
	);
};

export default GridView;
