import LoginForm from 'components/hoc/login-form';
import { FC } from 'react';
import Api from 'services/api.service';
import { useAppDispatch } from 'store';
import { login } from 'store/auth/auth.slice';
import { handleError } from 'utils';
import { BackgroundElement } from 'components/basic/others';
import { FullColumn, Column } from 'components/hoc/background';
import { FlexRow } from 'components/basic';

const Login: FC = () => {
	const dispatch = useAppDispatch();

	const onSubmit = async (credentials: {
		username: string;
		password: string;
	}) => {
		try {
			const { data } = await Api.login(credentials);
			dispatch(login(data));
		} catch (error) {
			handleError(error);
		}
	};

	return (
		<FlexRow className='justify-center h-screen w-11/12 overflow-hidden'>
			<FullColumn margin={24} />
			<FullColumn margin={96} />
			<Column margin={80}>
				<BackgroundElement height='50vh' />
				<LoginForm onSubmit={onSubmit} />
				<BackgroundElement />
			</Column>
			<FullColumn margin={48} />
			<FullColumn margin={96} />
		</FlexRow>
	);
};

export default Login;
