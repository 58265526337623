import { FC, useState, useEffect } from 'react';
import { FlexAlign, FlexCol, FlexRow, Text } from 'components/basic';
import moment from 'moment';
import { InputTimeRange } from 'components/basic/inputs';
import { TRange, TRangeDay } from 'types/table';
import { days, isNotEmpty, toggleElementInArray } from 'utils';
import { Cross, Plus } from 'components/basic/assets';

import { selectorApp } from 'store/app/app.slice';
import { useSelector } from 'react-redux';

interface TimeRangeProps {
	setValue: (value: any) => void;
	defaultRanges?: { [day: string]: string[] };
}

const TimeRangeInput: FC<TimeRangeProps> = ({ setValue, defaultRanges }) => {
	const defaultRange: TRange = { start: undefined, end: '' };
	const { t } = useSelector(selectorApp);
	const [rangeDays, setRangeDays] = useState<TRangeDay>(
		days.reduce((week, day) => {
			week[day] = [defaultRange];
			return week;
		}, {} as TRangeDay)
	);

	const onSubmit = () => {
		setValue(
			days.reduce((week, day) => {
				if (Array.isArray(rangeDays?.[day])) {
					week[day] = rangeDays[day]
						?.map(({ start, end }) =>
							!!start
								? `${start}${isNotEmpty(end) ? `-${end}` : ''}`
								: undefined
						)
						.filter((range) => range !== undefined);
				}
				return week;
			}, {} as any)
		);
	};

	useEffect(() => {
		if (!defaultRanges) return;
		setRangeDays(
			days.reduce((week, day) => {
				if (
					Array.isArray(defaultRanges?.[day]) &&
					defaultRanges[day].length > 0
				) {
					week[day] = defaultRanges[day]
						?.map((range) => {
							const [start, end] = range.split('-');
							return { start, end };
						})
						.filter((range) => range !== undefined);
				} else week[day] = [defaultRange];
				return week;
			}, {} as any)
		);
	}, [defaultRanges]);

	useEffect(() => {
		onSubmit();
	}, [rangeDays]);

	return (
		<FlexCol>
			{Object.entries(rangeDays).map(([day, ranges], index) => (
				<FlexRow key={`input${day}:${index}`}>
					<Text
						fontSize='xs'
						fontWeight='semibold'
						color='primary'
						className='w-9 mr-6 text-2xs'
					>
						{moment().day(index).format('ddd')}
					</Text>
					<FlexCol>
						{ranges?.map(({ start, end }, ind) => (
							<FlexAlign
								key={`input${day}:${index}${ind}`}
								id={`input${day}:${index}${ind}`}
								className={`${
									index === 6 && ind === ranges?.length - 1 ? '' : 'mb-2'
								}`}
							>
								<Text fontSize='xs' color='primary' className='mr-2'>
									{`${t('input.time.start')}`.toUpperCase()}
								</Text>
								<InputTimeRange
									defaultValue={start}
									value={start}
									onChange={({ currentTarget: { value } }) => {
										const newRange = (rangeDays as any)[day];
										newRange[ind] = { ...newRange[ind], start: value };
										setRangeDays({
											...rangeDays,
											[day]: newRange,
										});
									}}
								/>
								<Text fontSize='xs' color='primary' className='mr-2 ml-4'>
									{`${t('input.time.end')}`.toUpperCase()}
								</Text>
								<InputTimeRange
									defaultValue={end}
									value={end}
									onChange={({ currentTarget: { value } }) => {
										const newRange = (rangeDays as any)[day];
										newRange[ind] = { ...newRange[ind], end: value };
										setRangeDays({
											...rangeDays,
											[day]: newRange,
										});
									}}
								/>
								{ind === ranges.length - 1 ? (
									<Plus
										className='ml-3 w-2 min-w-2 h-2 cursor-pointer'
										onClick={() =>
											setRangeDays({
												...rangeDays,
												[day]: [
													...((rangeDays as any)[day] ?? []),
													defaultRange,
												],
											})
										}
									/>
								) : (
									<div className='ml-3 min-w-2 h-2' />
								)}
								{ranges.length > 1 && (
									<Cross
										color='primary'
										className='ml-3 w-2 min-w-2 h-2 opacity-50 cursor-pointer'
										onClick={() => {
											const newDays = toggleElementInArray(
												(rangeDays as any)?.[day]?.[ind],
												(rangeDays as any)?.[day]
											);
											setRangeDays({
												...rangeDays,
												[day]: newDays.length > 0 ? newDays : [defaultRange],
											});
										}}
									/>
								)}
							</FlexAlign>
						))}
					</FlexCol>
				</FlexRow>
			))}
		</FlexCol>
	);
};

export default TimeRangeInput;
