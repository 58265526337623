import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectorAuth, setTables } from 'store/auth/auth.slice';
import { useAppDispatch } from 'store';
import {
	selectorApp,
	setInsertSession,
	setPreviewSession,
	setProperties,
} from 'store/app/app.slice';
import Api from 'services/api.service';
import {
	TEditedCell,
	TElement,
	TGroupedElements,
	TSchema,
	TStructure,
} from 'types/table';
import {
	TModalOperationOpt,
	TTableOperation,
	TArrayModalOpt,
	TDeleteOptions,
	TFileModalOpt,
	TTableOptions,
	TGeoFeatures,
	TTableOpt,
	TOption,
	TGeoCoord,
} from 'types/app';
import {
	elemToArray,
	isEmpty,
	handleError,
	mergeObjectOfArray,
	isNotEmpty,
	replaceElementInArray,
	objectFilter,
} from 'utils';
import UpsertColumnModal from 'components/modal/upsert-column/upsert-column.modal';
import ArrayModal from 'components/modal/array.modal';
import FileModal from 'components/modal/file.modal';
import lodash from 'lodash';
import { toast } from 'react-toastify';

import ConfirmModal from 'components/modal/confirm.modal';
import { FlexCol, Text } from 'components/basic';
import { SmallLoader } from 'components/basic/others';
import { useHistory, useParams } from 'react-router-dom';
import GeojsonModal from 'components/modal/geojson.modal';
import GridView from 'components/view/table-grid/grid.component';
import PreviewModal from 'components/modal/preview.modal';
import Table from 'services/table.service';
import VerticalView from 'components/view/vertical/vertical.component';

const Dashboard: FC = () => {
	const { tableName: thisTableName }: { tableName: string } = useParams() ?? {};
	const history = useHistory();
	const { tables, user } = useSelector(selectorAuth);
	const { insertSession, previewSession, t } = useSelector(selectorApp);
	const dispatch = useAppDispatch();

	const [currentTable, setCurrentTable] = useState<TStructure>();
	const [uploading, setUploading] = useState<boolean>(false);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [elements, setElements] = useState<TElement[] | TGroupedElements>([]);
	const [page, setPage] = useState<number>(1);
	const [limit] = useState<number>(35);
	const [hasNextPage, setHasNextPage] = useState<boolean>(true);
	const [columnModal, setColumnModal] = useState<Partial<TModalOperationOpt>>();
	const [cancelModal, setCancelModal] = useState<boolean>(false);
	const [fileModal, setFileModal] = useState<TFileModalOpt>();
	const [arrayModal, setArrayModal] = useState<TArrayModalOpt>();
	const [tableOptions, setTableOptions] = useState<TTableOptions>({});
	const [confirmModal, setConfirmModal] = useState<{
		tableName: string;
		options: TDeleteOptions;
		type: 'row' | 'rows';
	}>();
	const [geojsonModal, setGeojsonModal] = useState<{
		features: TGeoFeatures;
		table: {
			current: TStructure;
			geo: TStructure;
		};
		options: TOption[];
	}>();

	// LOAD DATAS

	const loadTables = async () => {
		try {
			const {
				data: { tables },
			} = await Api.listTables();
			dispatch(setTables(tables));
			if (tables.length === 0) {
				setTableLoading(false);
			}
		} catch (error) {
			handleError(error);
		}
	};

	const loadProperties = async () => {
		try {
			const {
				data: { fieldTypes: types },
			} = await Api.fieldTypes();
			const { data: attributes } = await Api.fieldAttributes();
			const {
				data: { mimetypes },
			} = await Api.fileMimetypes();
			dispatch(
				setProperties({
					fieldAttributes: attributes ?? {},
					fieldTypes: types ?? [],
					supportedMimetypes: mimetypes ?? [],
				})
			);
		} catch (error) {
			handleError(error);
		}
	};

	useEffect(() => {
		loadProperties();
		loadTables();
	}, []);

	useEffect(() => {
		(async () => {
			if (!tables || tables?.length === 0) return;
			if (!!thisTableName && thisTableName !== '_users') {
				// force grid to rerender when changing table
				setTableLoading(true);
				setCurrentTable(await Table.loadTableStructure(thisTableName));
				setTableLoading(false);
			} else history.push(`dashboard/${tables[0]?.tableName ?? ''}`);
		})();
	}, [thisTableName, tables]);

	const defaultLoadElements = async (
		reset: boolean = false,
		opts: TTableOptions = tableOptions
	) => {
		if (!currentTable) return;
		const newElements = await Table.loadTableElements(
			currentTable.tableName,
			opts,
			reset ? 1 : page,
			limit
		);
		if (reset) {
			setElements(newElements);
			setPage(2);
		} else {
			if (Array.isArray(elements) && Array.isArray(newElements)) {
				setElements((elems) => [...(elems as TElement[]), ...newElements]);
			} else {
				setElements((elems) =>
					mergeObjectOfArray(
						elems as TGroupedElements,
						newElements as TGroupedElements
					)
				);
			}
			setPage((p) => p + 1);
		}
		setHasNextPage(Table.getElementsArray(newElements, opts).length >= limit);
	};

	useEffect(() => {
		if (insertSession?.tableName !== thisTableName)
			dispatch(setInsertSession(undefined));
		defaultLoadElements(true);
	}, [tableOptions]);

	useEffect(() => {
		setTableOptions({});
		if (insertSession?.tableName !== thisTableName)
			dispatch(setInsertSession(undefined));
		defaultLoadElements(true, {});
	}, [currentTable]);

	const reloadTables = (tableName: string, table: TStructure) => {
		const tableNames = tables.map(({ tableName }) => tableName);
		const tableIndex = tableNames.indexOf(tableName);
		if (tableIndex > 0)
			dispatch(setTables(replaceElementInArray(tables, table, tableIndex)));
	};

	//EDIT SCHEMA

	const renameColumn = async (
		tableName: string,
		obj: { [columnName: string]: string }
	): Promise<TStructure | undefined> => {
		// obj key as old column name and value as new column name
		if (!obj || Object.keys(obj).length === 0) return currentTable;
		const [columnName, newColumnName] = Object.entries(obj)[0];
		if (columnName === newColumnName) return currentTable;
		const { data } = await Api.renameColumn(
			tableName,
			columnName,
			newColumnName
		);
		return data;
	};

	const upsertColumn = async (
		newStructure: TStructure,
		renamedColumn?: { [columnName: string]: string }
	) => {
		try {
			let structure: TStructure | undefined;
			if (thisTableName === 'User') {
				structure = (await Api.editUserTable(newStructure.tableSchema)).data;
			} else {
				if (renamedColumn)
					structure = await renameColumn(newStructure.tableName, renamedColumn);
				structure = (await Api.editSchema(newStructure))?.data;
				reloadTables(structure?.tableName ?? '', structure as TStructure);
			}
			setCurrentTable(structure);
		} catch (error) {
			handleError(error);
		}
	};

	// EDIT ELEMENTS

	const updateRow = async (data: TEditedCell, tableSchema: TSchema) => {
		if (!data) return;
		const {
			address: { row, group, tableName },
			update,
		} = data;
		const newElement = await Table.updateElement(
			{ tableName, tableSchema },
			row,
			update
		);
		if (!newElement) return;
		const newElements = lodash.cloneDeep(elements);
		if (Table.isGroupedElements(tableOptions)) {
			const elemIndex = (
				lodash.get(newElements, group) as TElement[]
			).findIndex(({ _id }) => _id === row);

			lodash.set(newElements, `${group}.${elemIndex}`, newElement);
		} else {
			const elemIndex = (newElements as TElement[]).findIndex(
				({ _id }) => _id === row
			);
			(newElements as TElement[])[elemIndex] = newElement;
		}
		setElements(newElements);
	};

	const deleteRows = async (
		tableName: string,
		{ deleteRows, deleteTable }: TDeleteOptions
	) => {
		try {
			if (['_users', 'User'].includes(thisTableName)) {
				if (deleteTable) return toast.error('Cannot delete all users');
				else {
					if (deleteRows && deleteRows.length > 0) {
						for (const row of deleteRows) {
							await Api.deleteUser(row);
						}
						if (Table.isGroupedElements(tableOptions)) {
							const newElements = lodash.cloneDeep(
								elements
							) as TGroupedElements;
							const groupKeys = Object.keys(newElements);
							groupKeys.forEach((key) => {
								if (Table.hasSubgroup(tableOptions)) {
									const subgroupKeys = Object.keys(
										newElements[key] as TGroupedElements
									);
									subgroupKeys.forEach((subkey) =>
										lodash.set(
											newElements,
											`${key}.${subkey}`,
											(
												(newElements[key] as TGroupedElements)[
													subkey
												] as TElement[]
											).filter(
												(elem) => elem._id && !deleteRows.includes(elem._id)
											)
										)
									);
								} else {
									newElements[key] = (newElements[key] as TElement[]).filter(
										(elem) => elem._id && !deleteRows.includes(elem._id)
									);
								}
							});
							setElements(newElements);
						} else {
							setElements(
								(elements as TElement[]).filter(
									(elem) => elem._id && !deleteRows.includes(elem._id)
								)
							);
						}
					}
				}
			} else {
				if (deleteTable) {
					await Api.deleteElements(tableName, true);
					setElements(Table.isGroupedElements(tableOptions) ? [] : {});
				} else if (deleteRows) {
					const multiple = deleteRows.length > 1;
					await Api.deleteElements(
						tableName,
						multiple,
						multiple ? { _id: { $in: deleteRows } } : { _id: deleteRows[0] }
					);
					if (Table.isGroupedElements(tableOptions)) {
						const newElements = lodash.cloneDeep(elements) as TGroupedElements;
						const groupKeys = Object.keys(newElements);
						groupKeys.forEach((key) => {
							if (Table.hasSubgroup(tableOptions)) {
								const subgroupKeys = Object.keys(
									newElements[key] as TGroupedElements
								);
								subgroupKeys.forEach((subkey) =>
									lodash.set(
										newElements,
										`${key}.${subkey}`,
										(
											(newElements[key] as TGroupedElements)[
												subkey
											] as TElement[]
										).filter(
											(elem) => elem._id && !deleteRows.includes(elem._id)
										)
									)
								);
							} else {
								newElements[key] = (newElements[key] as TElement[]).filter(
									(elem) => elem._id && !deleteRows.includes(elem._id)
								);
							}
						});
						setElements(newElements);
					} else {
						setElements(
							(elements as TElement[]).filter(
								(elem) => elem._id && !deleteRows.includes(elem._id)
							)
						);
					}
				}
			}
		} catch (error) {
			handleError(error);
		}
	};

	const closeInsert = () => {
		if (insertSession) dispatch(setInsertSession(undefined));
		if (previewSession.length > 0) {
			dispatch(
				setPreviewSession(previewSession.slice(0, previewSession.length - 1))
			);
		}
	};

	const insertRow = async (
		newElement: Partial<TElement>,
		tableName: string
	) => {
		if (
			isEmpty(
				objectFilter(
					newElement ?? {},
					([field]) => !Table.defaultFields.includes(field)
				)
			)
		)
			return closeInsert();
		const element = await Table.insertElement(tableName, newElement);
		if (!element) {
			return !currentTable?.singleRow && setCancelModal(true);
		}
		if (Table.isGroupedElements(tableOptions)) {
			const newElements: TGroupedElements = {};
			let groupKey = JSON.stringify(element[tableOptions.group?.value ?? '']);
			if (Table.hasSubgroup(tableOptions))
				groupKey = `${groupKey}.${JSON.stringify(
					element[tableOptions.group?.subgroup ?? '']
				)}`;
			lodash.set(newElements, groupKey, elemToArray(element));
			setElements((elems) =>
				mergeObjectOfArray(newElements, elems as TGroupedElements)
			);
		} else {
			setElements((elems) => [element, ...(elems as TElement[])]);
		}
		dispatch(setInsertSession(undefined));
	};

	const handleOperation = async (tableOperation: TTableOperation) => {
		if (!tableOperation) return;
		const { operation, table, options } = tableOperation;
		switch (operation as TTableOpt) {
			case 'cancelInsert':
				setCancelModal(true);
				break;
			case 'closeInsert':
				closeInsert();
				break;
			case 'addColumn':
				setColumnModal({ table, operation });
				break;
			case 'editColumn':
				setColumnModal({ table, operation, oldSchema: options });
				break;
			case 'editRow':
				await updateRow(options as TEditedCell, table.tableSchema);
				break;
			case 'deleteRow':
				setConfirmModal({
					options,
					tableName: table.tableName,
					type:
						options.deleteTable || options.deleteRows?.length > 1
							? 'rows'
							: 'row',
				});
				break;
			case 'insertSession':
				const { group }: { group: string } = options;
				const tempElem = Table.newElement(user ?? {});
				if (Table.isGroupedElements(tableOptions) && group) {
					const [groupValue, subGroupValue] = group.split('.');
					const groupKey = tableOptions.group?.value ?? '';
					tempElem[groupKey] = groupValue;
					if (Table.hasSubgroup(tableOptions)) {
						const subGroupKey = tableOptions.group?.subgroup ?? '';
						tempElem[subGroupKey] = subGroupValue;
					}
				}
				dispatch(
					setInsertSession({
						tableName: table.tableName,
						group: options.group,
						element: tempElem,
					})
				);
				break;
			case 'insertRow':
				if (isNotEmpty(options?.element))
					insertRow(
						(options as { element: TElement }).element,
						table.tableName
					);
				else insertRow(insertSession?.element ?? {}, table.tableName);

				break;
			case 'insertArray': {
				const {
					options: { address, fieldType },
				} = tableOperation;
				setArrayModal({
					arr: Table.isGroupedElements(tableOptions)
						? elements
						: (elements as TElement[]).find(({ _id }) => _id === address.row)?.[
								address.column
						  ] ?? [],
					fieldType,
					table,
					address,
				});
				break;
			}
			case 'insertFile':
				const {
					options: { address },
				} = tableOperation;
				setFileModal({
					elem: Table.isGroupedElements(tableOptions)
						? elements
						: (elements as TElement[]).find(({ _id }) => _id === address.row)?.[
								address.column
						  ],
					address,
					table,
				});
		}
	};

	const confirmGeojson = async (
		features: TGeoFeatures,
		currentTable: TStructure
	) => {
		const [locationField] = Object.entries(currentTable.tableSchema).filter(
			([_fieldName, { type }]) => type[0] === 'Location'
		)[0];
		const [nameField] = Object.entries(currentTable.tableSchema).filter(
			([fieldName, { type }]) =>
				type === 'String' && !Table.defaultFields.includes(fieldName)
		)[0];
		if (!locationField) throw new Error(`${t('toast.no_location_field')}`);
		const requiredFields = Object.entries(currentTable.tableSchema).reduce(
			(requireds, [fieldName, { required }]) =>
				required ? [...requireds, fieldName] : requireds,
			[] as string[]
		);
		if (!nameField) toast.warning(`${t('toast.no_name_field')}`);
		if (
			requiredFields.length > 1 ||
			(requiredFields.length === 1 &&
				![locationField, nameField].includes(requiredFields[0]))
		)
			throw new Error(`${t('toast.required_fields')}`);
		const geoTable = tables.filter(
			({ tableSchema }) =>
				!!Object.values(tableSchema).filter(
					({ ref }) => ref === currentTable.tableName
				)[0]
		)[0];
		// TODO: Translate
		if (!geoTable) return toast.error(`${t('toast.no_geojson_table')}`);
		const [stringField] = Object.entries(geoTable.tableSchema).filter(
			([fieldName, fieldSchema]) =>
				!Table.defaultFields.includes(fieldName) &&
				fieldSchema.type === 'String'
		)[0];
		if (!stringField) return toast.error(`${t('toast.no_string_field')}`);
		const options = (await Table.loadTableElements(
			geoTable.tableName
		)) as TElement[];
		setGeojsonModal({
			features,
			table: {
				current: currentTable,
				geo: geoTable,
			},
			options: options.map((opt) => ({
				label: opt[stringField],
				value: opt[stringField],
			})),
		});
	};

	// CODE IS FOR SARIM

	const uploadGeojson = async (
		{ geo, current }: { geo: TStructure; current: TStructure },
		polys: { coordinates: TGeoCoord; reference: string }[],
		deleteOld: boolean = false
	) => {
		setUploading(true);
		try {
			// Location field of the polygons table
			const [locationField] = Object.entries(current.tableSchema).find(
				([_fieldName, { type }]) => type[0] === 'Location'
			) ?? [''];
			// String field to fill of the polygons table
			const [nameField] = Object.entries(current.tableSchema).find(
				([fieldName, { type }]) =>
					type === 'String' && !Table.defaultFields.includes(fieldName)
			) ?? [''];
			let newElements: TElement[] = [];
			//Location field to compare of the table with foreign key
			const [foreignLocationField] = Object.entries(geo.tableSchema).find(
				([fieldName, fieldSchema]) =>
					!Table.defaultFields.includes(fieldName) &&
					fieldSchema.type?.[0] === 'ObjectId' &&
					fieldSchema.ref === current.tableName
			) ?? [''];
			// String field to compare of the table with foreign key
			const [foreignStringField] = Object.entries(geo.tableSchema).find(
				([fieldName, fieldSchema]) =>
					!Table.defaultFields.includes(fieldName) &&
					fieldSchema.type === 'String'
			) ?? [''];
			for (const { coordinates, reference } of polys) {
				const newPolygons = coordinates.flat().map((poly, index) => ({
					name: `${reference} (${index + 1})`,
					polygon: poly.map(([lng, lat]) => ({ lat, lng })),
				}));
				const {
					data: { elements: polygons },
				} = await Api.insertElement(current.tableName, {
					elements: newPolygons.map(({ name, polygon }) => ({
						[nameField]: name,
						[locationField]: polygon,
					})),
				});
				if (deleteOld) {
					const [refElement] = (await Table.loadTableElements(geo.tableName, {
						filter: { [foreignStringField]: reference },
					})) as TElement[];
					if (!refElement || refElement.length === 0) return;
					await Api.updateElement(geo.tableName, refElement._id ?? '', {
						[foreignLocationField]: polygons,
					});
					await deleteRows(current.tableName, {
						deleteRows: refElement[foreignLocationField].map(
							({ _id }: TElement) => _id
						),
					});
				}
				newElements = [...newElements, ...polygons];
			}
			if (Table.isGroupedElements(tableOptions)) {
				const newElement: TGroupedElements = {};
				let groupKey = tableOptions.group?.value as string;
				if (Table.hasSubgroup(tableOptions))
					groupKey = `${groupKey}.${tableOptions.group?.subgroup}`;
				lodash.set(newElement, groupKey, newElements);
				setElements(
					mergeObjectOfArray(newElement, elements as TGroupedElements)
				);
			} else {
				setElements([...newElements, ...(elements as TElement[])]);
			}
		} catch (e) {
			handleError(e);
		}
		setUploading(false);
	};
	return (
		<>
			<FlexCol
				key={`grid:${thisTableName}`}
				className='max-h-full-with-header w-full'
			>
				{tableLoading ? (
					<SmallLoader />
				) : currentTable ? (
					currentTable.singleRow ? (
						<VerticalView
							defaultElement={
								Table.getElementsArray(elements, tableOptions)?.[0]
							}
							setTableOptions={setTableOptions}
							startOperation={handleOperation}
							table={currentTable}
							tableOptions={tableOptions}
						/>
					) : (
						<GridView
							uploading={uploading}
							uploadGeojson={confirmGeojson}
							scrollOptions={{
								defaultLoadElements,
								hasNextPage,
							}}
							tableOptions={tableOptions}
							setTableOptions={setTableOptions}
							startOperation={handleOperation}
							table={currentTable}
							elements={elements}
						/>
					)
				) : (
					<div className='p-4'>{t('table.no_table')}</div>
				)}
			</FlexCol>
			{!!columnModal && (
				<UpsertColumnModal
					operation={columnModal?.operation}
					table={columnModal?.table}
					oldSchema={columnModal?.oldSchema}
					onSubmit={upsertColumn}
					onHide={() => setColumnModal(undefined)}
				/>
			)}
			{!!arrayModal && (
				<ArrayModal
					startOperation={handleOperation}
					opt={arrayModal}
					onHide={() => setArrayModal(undefined)}
				/>
			)}
			{!!fileModal && (
				<FileModal
					opt={fileModal ?? {}}
					startOperation={handleOperation}
					onHide={() => setFileModal(undefined)}
				/>
			)}
			{!!geojsonModal && (
				<GeojsonModal
					table={geojsonModal.table}
					uploadGeojson={uploadGeojson}
					features={geojsonModal.features ?? []}
					options={geojsonModal.options}
					onHide={() => setGeojsonModal(undefined)}
				/>
			)}
			{previewSession?.length > 0 && currentTable && (
				<PreviewModal startOperation={handleOperation} onHide={closeInsert} />
			)}
			{cancelModal && (
				<ConfirmModal
					header={`${t('placeholder.cancel')} ${t('table.operation.insert')}`}
					onHide={() => setCancelModal(false)}
					cancelText={t('placeholder.go_back')}
					explanation={
						<FlexCol>
							<Text>{t('modal.cancel_insert_one')}</Text>
							<Text>{t('modal.cancel_insert_two')}</Text>
						</FlexCol>
					}
					onConfirm={async () => {
						closeInsert();
						setCancelModal(false);
					}}
				/>
			)}
			{!!confirmModal && (
				<ConfirmModal
					header={`${t('placeholder.remove')} ${t('rows.delete_multiple')}`}
					onHide={() => setConfirmModal(undefined)}
					confirmText={t('placeholder.delete')}
					explanation={
						<FlexCol>
							<Text>{`${t('modal.remove_one')} ${
								confirmModal.options?.deleteRows?.length ?? elements.length
							} ${`${t(
								confirmModal.type === 'row'
									? 'rows.delete_one'
									: 'rows.delete_multiple'
							)}`.toLowerCase()}?`}</Text>
							<Text>{t('modal.remove_two')}</Text>
						</FlexCol>
					}
					onConfirm={async () => {
						await deleteRows(confirmModal.tableName, confirmModal.options);
						dispatch(
							setPreviewSession(
								previewSession.slice(0, previewSession.length - 1)
							)
						);
						setConfirmModal(undefined);
					}}
				/>
			)}
		</>
	);
};

export default Dashboard;
