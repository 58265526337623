import { FC, Dispatch, SetStateAction, Fragment } from 'react';
import { FlexAlign, Grid, Text } from 'components/basic';
import TableCell from 'components/hoc/table-cell';
import { useSelector } from 'react-redux';
import { selectorApp, setPreviewSession } from 'store/app/app.slice';
import lodash from 'lodash';

import { elemToArray, isEmpty, objectFilter } from 'utils';
import { TCellAddress, TElement, TStructure } from 'types/table';
import { Cross, Expand, Plus } from 'components/basic/assets';
import { toast } from 'react-toastify';
import { TTableOperation } from 'types/app';
import TableIndex from 'components/hoc/table-index';
import { OptionButton } from 'components/basic/buttons';
import { useAppDispatch } from 'store';
import Table from 'services/table.service';

interface BodyProps {
	table: TStructure;
	elements: TElement[];
	startOperation: (operationOpt: TTableOperation) => void;
	editCell?: TCellAddress;
	setEditCell: Dispatch<SetStateAction<TCellAddress | undefined>>;
	selectedCell?: TCellAddress | TCellAddress[];
	setSelectedCell: Dispatch<
		SetStateAction<TCellAddress | TCellAddress[] | undefined>
	>;
	setHoveredRow: Dispatch<SetStateAction<string | undefined>>;
	hoveredRow?: string;
	selectedTable?: string;
	setSelectedTable: Dispatch<SetStateAction<string | undefined>>;
	fields: string[];
	columnWidths: {
		[column: string]: number;
	};
	group: string;
}

const GridBody: FC<BodyProps> = ({
	elements,
	startOperation,
	table,
	selectedTable,
	fields,
	columnWidths,
	setSelectedTable,
	group,
	selectedCell,
	setSelectedCell,
	editCell,
	setEditCell,
	setHoveredRow,
	hoveredRow,
}) => {
	const { insertSession, t } = useSelector(selectorApp);
	const dispatch = useAppDispatch();

	const isSelected = (rowId: string | undefined, column: string) => {
		if (!rowId) return false;
		if (selectedTable === table.tableName) return true;
		if (!selectedCell) return false;
		const selectedCells = elemToArray(selectedCell);
		let selected = false;
		for (const cell of selectedCells) {
			if (cell.group !== group) return false;
			selected = cell.row === rowId && [column, '*'].includes(cell.column);
			if (selected) break;
		}
		return selected;
	};

	const insertElement = () => {
		if (!insertSession) {
			startOperation({ table, options: { group }, operation: 'insertSession' });
			setSelectedCell({
				column: '*',
				row: '',
				tableName: table.tableName,
				group,
			});
		} else toast.warning(`${t('toast.insert_element_error')}`);
	};

	const cancelInsert = (isClose: boolean = false) => {
		setSelectedCell(undefined);
		setEditCell(undefined);
		setHoveredRow(undefined);
		startOperation({
			table,
			operation:
				isClose ||
				isEmpty(
					objectFilter(
						insertSession?.element ?? {},
						([field]) => !Table.defaultFields.includes(field)
					)
				)
					? 'closeInsert'
					: 'cancelInsert',
		});
	};

	return (
		<Grid
			className='grid-flow-col z-20 w-content'
			onMouseLeave={() => setHoveredRow(undefined)}
			style={{
				gridTemplateRows: `repeat(${elements.length + 1}, minmax(3rem, 3rem))`,
				gridTemplateColumns: 'max-content',
			}}
		>
			<TableIndex
				bgColor='gray'
				className={`rounded-tl overflow-hidden ${
					insertSession?.group === group || hoveredRow === ''
						? 'text-white bg-primary-75'
						: 'text-primary bg-primary-15'
				}`}
				address={{
					column: '*',
					row: '',
					tableName: table.tableName,
					group,
				}}
				onClick={() =>
					insertSession?.group === group ? cancelInsert(true) : insertElement()
				}
				onMouseEnter={() => setHoveredRow('')}
			>
				{insertSession?.group === group ? (
					<Cross className='w-2.5 h-2.5' />
				) : (
					<FlexAlign className='justify-center flex-1 h-full'>
						<Plus
							color={
								insertSession?.group === group || hoveredRow === ''
									? 'white'
									: 'primary'
							}
							className='w-2.5 h-2.5'
						/>
					</FlexAlign>
				)}
			</TableIndex>
			{elements.map(({ _id, ...rest }, rowIndex) => {
				const selectedRow = isSelected(_id, '*');
				const address: TCellAddress = {
					row: _id ?? 'id',
					column: '*',
					tableName: table.tableName,
					group,
				};

				return _id ? (
					<TableIndex
						bgColor={rowIndex % 2 === 0 ? 'gray-dark' : 'gray'}
						key={`*${rowIndex}`}
						className={`${rowIndex === elements.length ? 'rounded-bl' : ''} ${
							selectedRow || hoveredRow === _id
								? 'text-white bg-primary-75'
								: 'text-primary bg-primary-15'
						}`}
						address={address}
						onMouseEnter={() => setHoveredRow(_id)}
					>
						{hoveredRow !== _id ? (
							rowIndex + 1
						) : (
							<>
								<FlexAlign
									onClick={() =>
										dispatch(
											setPreviewSession([
												{
													table,
													element: { _id, ...rest },
													previousTableField: null,
												},
											])
										)
									}
									className='justify-center flex-1 h-full hover:bg-primary-90'
								>
									<Expand color='white' />
								</FlexAlign>
								<div className='bg-white w-0.5 h-1/2' />
								<FlexAlign
									className='justify-center flex-1 h-full hover:bg-primary-90'
									onClick={() => {
										if (editCell) return;
										if (!Array.isArray(selectedCell))
											setSelectedCell([address]);
										else if (selectedRow) {
											const filter = selectedCell.filter(
												(cell) => cell.row !== _id
											);
											if (filter.length > 0) setSelectedCell(filter);
											else setSelectedCell(undefined);
										} else setSelectedCell([...selectedCell, address]);
									}}
								>
									<OptionButton
										checked={selectedRow}
										className='w-3 h-3'
										color='white'
									/>
								</FlexAlign>
							</>
						)}
					</TableIndex>
				) : (
					<></>
				);
			})}
			{fields.map((column: string, columnIndex: number) => {
				const cellProps = {
					insertModal: (
						address: TCellAddress,
						fieldType: string | string[]
					) => {
						startOperation({
							table,
							options: {
								address,
								fieldType: Table.getType(fieldType),
							},
							operation: Array.isArray(fieldType)
								? 'insertArray'
								: 'insertFile',
						});
					},
				};
				const address: TCellAddress = {
					tableName: table.tableName,
					column,
					row: '',
					group,
				};
				return (
					<Fragment key={`column${column}${columnIndex}`}>
						<TableCell
							bgColor='gray'
							attributes={
								insertSession?.group === group
									? table.tableSchema[column]
									: { type: 'JSX' }
							}
							cellStyle={`cursor-pointer ${
								insertSession?.group === group ? 'bg-primary-15' : ''
							}`}
							isEditing={lodash.isEqual(editCell, address)}
							width={columnWidths[column]}
							setEdit={insertSession && setEditCell}
							onClick={() => !insertSession && insertElement()}
							editCell={(edited) =>
								startOperation({
									table: table,
									operation: 'editRow',
									options: edited,
								})
							}
							address={address}
							onMouseEnter={() => setHoveredRow('')}
							cellValue={
								insertSession?.group === group ? (
									insertSession.element?.[column]
								) : columnIndex === 0 ? (
									<Text color='black-light' fontSize='xs'>
										{t('placeholder.add')}
									</Text>
								) : (
									<></>
								)
							}
							{...cellProps}
						/>
						{elements.map((element, rowIndex: number) => {
							const id = element._id ?? 'id';
							const selectedCell = isSelected(id, column);
							const address: TCellAddress = {
								tableName: table.tableName,
								column,
								row: id,
								group,
							};

							return (
								<TableCell
									bgColor={rowIndex % 2 === 0 ? 'gray-dark' : 'gray'}
									width={columnWidths[column]}
									attributes={table.tableSchema[column]}
									isEditing={lodash.isEqual(editCell, address)}
									setEdit={setEditCell}
									onClick={() => {
										if (editCell) return;
										if (selectedCell) setSelectedCell(undefined);
										else {
											setSelectedCell(address);
										}
										setSelectedTable(undefined);
									}}
									editCell={(edited) =>
										startOperation({
											table,
											operation: 'editRow',
											options: edited,
										})
									}
									key={`${element}:${rowIndex}`}
									address={address}
									onMouseEnter={() => setHoveredRow(id)}
									cellValue={element?.[column]}
									cellStyle={
										selectedCell ? 'bg-primary-15' : 'hover:bg-primary-15'
									}
									{...cellProps}
								/>
							);
						})}
					</Fragment>
				);
			})}
		</Grid>
	);
};

export default GridBody;
