import { useState, useEffect, forwardRef, HTMLAttributes } from 'react';
import { TCellAddress, TEditedCell, TField } from 'types/table';
import {
	InputArray,
	InputCheckbox,
	InputFile,
	InputLocation,
	InputSelection,
} from 'components/basic/inputs';
import { ClickableScreen, Media } from 'components/basic/others';
import { FlexAlign } from 'components/basic';
import { useSelector } from 'react-redux';
import { selectorApp, setInsertSession } from 'store/app/app.slice';
import { useAppDispatch } from 'store';
import { elemToArray, isEmpty, language } from 'utils';

import { PrintField } from 'components/basic/print';
import ObjectIdModal from '../modal/objectid.modal';
import { TOption } from 'types/app';
import TimeRangeModal from '../modal/time-range.modal';
import lodash from 'lodash';
import PeriodModal from '../modal/period.modal';
import TranslationModal from 'components/modal/translation.modal';
import moment from 'moment';
import CronModal from 'components/modal/cron.modal';
import Table from 'services/table.service';
import MenuModal from 'components/modal/menu.modal';

interface TCellProps extends HTMLAttributes<HTMLDivElement> {
	cellValue?: any;
	attributes: TField;
	isEditing?: boolean;
	setEdit?: (value: TCellAddress | undefined) => void;
	address: TCellAddress;
	cellStyle?: string;
	editCell?: (edited?: TEditedCell) => void;
	insertModal?: (address: TCellAddress, fieldType: string | string[]) => void;
	width?: number;
	bgColor: 'gray' | 'gray-dark';
}

const TableCell = forwardRef<HTMLDivElement, TCellProps>(
	(
		{
			cellValue,
			className = '',
			address,
			attributes,
			isEditing,
			setEdit,
			cellStyle,
			editCell,
			insertModal,
			width,
			bgColor,
			...props
		},
		ref
	) => {
		const [fieldType, setFieldType] = useState<string>('String');
		const [updated, setUpdated] = useState<any>();
		const [style, setStyle] = useState<string | undefined>(cellStyle);
		const { insertSession, t } = useSelector(selectorApp);
		const dispatch = useAppDispatch();

		const mediaCSS = `rounded mx-1 object-contain ${
			fieldType === 'ObjectId' &&
			Table.getType(attributes?.column?.schema?.type) === 'File'
				? 'h-5 w-5 min-w-15px'
				: 'h-35px w-35px min-w-35px'
		}`;

		useEffect(() => {
			setStyle(cellStyle);
		}, [cellStyle]);

		useEffect(() => {
			setFieldType(Table.getType(attributes?.type) ?? 'String');
		}, [attributes]);

		const submitEdit = (value?: any) => {
			if (!editCell || !setEdit) return;
			editCell({ address, update: { [address.column]: value ?? updated } });
			setUpdated(undefined);
			setEdit(undefined);
		};

		const handleInput = (value: any) =>
			insertSession
				? dispatch(
						setInsertSession({
							...insertSession,
							element: { ...insertSession.element, [address.column]: value },
						})
				  )
				: setUpdated(value);

		const PrintInput = () => {
			if (!attributes?.type) return <div />;
			const isArrayInput =
				Array.isArray(attributes.type) || attributes.type === 'Array';

			const refId = `cell:${JSON.stringify(address)}`;

			if (attributes.enum) {
				const options: TOption[] = attributes.translatable
					? Object.entries(attributes.enum[language]).map(([value, label]) => ({
							label,
							value,
					  }))
					: attributes.enum.map((value: string) => ({
							label: value,
							value,
					  }));

				return (
					<InputSelection
						id={`selection:${JSON.stringify(address)}`}
						buttonClass={`h-11 px-2 text-xs w-full select-none bg-${bgColor}`}
						optionsListClass={`bg-${bgColor} min-w-full select-none`}
						optionClass='min-h-12 px-2'
						defaultSelected={
							insertSession?.element?.[address.column] ?? updated ?? cellValue
						}
						multiple={isArrayInput}
						options={{ _nogroup: options }}
						onSelection={(opt) => handleInput(opt)}
					/>
				);
			}

			if (attributes.translatable) {
				return (
					<TranslationModal
						multiple={isArrayInput}
						onClose={(value) => {
							if (!lodash.isEqual(value, cellValue)) {
								if (insertSession) handleInput(value);
								else submitEdit(value);
							}
							setEdit && setEdit(undefined);
						}}
						defaultValue={cellValue}
						refId={refId}
					/>
				);
			}

			if (fieldType === 'TimeRange') {
				return (
					<TimeRangeModal
						refId={refId}
						defaultRanges={cellValue}
						onClose={(value) => {
							if (!lodash.isEqual(value, cellValue)) {
								if (insertSession) handleInput(value);
								else submitEdit(value);
							}
							setEdit && setEdit(undefined);
						}}
					/>
				);
			}
			if (fieldType === 'Period') {
				return (
					<PeriodModal
						refId={refId}
						isArray={isArrayInput}
						defaultPeriods={isEmpty(cellValue) ? [] : elemToArray(cellValue)}
						onClose={(value) => {
							if (!lodash.isEqual(value, cellValue)) {
								if (insertSession) handleInput(value);
								else submitEdit(value);
							}
							setEdit && setEdit(undefined);
						}}
					/>
				);
			}

			if (fieldType === 'Quantity' || fieldType === 'Menu') {
				return (
					<MenuModal
						refId={refId}
						hasQuantity={fieldType === 'Quantity'}
						multiple={isArrayInput}
						defaultValues={cellValue}
						onClose={(value) => {
							if (
								!lodash.isEqual(
									value,
									isArrayInput ? elemToArray(cellValue ?? []) : cellValue
								)
							) {
								if (insertSession) handleInput(value);
								else submitEdit(value);
							}
							setEdit && setEdit(undefined);
						}}
					/>
				);
			}

			if (fieldType === 'ObjectId') {
				if (isEmpty(attributes.ref)) return <></>;
				return (
					<ObjectIdModal
						previousTableField={address.column}
						refId={refId}
						onClose={(value) => {
							if (
								!lodash.isEqual(
									value,
									isArrayInput ? elemToArray(cellValue ?? []) : cellValue
								)
							) {
								if (insertSession) handleInput(value);
								else submitEdit(value);
							}
							setEdit && setEdit(undefined);
						}}
						tableName={attributes.ref}
						multiple={isArrayInput}
						defaultValues={elemToArray(cellValue ?? [])}
						initialFields={elemToArray(attributes.column?.value)}
					/>
				);
			}

			if (fieldType === 'Boolean') {
				return (
					<FlexAlign className='w-full justify-center'>
						<InputCheckbox
							className='z-30 h-6 w-6'
							setValue={(value) => handleInput(value)}
							color='white'
							text=''
							checked={
								insertSession
									? insertSession.element[address.column]
									: updated ?? cellValue
							}
						/>
					</FlexAlign>
				);
			}
			if (fieldType === 'File') {
				return (
					<InputFile
						tableName={address.tableName}
						fieldName={address.column}
						mimetypes={attributes.mimetypes}
						className='z-20'
						onDrop={(files) => handleInput(files[0])}
					>
						<FlexAlign className='justify-center cursor-pointer min-w-100px h-11 text-xs text-primary font-semibold'>
							{updated || insertSession?.element?.[address.column] ? (
								<Media
									className={mediaCSS}
									file={updated ?? insertSession?.element?.[address.column]}
								/>
							) : (
								`${t('placeholder.upload')}`.toUpperCase()
							)}
						</FlexAlign>
					</InputFile>
				);
			}
			if (fieldType === 'Location') {
				return (
					<InputLocation
						defaultValue={cellValue}
						onChange={(value) => handleInput(value)}
					/>
				);
			}
			if (fieldType === 'Cron') {
				return (
					<CronModal
						refId={refId}
						defaultCron={cellValue}
						onClose={(value) => {
							if (insertSession) handleInput(value);
							else submitEdit(value);
							setEdit && setEdit(undefined);
						}}
					/>
				);
			}
			const inputType =
				fieldType === 'DateTime' ? 'datetime-local' : fieldType.toLowerCase();

			const defaultValues = elemToArray(cellValue).map((elem) => {
				switch (inputType) {
					case 'datetime-local':
						return moment(elem).utcOffset(0).format('YYYY-MM-DDTHH:mm');
					case 'time':
						return moment(elem).utcOffset(0).format('HH:mm');
					case 'date':
						return moment(elem).utcOffset(0).format('YYYY-MM-DD');
					default:
						return elem;
				}
			});

			if (
				isArrayInput &&
				['String', 'Number', 'Array', 'Date', 'Time', 'DateTime'].includes(
					fieldType
				)
			)
				return (
					<InputArray
						className='z-20 h-11 flex-nowrap'
						inputProps={{
							maxLength: attributes.maxLength,
							minLength: attributes.minLength,
							min: attributes.min,
							max: attributes.max,
							type: inputType,
							autoFocus: true,
						}}
						defaultValue={isEmpty(cellValue) ? undefined : defaultValues}
						setValue={(arr) => handleInput(arr)}
					/>
				);

			return (
				<input
					className={`z-20 px-2 h-11 w-full font-normal bg-${bgColor} focus:outline-none resize-x overflow-auto`}
					type={inputType}
					autoFocus
					min={attributes.min}
					max={attributes.max}
					minLength={attributes.minLength}
					maxLength={attributes.maxLength}
					defaultValue={defaultValues[0]}
					onChange={(e) => {
						const value = parseFloat(e.currentTarget.value);
						if (attributes.max && value > attributes.max)
							e.currentTarget.value = attributes.max.toString();
						if (attributes.min && value < attributes.min)
							e.currentTarget.value = attributes.min.toString();
						handleInput(
							inputType === 'number'
								? parseFloat(e.currentTarget.value)
								: e.currentTarget.value
						);
					}}
				/>
			);
		};

		const startEdit = () => {
			if (!setEdit) return;
			if (
				((Array.isArray(attributes?.type) &&
					['Array', 'File', 'Boolean', 'Decimal128', 'Location'].includes(
						fieldType
					)) ||
					(fieldType === 'File' && cellValue)) &&
				insertModal
			)
				return insertModal(address, attributes.type);
			if (attributes?.immutable && !insertSession) {
				setTimeout(() => setStyle('bg-primary-15'), 200);
				setTimeout(() => setStyle(cellStyle), 400);
			} else {
				setEdit(address);
			}
		};
		return (
			<div
				onContextMenu={(e) => e.preventDefault()}
				/* TODO: IMPLEMENT TAB FOCUS tabIndex={0} */
				ref={ref}
				className={`w-full overflow-hidden whitespace-nowrap cursor-default border-r border-white bg-${bgColor} ${className}`}
				style={{ ...props.style, width }}
				{...props}
			>
				{isEditing && <ClickableScreen cb={() => submitEdit()} />}
				<FlexAlign
					id={`cell:${JSON.stringify(address)}`}
					onClick={(e) => {
						e.preventDefault();
						if (!insertSession) return;
						if (insertSession && address.row === '') startEdit();
					}}
					onDoubleClick={(e) => {
						e.preventDefault();
						if (insertSession) return;
						startEdit();
					}}
					className={`h-12 text-xs hover:bg-primary-15 ${style} ${
						isEditing ? 'border-2 border-primary' : 'px-2'
					} ${cellStyle}`}
					style={{ width }}
				>
					{isEditing && !Table.defaultFields.includes(address.column)
						? PrintInput()
						: PrintField(cellValue, attributes, address)}
				</FlexAlign>
			</div>
		);
	}
);

export default TableCell;
