import { TBrand, TColor } from 'types/app';
import { makeUrl } from 'utils';

export const defaultTheme: TBrand = {
	name: 'Armonia',
	welcome: {
		it: 'Benvenuto in ArmoniaDB',
		en: 'Welcome to ArmoniaDB',
	},
	favicon: {
		url: '%PUBLIC_URL%/favicon.svg',
		mimetype: 'image/svg+xml',
		size: 1,
		rotate: 0,
		scale: 1,
	},
	landingLogo: {
		url: '%PUBLIC_URL%/logo-landing.svg',
		mimetype: 'image/svg+xml',
		size: 1,
		rotate: 0,
		scale: 1,
	},
	headerLogo: {
		url: '%PUBLIC_URL%/logo-header.svg',
		mimetype: 'image/svg+xml',
		size: 1,
		rotate: 0,
		scale: 1,
	},
	color: {
		name: 'purple',
		r: 82,
		g: 83,
		b: 152,
	},
};

const generatePalette = ({ r, g, b }: TColor) => {
	const palette: { [shade: string]: string } = {
		'--armonia-color': `rgba(${r},${g},${b})`,
	};
	for (let i = 0; i < 1; i = i + 0.05)
		palette[
			`--armonia-color-${Math.round(i * 100)}`
		] = `rgba(${r},${g},${b},${i.toFixed(2)})`;
	return palette;
};

const applyPalette = (color: TColor) => {
	const palette = generatePalette(color);
	Object.entries(palette).forEach(([shade, value]) =>
		document.documentElement.style.setProperty(shade, value)
	);
};

export const applyBrand = (brand: TBrand = defaultTheme) => {
	applyPalette(brand.color);
	const favicon = document.getElementById('favicon');
	if (favicon) favicon.setAttribute('href', makeUrl(brand.favicon?.url));
	document.title = `${brand.name} DB`;
};
