import axios from 'axios';
import { flatten } from 'flat';
import { baseUrl } from 'config';
import {
	TListOptions,
	TSchema,
	TStructure,
	TStructureProperties,
} from 'types/table';
import { TUser } from 'types/auth';
import { TBrand } from 'types/app';
import { TApiMethod, TAutomation } from 'types/automation';
import lodash from 'lodash';
import { FileCompressor } from 'utils';

axios.defaults.baseURL = baseUrl;
axios.defaults.headers.common.Accept = 'application/json';

export interface TApiOptions {
	params?: any;
	headers?: any;
	data?: any;
}

class Api {
	static _temporaryToken: string | undefined = undefined;

	static request = async (
		method: TApiMethod,
		url: string,
		params: any = {},
		headers: any = {},
		data: any = {},
		useToken: boolean
	) => {
		if (useToken) {
			const token = Api._temporaryToken;
			headers.authorization = `Bearer ${token}`;
		}
		return axios({
			method,
			url,
			params,
			headers,
			data,
		});
	};

	static get = async (url: string, opt: TApiOptions = {}, useToken = true) =>
		Api.request('GET', url, opt.params, opt.headers, opt.data, useToken);
	static post = async (url: string, opt: TApiOptions = {}, useToken = true) =>
		Api.request('POST', url, opt.params, opt.headers, opt.data, useToken);
	static delete = async (url: string, opt: TApiOptions = {}, useToken = true) =>
		Api.request('DELETE', url, opt.params, opt.headers, opt.data, useToken);
	static patch = async (url: string, opt: TApiOptions = {}, useToken = true) =>
		Api.request('PATCH', url, opt.params, opt.headers, opt.data, useToken);
	static put = async (url: string, opt: TApiOptions = {}, useToken = true) =>
		Api.request('PUT', url, opt.params, opt.headers, opt.data, useToken);

	static on401 = (callback: (response: any) => void) =>
		axios.interceptors.response.use(
			(response) => response,
			(error) => {
				const { response } = error;
				if (
					response?.status === 401 &&
					response.data.message.startsWith('[FASTIFY]')
				) {
					Api.resetTemporaryToken();
					callback(response);
				}
				return Promise.reject(error);
			}
		);

	static resetTemporaryToken = () => {
		Api._temporaryToken = undefined;
	};

	static setTemporaryToken = (value: string | undefined) => {
		Api._temporaryToken = value;
	};

	static createFormDataFromObject = async (obj: Object) => {
		const formData = new FormData();
		if (obj) {
			const flatObj = await flatten<any, Promise<Object>>(obj);
			Object.entries(flatObj).forEach(([key, value]: [string, any]) =>
				formData.set(key, value)
			);
		}
		return formData;
	};

	//PUBLIC

	static getBrand = async () => Api.get('/public/brand', {}, false);

	static login = async (credentials: {
		username: string;
		password: string;
	}) => {
		const response = await Api.post(
			'/public/auth',
			{ data: credentials },
			false
		);
		Api.setTemporaryToken(response.data.access_token);
		return response;
	};

	static fileMimetypes = async () => Api.get('/public/mimetypes', {}, false);

	// BRAND

	static saveBrand = async (brand: TBrand) =>
		Api.post('/brand', { data: { brand } });

	// USER

	static createUser = async (user: Partial<TUser>) => {
		const newUser = lodash.cloneDeep(user);
		if (newUser.roleId) {
			//@ts-ignore TODO: temporary fix for user creation
			newUser.role = { roleId: newUser.roleId };
			delete newUser.roleId;
		}

		return Api.post('/user', {
			data: newUser,
		});
	};

	static getUserTable = async () => Api.get('/user/table');

	static editUserTable = async (newSchema: TSchema) =>
		Api.put('/user/table', { data: newSchema });

	static updateUser = async (userId: string, data: any) =>
		Api.put(`/user/${userId}`, { data });

	static deleteUser = async (userId: string) => Api.delete(`/user/${userId}`);

	static logout = () => Api.resetTemporaryToken();

	static listUsers = async (options: TListOptions = {}) =>
		Api.post('/user/list', { data: options });

	// ROLE
	static listRoles = async (options: TListOptions = {}) =>
		Api.post('/role/list', { data: options });

	static permissions = async () => Api.get('/role/permissions', {});

	// SCHEMA

	static fieldAttributes = async () => Api.get('/schema/fields/attributes', {});

	static fieldTypes = async () => Api.get('/schema/fields/types', {});

	static getTable = async (tableName: string) =>
		Api.get(`/schema/${tableName}`);

	static listTables = async () => Api.get('/schema/list');

	static createTable = async (structure: TStructure) =>
		Api.post('/schema', { data: structure });

	static deleteTable = async (tableName: string) =>
		Api.delete(`/schema/${tableName}`);

	static editSchema = async ({ tableName, tableSchema }: TStructure) =>
		Api.put(`/schema/${tableName}`, { data: { tableSchema } });

	static renameTable = async (tableName: string, newTableName: string) =>
		Api.put(`/schema/${tableName}/rename`, { data: { newTableName } });

	static editTableProperties = async (
		tableName: string,
		properties: TStructureProperties
	) => Api.put(`/schema/${tableName}/properties`, { data: properties });

	static renameColumn = async (
		tableName: string,
		columnName: string,
		newColumnName: string
	) =>
		Api.put(`/schema/${tableName}/rename/column`, {
			data: { columnName, newColumnName },
		});

	// TABLE

	static listElements = async (
		tableName: string,
		{
			options,
			filter,
			page = 1,
			limit = 1000,
			projection,
			deleted = false,
			groupby,
		}: TListOptions
	) =>
		Api.post(`/table/${tableName}/list`, {
			data: { page, limit, options, filter, projection, groupby },
			params: { deleted },
		});

	static findElement = async (
		tableName: string,
		{
			options,
			filter,
			projection,
		}: Pick<TListOptions, 'options' | 'filter' | 'projection'>
	) =>
		Api.post(`/table/${tableName}/find`, {
			data: { options, filter, projection },
		});

	static insertElement = async (
		tableName: string,
		{ element, elements }: { element?: any; elements?: any }
	) => {
		const data = element ? { element } : { elements };
		return Api.post(`/table/${tableName}`, {
			data,
		});
	};

	static updateElement = async (
		tableName: string,
		elementId: string,
		update: any
	) =>
		Api.put(`/table/${tableName}`, {
			data: {
				filter: { _id: elementId },
				update,
			},
		});

	static deleteElements = async (
		tableName: string,
		multiple: boolean,
		filter?: any,
		options?: any
	) =>
		Api.delete(`/table/${tableName}`, {
			params: { multiple, filter, options },
		});
	static uploadFiles = async (
		tableName: string,
		files: { [file: string]: File },
		fieldName?: string
	) => {
		await FileCompressor(files);
		return Api.post(
			`/table/${tableName}/upload${fieldName ? `?field=${fieldName}` : ''}`,
			{ data: await Api.createFormDataFromObject(files) }
		);
	};

	// AUTOMATION

	static listAutomations = async (filter: any, sort: any) =>
		Api.post('/automation/list', { data: { filter, options: { sort } } });

	static editAutomations = async (automations: TAutomation[]) =>
		Api.put('/automation', { data: { automations } });

	static deleteAutomations = async (automations: string[]) =>
		Api.delete('/automation', {
			params: { filter: { _id: { $in: automations } } },
		});

	static listEvents = async () => Api.get('/automation/types/events');
	static listConditions = async () => Api.get('/automation/types/conditions');
	static listActions = async () => Api.get('/automation/types/actions');

	static exportDB = async () => Api.get('/schema/export');
	static importDB = async (database: { [tableName: string]: any }) =>
		Api.post('/schema/import', { data: { database } });
}

export default Api;
