import { FC, useState } from 'react';
import { TRangeDay } from 'types/table';
import { ClickableScreen, TableModal } from 'components/basic/others';
import { TTableModalProps } from 'types/app';
import TimeRangeInput from 'components/hoc/timerange-input';

interface TimeRangeProps extends TTableModalProps {
	onClose: (value?: any) => void;
	defaultRanges?: { [day: string]: string[] };
}

const TimeRangeModal: FC<TimeRangeProps> = ({
	onClose,
	defaultRanges,
	refId,
}) => {
	const [values, setValues] = useState<TRangeDay>();

	return (
		<>
			<ClickableScreen cb={() => onClose(values)} />
			<TableModal zIndex={31} refId={refId} className='py-4 px-3'>
				<TimeRangeInput
					setValue={(value) => setValues(value)}
					defaultRanges={defaultRanges}
				/>
			</TableModal>
		</>
	);
};

export default TimeRangeModal;
