import { ButtonHTMLAttributes, FC, HTMLAttributes } from 'react';
import { TCheckboxProps } from 'types/app';
import { FlexAlign, FlexRow, Text } from '.';
import { Check, Plus, Upload } from './assets';

const allButtonsCSS: string =
	'flex flex-col items-center justify-center text-center font-semibold text-sm px-3 h-8 min-h-8 rounded focus:outline-none whitespace-nowrap';

export const FilledButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
	children,
	className = '',
	...props
}) => (
	<button
		{...props}
		className={`${allButtonsCSS} w-full text-white bg-primary border-primary border-2 ${className}`}
	>
		{children}
	</button>
);

export const OutlinedButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
	children,
	className = '',
	...props
}) => (
	<button
		{...props}
		className={`${allButtonsCSS} w-full text-primary bg-transparent border-current border-2 ${className}`}
	>
		{children}
	</button>
);

export const AddButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
	children,
	className = '',
	...props
}) => (
	<button
		{...props}
		className={`${allButtonsCSS} w-full text-primary bg-transparent border-gray-dark border-2 ${className}`}
	>
		<Plus color='gray-dark' />
	</button>
);

const checkboxCSS = (color: string) =>
	`items-center justify-center h-4 w-4 p-1 bg-${color} cursor-pointer`;

export const Checkbox: FC<HTMLAttributes<HTMLDivElement> & TCheckboxProps> = ({
	checked = false,
	className = '',
	color = 'gray',
	...props
}) => (
	<FlexRow {...props} className={`${checkboxCSS(color)} rounded ${className}`}>
		{checked && <Check className='w-full h-full' />}
	</FlexRow>
);

export const OptionButton: FC<HTMLAttributes<HTMLDivElement> & TCheckboxProps> =
	({ checked = false, className = '', color = 'gray', ...props }) => (
		<FlexRow
			{...props}
			className={`${checkboxCSS(color)} rounded-circle ${className}`}
		>
			{checked && <div className='h-2 w-2 rounded-circle bg-primary' />}
		</FlexRow>
	);

export const DashedButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
	children,
	className = '',
	...props
}) => (
	<button
		className={`${allButtonsCSS} border border-dashed border-primary ${className}`}
		{...props}
	>
		{children}
	</button>
);

export const UploadButton: FC<
	ButtonHTMLAttributes<HTMLButtonElement> & { text: any }
> = ({ children, className = '', text, ...props }) => (
	<button
		{...props}
		className={`${allButtonsCSS} opacity-25 border border-dashed ${className}`}
	>
		<Upload className='mb-1' />
		<Text>{text}</Text>
	</button>
);

export const ActionButton: FC<
	ButtonHTMLAttributes<HTMLDivElement> & { Icon: JSX.Element; color?: string }
> = ({
	Icon,
	className = '',
	children,
	onClick,
	color = 'primary',
	...props
}) => (
	<FlexAlign
		className={`py-1 px-2 rounded hover:bg-${color}-15 cursor-pointer max-w-content whitespace-nowrap ${className}`}
		onClick={(e) => {
			if (!onClick) return;
			e.stopPropagation();
			onClick(e);
		}}
		{...props}
	>
		{Icon}
		<Text className='ml-1' fontSize='xs' fontWeight='semibold' color={color}>
			{children}
		</Text>
	</FlexAlign>
);
