import { FC, useState } from 'react';
import { TElement } from 'types/table';
import { TTableModalProps } from 'types/app';
import { ClickableScreen, TableModal } from 'components/basic/others';
import ObjectIdInput from 'components/hoc/objectid-input';

interface ModalProps extends TTableModalProps {
	initialFields: string[];
	defaultValues: TElement | TElement[];
	multiple?: boolean;
	onClose: (value?: TElement[] | TElement) => void;
	tableName: string;
	previousTableField: string; // Column of the current selected table
}

const ObjectIdModal: FC<ModalProps> = ({
	initialFields,
	defaultValues,
	multiple,
	onClose,
	tableName,
	previousTableField,
	refId,
}) => {
	const [values, setValues] = useState<TElement | TElement[]>(defaultValues);

	return (
		<>
			<ClickableScreen
				cb={() =>
					onClose(
						multiple ? values : Array.isArray(values) ? values[0] : values
					)
				}
			/>
			<TableModal
				zIndex={50}
				refId={refId}
				className='max-w-35vw md:max-w-50vw'
			>
				<ObjectIdInput
					previousTableField={previousTableField}
					defaultValues={values}
					tableName={tableName}
					initialFields={initialFields}
					multiple={multiple}
					setValue={(value) => setValues(value)}
					maxHeight='35vh'
				/>
			</TableModal>
		</>
	);
};

export default ObjectIdModal;
