import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FilledButton } from 'components/basic/buttons';
import { InputText } from 'components/basic/inputs';
import { Text } from 'components/basic';
import { AppVersion, Media } from 'components/basic/others';
import { useSelector } from 'react-redux';
import { selectorApp } from 'store/app/app.slice';
import { language } from 'utils';
interface FormProps {
	onSubmit: SubmitHandler<{ username: string; password: string }>;
}

const LoginForm: FC<FormProps> = ({ onSubmit }) => {
	const { handleSubmit, setValue, register } = useForm();
	const { brand, t } = useSelector(selectorApp);

	return (
		<form
			className='relative flex flex-col h-auto w-full justify-items-center items-center text-center bg-white px-8 py-10 rounded-10'
			onSubmit={handleSubmit(onSubmit)}
		>
			<Media
				file={brand.landingLogo}
				className='h-24 w-24 min-w-15px -mt-24 justify-self-center'
			/>
			<Text
				fontWeight='extrabold'
				fontSize='2xl'
				color='primary'
				className='mx-9 my-5'
			>
				{brand.welcome[language]}
			</Text>
			<InputText
				{...register('username', { required: true })}
				placeholder={`${t('login.username')}`}
				onChange={({ currentTarget: { value } }) => setValue('username', value)}
			/>
			<InputText
				{...register('password', { required: true })}
				type='password'
				placeholder={`${t('login.password')}`}
				onChange={({ currentTarget: { value } }) => setValue('password', value)}
				style={{ marginTop: 10 }}
			/>
			{/* <Text color='primary' className='mt-3 mb-5'>
				Password dimenticata
			</Text> */}
			<FilledButton type='submit' className='my-4'>
				{t('login.enter')}
			</FilledButton>
			{/* <OutlinedButton
				onClick={() => window.open('https://www.google.com')}
				type='button'
				style={{ marginTop: 10 }}
			>
				<Google className='mr-3 -mb-0.5' />
				Accedi con Google
			</OutlinedButton> */}
			<AppVersion />
		</form>
	);
};

export default LoginForm;
