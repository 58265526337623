import { FC, useState, useEffect } from 'react';
import { FlexAlign } from 'components/basic';

import { TField } from 'types/table';
import { useResizeDetector } from 'react-resize-detector';
import { PrintColumn } from 'components/basic/print';

interface TableHeaderProps {
	fieldName: string | any;
	headerClick: () => void;
	className?: string;
	fieldSchema: TField;
	setColumnWidth: (width: number) => void;
}

const TableHeader: FC<TableHeaderProps> = ({
	className = '',
	headerClick,
	fieldName,
	fieldSchema,
	setColumnWidth,
}) => {
	const { width, ref } = useResizeDetector();
	const [columnMinWidth, setColumnMinWidth] = useState<number>(72);

	useEffect(() => {
		const column = document.getElementById(`header${fieldName}`);
		setColumnMinWidth(
			column ? Math.max(column.getBoundingClientRect().width + 24, 72) : 72
		);
	}, [fieldName]);

	useEffect(() => {
		// width + padding + border
		setColumnWidth(width ? width + 25 : 0);
	}, [width]);

	return (
		<FlexAlign
			ref={ref}
			className={`overflow-hidden resize-x h-8 px-3 border-b border-white whitespace-nowrap ${className}`}
			style={{ minWidth: `${columnMinWidth}px` }}
		>
			{fieldSchema && (
				<PrintColumn
					headerClick={headerClick}
					color='white'
					fieldName={fieldName}
					fieldSchema={fieldSchema}
				/>
			)}
		</FlexAlign>
	);
};

export default TableHeader;
