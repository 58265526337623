import { FC } from 'react';
import { Automations, Settings } from 'components/basic/assets';
import { ListWrapper } from 'components/basic/others';

import ListElement from './list-element';
import { useHistory, useLocation } from 'react-router-dom';
import { Label } from 'components/basic';
import { useSelector } from 'react-redux';
import { selectorApp } from 'store/app/app.slice';

const SystemList: FC = () => {
	const history = useHistory();
	const { pathname } = useLocation();
	const { schemaSettings, t } = useSelector(selectorApp);

	return (
		<ListWrapper className='mt-3'>
			<Label className='px-4 mt-2'>
				{`${t('settings.list')}`.toUpperCase()}
			</Label>
			<ListElement
				text={t('screen.settings')}
				selected={pathname === '/settings'}
				selectable
				onElemClick={() => history.push('/settings')}
				onContextClick={() => history.push('/settings')}
				Icon={
					<Settings
						color={pathname === '/settings' ? 'white' : 'black-lighter'}
					/>
				}
				rightIcon='arrow'
			/>
			{schemaSettings && (
				<ListElement
					text={t('screen.automations')}
					selected={pathname === '/automations'}
					selectable
					onElemClick={() => history.push('/automations')}
					onContextClick={() => history.push('/automations')}
					Icon={
						<Automations
							color={pathname === '/automations' ? 'white' : 'black-lighter'}
						/>
					}
					rightIcon='arrow'
				/>
			)}
		</ListWrapper>
	);
};

export default SystemList;
