import { FC, useState, useRef } from 'react';
import { TRange } from 'types/table';
import { ClickableScreen, TableModal } from 'components/basic/others';
import { TTableModalProps } from 'types/app';
import PeriodInput from 'components/hoc/period-input';

interface PeriodModalProps extends TTableModalProps {
	onClose: (value: TRange | TRange[] | undefined) => void;
	defaultPeriods?: string[];
	isArray: boolean;
}

const PeriodModal: FC<PeriodModalProps> = ({
	onClose,
	defaultPeriods,
	refId,
	isArray,
}) => {
	const [values, setValues] = useState<TRange | TRange[]>();
	// TODO: Adjut modals exit click to get all input values without setTimeout
	const tempRef = useRef<TRange | TRange[] | undefined>(values);
	tempRef.current = values;
	return (
		<>
			<ClickableScreen
				cb={() =>
					setTimeout(() => {
						onClose(tempRef.current);
					}, 500)
				}
			/>
			<TableModal zIndex={31} refId={refId} className='pb-4 pt-1 px-3'>
				<PeriodInput
					isArray={isArray}
					defaultPeriods={defaultPeriods}
					setValue={(value) => setValues(value)}
				/>
			</TableModal>
		</>
	);
};

export default PeriodModal;
