import { FC, useState, useEffect } from 'react';
import { FlexAlign } from 'components/basic';

import HeaderElement from '../hoc/header-element';
import { useLocation } from 'react-router-dom';
import { selectorApp } from 'store/app/app.slice';
import { useSelector } from 'react-redux';

const PageHeader: FC = () => {
	const { pathname } = useLocation();
	const { t } = useSelector(selectorApp);
	const [headerText, setHeaderText] = useState<string>(`${t('view.grid')}`);

	useEffect(() => {
		let newText = '';
		switch (pathname) {
			case '/settings':
				newText = `${t('screen.settings')}`;
				break;
			case '/automations':
				newText = `${t('screen.automations')}`;
				break;
			default:
				newText = `${t('view.grid')}`;
		}
		setHeaderText(newText);
	}, [pathname]);

	return (
		<FlexAlign className='max-w-content h-7 bg-primary rounded-t'>
			<HeaderElement text={headerText} selected />
		</FlexAlign>
	);
};

export default PageHeader;
