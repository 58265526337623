import { FC, useEffect, useState, HTMLAttributes } from 'react';
import { FlexAlign, FlexCol, Label } from 'components/basic';
import { Flag } from 'components/basic/assets';
import { TAlias, TLanguage } from 'types/app';
import {
	elemToArray,
	language,
	locales,
	isNotEmpty,
	replaceElementInArray,
} from 'utils';
import { InputArray, InputText } from 'components/basic/inputs';

interface TTranslationProps extends HTMLAttributes<HTMLDivElement> {
	defaultTranslate?: TAlias | TAlias[];
	setValue: (value: TAlias | TAlias[]) => void;
	multiple?: boolean;
}

const InputTranslation: FC<TTranslationProps> = ({
	defaultTranslate,
	setValue,
	multiple,
	...props
}) => {
	const [currentValues, setCurrentValues] = useState<TAlias[]>([]);

	const onSubmit = () => {
		const newValues = currentValues.filter((value) => isNotEmpty(value));
		setValue(multiple ? newValues : newValues[0]);
	};

	useEffect(() => {
		onSubmit();
	}, [currentValues]);

	useEffect(() => {
		if (defaultTranslate) {
			const defaults = elemToArray(defaultTranslate).filter((value) =>
				Object.keys(value).find((lang) => Object.keys(locales).includes(lang))
			);
			setCurrentValues(defaults);
		}
	}, [defaultTranslate]);

	return (
		<FlexCol {...props}>
			<FlexAlign className='justify-between bg-gray rounded mb-4'>
				{multiple ? (
					<InputArray
						className='-mr-2 flex-wrap'
						defaultValue={currentValues.map(
							(value) => value[language] as string
						)}
						inputProps={{
							className: 'h-6 focus:ring-2 focus:ring-primary rounded',
							autoFocus: true,
						}}
						setValue={(array) =>
							array &&
							setCurrentValues(
								array.map((value, index) =>
									currentValues[index]
										? { ...currentValues[index], [language]: value }
										: { [language]: value }
								)
							)
						}
					/>
				) : (
					<InputText
						defaultValue={currentValues[0]?.[language]}
						autoFocus
						style={{ height: '1.5rem' }}
						onChange={({ currentTarget: { value } }) =>
							setCurrentValues([{ ...currentValues[0], [language]: value }])
						}
					/>
				)}
				<Flag lang={language} className='mx-2' />
			</FlexAlign>
			{currentValues.map((value, index) => (
				<FlexCol className='mb-2' key={`translation${index}`}>
					<Label className='whitespace-pre-wrap'>{value[language]}</Label>
					{Object.keys(locales)
						.filter((loc) => loc !== language)
						.map((lang, ind) => (
							<FlexAlign
								className='mb-1 border-b border-gray-dark hover:border-primary'
								key={`translation${index}${ind}`}
							>
								<input
									onBlur={({ currentTarget: { value } }) => {
										let newValue = currentValues[index];
										if (isNotEmpty(value)) newValue[lang as TLanguage] = value;
										else delete newValue[lang as TLanguage];
										setCurrentValues(
											replaceElementInArray(currentValues, newValue, index)
										);
									}}
									defaultValue={value?.[lang as TLanguage]}
									className='py-1 px-2 mr-2 bg-transparent focus:outline-none w-full'
								/>
								<Flag style={{ minWidth: 19 }} lang={lang as TLanguage} />
							</FlexAlign>
						))}
				</FlexCol>
			))}
		</FlexCol>
	);
};

export default InputTranslation;
