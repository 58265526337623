import { FC, useState, useRef } from 'react';
import { TMenu, TQuantity } from 'types/table';
import { TTableModalProps } from 'types/app';
import { ClickableScreen, TableModal } from 'components/basic/others';
import MenuInput from 'components/hoc/menu-input';

interface ModalProps extends TTableModalProps {
	defaultValues: TQuantity | TQuantity[] | TMenu | TMenu[];
	multiple?: boolean;
	onClose: (value?: TQuantity | TQuantity[] | TMenu | TMenu[]) => void;
	hasQuantity?: boolean;
}

const MenuModal: FC<ModalProps> = ({
	defaultValues,
	multiple,
	onClose,
	refId,
	hasQuantity,
}) => {
	const [values, setValues] = useState<
		TQuantity | TQuantity[] | TMenu | TMenu[]
	>(defaultValues);

	// TODO: Adjut modals exit click to get all input values without setTimeout
	const tempRef = useRef<TQuantity | TQuantity[] | TMenu | TMenu[] | undefined>(
		values
	);
	tempRef.current = values;

	return (
		<>
			<ClickableScreen
				cb={() =>
					setTimeout(() => {
						onClose(
							multiple
								? tempRef.current
								: Array.isArray(tempRef.current)
								? tempRef.current[0]
								: tempRef.current
						);
					}, 500)
				}
			/>
			<TableModal
				zIndex={50}
				refId={refId}
				className='max-w-35vw md:max-w-50vw'
			>
				<MenuInput
					hasQuantity={hasQuantity}
					defaultValues={values}
					multiple={multiple}
					setValue={(value) => setValues(value)}
					maxHeight='35vh'
				/>
			</TableModal>
		</>
	);
};

export default MenuModal;
