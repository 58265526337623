import { FC } from 'react';
import { TUser } from 'types/auth';
import { Avatar } from 'components/basic/assets';
import { FlexAlign, FlexCol, Text } from 'components/basic';

interface UserProps {
	user?: TUser;
}

export const HeaderUser: FC<UserProps> = ({ user }) => {
	if (!user) return <div></div>;
	return (
		<FlexAlign>
			<FlexCol className='mr-2'>
				<Text fontSize='sm'>{user.firstName}</Text>
				<Text fontSize='sm'>{user.lastName}</Text>
			</FlexCol>
			<Avatar lg user={user} />
		</FlexAlign>
	);
};
