import { FlexAlign } from 'components/basic';
import { PlusView } from 'components/basic/assets';
import { Checkbox } from 'components/basic/buttons';
import TableHeader from 'components/hoc/table-header';
import { FC, SetStateAction, Dispatch } from 'react';
import { useSelector } from 'react-redux';
import { selectorApp } from 'store/app/app.slice';
import { TTableOperation } from 'types/app';
import { TCellAddress, TStructure } from 'types/table';

interface HeaderProps {
	table: TStructure;
	startOperation: (operationOpt: TTableOperation) => void;
	setSelectedCell: Dispatch<
		SetStateAction<TCellAddress | TCellAddress[] | undefined>
	>;
	selectedTable?: string;
	setSelectedTable: Dispatch<SetStateAction<string | undefined>>;
	fields: string[];
	setColumnWidths: Dispatch<SetStateAction<{ [column: string]: number }>>;
}

const GridHeader: FC<HeaderProps> = ({
	setSelectedCell,
	setColumnWidths,
	fields,
	setSelectedTable,
	startOperation,
	table,
	selectedTable,
}) => {
	const { schemaSettings } = useSelector(selectorApp);
	return (
		<FlexAlign className='sticky top-0 h-8' style={{ zIndex: 31 }}>
			<FlexAlign className='justify-center min-w-12 h-8 bg-white border-r-2 border-white'>
				<Checkbox
					checked={selectedTable === table.tableName}
					onClick={() => {
						if (selectedTable === table.tableName) setSelectedTable(undefined);
						else setSelectedTable(table.tableName);
						setSelectedCell(undefined);
					}}
				/>
			</FlexAlign>
			{fields.map((column, columnIndex) => (
				<TableHeader
					key={`header${column}${columnIndex}`}
					setColumnWidth={(width) =>
						setColumnWidths((currentWidths) => ({
							...currentWidths,
							[column]: width,
						}))
					}
					headerClick={() => {
						startOperation({
							table,
							operation: 'editColumn',
							options: { [column]: table.tableSchema[column] },
						});
					}}
					className={`bg-primary border-r border-white ${
						columnIndex === fields.length - 1
							? 'rounded-tr'
							: columnIndex === 0
							? 'rounded-tl'
							: ''
					}`}
					fieldSchema={table.tableSchema[column]}
					fieldName={column}
				/>
			))}
			{schemaSettings && (
				<div
					onClick={() =>
						startOperation({
							table,
							operation: 'addColumn',
						})
					}
					className='p-2 cursor-pointer'
				>
					<PlusView className='w-4 h-4' />
				</div>
			)}
		</FlexAlign>
	);
};

export default GridHeader;
