import ReactDOM from 'react-dom';
import 'themes/tailwind.css';
import reportWebVitals from './reportWebVitals';
import App from 'app';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from 'store/index';
import { IntlProvider } from 'react-intl-hooks';
import { language, locales } from 'utils';

ReactDOM.render(
	<Provider store={store}>
		<IntlProvider
			locale={language}
			messages={locales[language]}
			defaultLocale='en'
		>
			<PersistGate persistor={persistor}>
				<App />
			</PersistGate>
		</IntlProvider>
	</Provider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
