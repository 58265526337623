import { ClickableScreen, TableModal } from 'components/basic/others';
import { FC, useState } from 'react';
import { TAlias, TTableModalProps } from 'types/app';
import InputTranslation from 'components/hoc/translation-input';

interface TTranslationModalProps extends TTableModalProps {
	defaultValue?: TAlias | TAlias[];
	onClose: (value?: TAlias | TAlias[]) => void;
	multiple?: boolean;
}

const TranslationModal: FC<TTranslationModalProps> = ({
	refId,
	defaultValue,
	onClose,
	multiple,
}) => {
	const [values, setValues] = useState<TAlias | TAlias[]>();

	return (
		<>
			<ClickableScreen cb={() => onClose(values)} />
			<TableModal
				zIndex={50}
				className='max-h-35vh overflow-auto'
				refId={refId}
			>
				<InputTranslation
					defaultTranslate={defaultValue}
					multiple={multiple}
					setValue={(value) => setValues(value)}
				/>
			</TableModal>
		</>
	);
};

export default TranslationModal;
