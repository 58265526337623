import { FC, useState } from 'react';
import { FlexAlign, FlexCol, FlexRow } from 'components/basic';
import { LongArrowLeft, Save } from 'components/basic/assets';
import { ActionButton } from 'components/basic/buttons';
import Preview from 'components/hoc/preview/preview.component';
import SettingsForm from 'components/hoc/settings-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Api from 'services/api.service';
import { useAppDispatch } from 'store';
import {
	selectorApp,
	setBrand,
	setVisibleDefaultFields,
} from 'store/app/app.slice';
import { TBrand } from 'types/app';

const Settings: FC = () => {
	const history = useHistory();
	const dispatch = useAppDispatch();
	const { brand, visibleDefaultFields, t } = useSelector(selectorApp);
	const [newBrand, setNewBrand] = useState<TBrand>(brand);
	const [visibleFields, setVisibleFields] = useState<string[]>(
		visibleDefaultFields ?? []
	);

	const saveBrand = async () => {
		dispatch(setVisibleDefaultFields(visibleFields));
		try {
			const { data } = await Api.saveBrand(newBrand);
			dispatch(setBrand(data));
			toast.success(`${t('toast.brand_updated')}`);
		} catch (error) {
			// TODO: disabled because of permission rework
			//handleError(error);
		}
	};

	return (
		<FlexCol className='pr-4 pl-3 pt-3'>
			<FlexAlign className='justify-between mb-4 pl-1'>
				<ActionButton Icon={<LongArrowLeft />} onClick={() => history.goBack()}>
					{t('placeholder.go_back')}
				</ActionButton>
				<ActionButton Icon={<Save />} onClick={saveBrand}>
					{`${t('placeholder.save')} ${t('placeholder.edits')}`}
				</ActionButton>
			</FlexAlign>
			<FlexRow className='p-4'>
				<div className='w-1/2 pr-3 max-h-full-grid overflow-y-auto'>
					<SettingsForm
						visibleFields={visibleFields}
						setVisibleFields={setVisibleFields}
						brand={newBrand}
						setBrand={setNewBrand}
					/>
				</div>
				<div className='w-1/2 pl-4'>
					<Preview brand={newBrand} />
				</div>
			</FlexRow>
		</FlexCol>
	);
};

export default Settings;
